import {Component} from '@angular/core';
import {UIStateEnum} from "../../enum/UIStateEnum";
import {ICharitiesByCategoryResult} from "../../interfaces/charity/ICharitiesByCategoryResult";
import {Observable, tap} from "rxjs";
import {CharityService} from "../../services/charity.service";
import {GameService} from "../../services/game.service";
import {MatDialog} from "@angular/material/dialog";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {IUpdateCharitySortOrder} from "../../interfaces/charity/IUpdateCharitySortOrder";
import {CdkDrag, CdkDragDrop, CdkDropList, CdkDropListGroup, moveItemInArray} from "@angular/cdk/drag-drop";
import {
  CharityCategoryChangeNameComponent
} from "../dialogs/charity-category-change-name/charity-category-change-name.component";
import {CharityScreens} from "../../enum/CharityScreens";
import {ICharityWithContributionTotals} from "../../interfaces/charity/ICharityWithContributionTotals";
import {PermissionTypes} from "../../enum/PermissionTypes";
import {IDialogGenericData} from "../../interfaces/IDialogGenericData";
import {
  ConfirmationActionDialogComponent
} from "../dialogs/confirmation-action-dialog/confirmation-action-dialog.component";
import {DialogFunctionService} from "../../services/dialog-function.service";
import {AsyncPipe} from "@angular/common";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {HasPermissionDirective} from "../../directives/permissions/has-permission";
import {SnackbarService} from "../../services/snackbar.service";

@Component({
  selector: 'app-charity-category-view',
  standalone: true,
  templateUrl: './charity-category-view.component.html',
  imports: [
    AsyncPipe,
    MatIconButton,
    MatMenuTrigger,
    MatIcon,
    MatTooltip,
    MatButton,
    CdkDropListGroup,
    CdkDropList,
    CdkDrag,
    MatMenu,
    MatMenuItem,
    HasPermissionDirective
  ],
  styleUrls: ['./charity-category-view.component.scss']
})
export class CharityCategoryViewComponent {
  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  public permissionTypes = PermissionTypes;
  public uiStateEnumForTemplate = UIStateEnum;
  public enableSortCharities: boolean = false;
  public charityScreens = CharityScreens;
  public categoryHasActiveCharities: boolean = false;
  public activeGame = this.gameService.activeGame();
  public chosenCategoryCharities: ICharityWithContributionTotals[] = [];
  public activeCharityCategory$: Observable<ICharitiesByCategoryResult | undefined> = this.charityService.selectActiveCategory()
    .pipe(tap((charityCategoryResultP) => {
      if (charityCategoryResultP) {
        this.chosenCategoryCharities = charityCategoryResultP?.Charities
          .sort((a, b) => a.SortOrder - b.SortOrder);
        this.categoryHasActiveCharities = this.chosenCategoryCharities.some((charityP) => {
          return charityP.Active;
        });
      }
    }));

  constructor(private charityService: CharityService,
              private gameService: GameService,
              private dialogFunctionService: DialogFunctionService,
              private matDialog: MatDialog,
              private snackBarService: SnackbarService,
              private errorHandlingService: ErrorHandlingService) {
  }

  public updateSortingCharities() {
    this.enableSortCharities = !this.enableSortCharities;
    this.chosenCategoryCharities = this.chosenCategoryCharities
      .sort((a, b) => a.SortOrder - b.SortOrder);
  }

  onBackButtonClick(activeScreenP: CharityScreens) {
    this.charityService.activeCharityScreenBehaviourSubject.next(activeScreenP);
    this.charityService.activeCharityBehaviourSubject.next(undefined);
  }

  public openCategoryNameChangeDialog() {
    this.matDialog.open(CharityCategoryChangeNameComponent, {
      width: '650px'
    });
  }

  public onDeactivateCharityClick(categoryP: ICharitiesByCategoryResult) {
    this.dialogFunctionService.setCallback(() => {
      return this.charityService.deactivateCharityCategory({
        categoryId: categoryP.CategoryId,
        gameId: this.gameService.activeGame().Id
      })
    });

    let deactivateCategoryDialogData: IDialogGenericData = {
      title: 'Confirm Charity Deactivation',
      message: `Please confirm that you would like to deactivate <span class="bold-text">${categoryP.Category.Name}</span>`,
      dataCyTag: 'confirm-deactivate-category-button',
      successMessage: 'Successfully deactivated category',
      extraDetails: 'Categories that have been deactivated will only reappear in the game once they have been reactivated and linked to at least one active charity.'
    };

    this.matDialog.open(ConfirmationActionDialogComponent, {
      data: deactivateCategoryDialogData,
      width: '650px'
    });
  }

  public onReactivateCategoryClick(categoryP: ICharitiesByCategoryResult) {
    this.dialogFunctionService.setCallback(() => {
      return this.charityService.reactivateCharityCategory({
        categoryId: categoryP.CategoryId,
        gameId: this.gameService.activeGame().Id
      })
    });

    let reactivateCharityCategoryDialogData: IDialogGenericData = {
      title: 'Confirm Charity Category Reactivation',
      message: `Please confirm that you would like to reactivate <span class="bold-text">${categoryP.Category.Name}</span>`,
      dataCyTag: 'confirm-reactivate-category-button',
      successMessage: 'Successfully reactivated category',
      extraDetails: 'Once the category has been reactivated, charities can be reintroduced to it. The category will remain invisible in the game until it is linked to at least one active charity.'
    };

    this.matDialog.open(ConfirmationActionDialogComponent, {
      data: reactivateCharityCategoryDialogData,
      width: '650px'
    });
  }

  public viewCharity(charityP: ICharityWithContributionTotals) {
    this.charityService.activeCharityBehaviourSubject.next(charityP);
    this.charityService.activeCharityScreenBehaviourSubject.next(CharityScreens.Charity);
  }

  submitCharityChanges() {
    let updatedCharities = this.updateSortOrderForCharityList();
    let updateCharityCategorySort: IUpdateCharitySortOrder = {
      charities: updatedCharities,
      gameId: this.activeGame?.Id!
    };
    this.uiState = UIStateEnum.ShowLoading;
    this.charityService.updateCharitySortOrder(updateCharityCategorySort).subscribe({
        next: () => {
          this.enableSortCharities = false;
          this.uiState = UIStateEnum.ShowData;
          this.snackBarService.openSuccessfulSnackBar("Successfully Updated Charity Sorting");
        },
        error: err => {
          this.uiState = UIStateEnum.ShowData;
          this.errorHandlingService.displayPageLevelErrorMessage(err);
        }
      }
    )
  }

  updateSortOrderForCharityList(): ICharityWithContributionTotals[] {
    return this.chosenCategoryCharities.map((val, index) => {
      val.SortOrder = index + 1;
      return val;
    });
  }

  dropCharity(event: CdkDragDrop<ICharityWithContributionTotals[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  sortCharitiesAlphabetically() {
    this.chosenCategoryCharities = this.chosenCategoryCharities
      .sort((a, b) => a.Name.trim().localeCompare(b.Name.trim()));

    for (let i = 0; i < this.chosenCategoryCharities.length; i++) {
      this.chosenCategoryCharities[i].SortOrder = i;
    }
  }
}
