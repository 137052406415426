<div class="text-center" *hasPermission="permissionTypes.ModifyTestimonials">
  <button mat-icon-button
          (click)="onEditTestimonial()"
          data-testid="edit-testimonial-button">
    <mat-icon matTooltipPosition="right"
              matTooltip="edit licence number data"
    >edit
    </mat-icon>
  </button>
</div>
