import {Component} from '@angular/core';
import {UsersService} from "../../services/users.service";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {ISendPasswordRecoveryEmailRequest} from "../../interfaces/ISendPasswordRecoveryEmailRequest";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {AppConfigService} from "../../services/app-config.service";
import {GamePageBase} from "../../shared/game-page-base";
import {GameService} from "../../services/game.service";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {RecaptchaFormsModule, RecaptchaModule} from "ng-recaptcha";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-recover-password',
  standalone: true,
  templateUrl: './recover-password.component.html',
  imports: [
    MatCardModule,
    MatFormFieldModule,
    MatInput,
    ReactiveFormsModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    MatButton
  ],
  styleUrls: ['./recover-password.component.css']
})
export class RecoverPasswordComponent extends GamePageBase {

  public showCheckEmailMessage: boolean = false;
  public recaptchaKey: string = this.appConfigService.recaptchaSiteKey();
  public recoverPasswordForm = new FormGroup({
    recoverPasswordEmail: new FormControl<string>('', Validators.required),
    recaptcha: new FormControl<string>('')
  });

  constructor(private userService: UsersService,
              gameService: GameService,
              private errorHandlingService: ErrorHandlingService,
              private appConfigService: AppConfigService) {
    super(gameService);
  }

  sendPasswordRecoveryEmail() {
    if (!this.recoverPasswordForm.valid) {
      this.recoverPasswordForm.markAllAsTouched();
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;
    const recoverPasswordRequest: ISendPasswordRecoveryEmailRequest = {
      email: this.recoverPasswordForm.controls.recoverPasswordEmail.value!,
      redirectUrl: `${window.location.origin}/login`,
      captcha: this.recoverPasswordForm.controls.recaptcha.value!,
      isAdminReset: true
    }
    this.userService.sendPasswordRecoveryEmail(recoverPasswordRequest).subscribe({
      next: () => this.showCheckEmailMessage = true,
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    })
  }

}
