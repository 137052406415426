<app-component-level-error-message [errorMessage]="errorMessage"></app-component-level-error-message>
<div>
  <mat-form-field appearance="outline">
    <mat-label>Draw Date</mat-label>
    <input placeholder="filter by draw date" matInput [matDatepicker]="picker" [(ngModel)]="drawDate"
           (ngModelChange)="filterResultsPerGameDrawDate()">
    <mat-hint>filter report by draw date</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
@if(uiState === uiStateForTemplate.ShowData) {
  <ag-grid-angular
    data-testid="hourly-ticket-sales-table"
    class="ag-theme-quartz margin-top-med"
    domLayout='autoHeight'
    [pagination]="true"
    [paginationPageSize]="25"
    overlayNoRowsTemplate="<span>No tickets for this game</span>"
    (gridReady)="onGroupsGridReady($event)"
    [rowData]="filteredTicketSalesPerGameNumber"
    [columnDefs]="groupsPerHourDefs">
  </ag-grid-angular>
} @else if(uiState === uiStateForTemplate.ShowLoading) {
  <app-loading-card-content></app-loading-card-content>
}
