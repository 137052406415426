import {Component, Inject} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {SnackbarService} from "../../../services/snackbar.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IActiveGameInstance} from "../../../interfaces/IActiveGameInstance";
import {GameInstanceService} from "../../../services/game-instance.service";
import {IUpdateGameInstanceEndedOnCommand} from "../../../interfaces/IUpdateGameInstanceEndedOnCommand";
import {ActiveUserService} from "../../../services/active-user.service";
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {FormsModule} from "@angular/forms";
import {DatePipe} from "@angular/common";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-end-game-instance',
  standalone: true,
  templateUrl: './end-game-instance.component.html',
  imports: [
    DialogBaseComponent,
    FormsModule,
    DatePipe,
    MatButton
  ],
  styleUrl: './end-game-instance.component.scss'
})
export class EndGameInstanceComponent {

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public formattedDateTimeString = '';

  constructor(private snackBarService: SnackbarService,
              private errorHandlingService: ErrorHandlingService,
              private gameInstanceService: GameInstanceService,
              private activeUserService: ActiveUserService,
              private matDialogRef: MatDialogRef<EndGameInstanceComponent>,
              @Inject(MAT_DIALOG_DATA) public activeGameInstance: IActiveGameInstance) {
    this.setDateTimePicker();
  }


  private setDateTimePicker() {
    const currentDrawDate = new Date(this.activeGameInstance.EndedOn);
    const year = currentDrawDate.getFullYear();
    const month = String(currentDrawDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(currentDrawDate.getDate()).padStart(2, '0');
    const hours = String(currentDrawDate.getHours()).padStart(2, '0');
    const minutes = String(currentDrawDate.getMinutes()).padStart(2, '0');
    this.formattedDateTimeString = `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  updateEndDate() {
    let updatedDate = new Date(this.formattedDateTimeString);
    const command: IUpdateGameInstanceEndedOnCommand = {
      EndedOn: updatedDate,
      GameInstanceId: this.activeGameInstance.Id,
      AdminId: this.activeUserService.activeUser().Id,
      GameId: this.activeGameInstance.GameId
    };
    this.uiState = UIStateEnum.ShowLoading;
    this.gameInstanceService.updateGameInstanceEndDate(command).subscribe({
      next:() => {
        this.snackBarService.openSuccessfulSnackBar('Successfully updated end date');
        this.matDialogRef.close();
      },
      error:(err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage(err, true);
      }
    })
  }
}
