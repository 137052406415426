<app-dialog-base [showLoadingSpinner]="uiState === uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Edit FAQ</span>

  @if (uiState === uiStateEnumForTemplate.ShowData) {
    <ng-container dialog-content>
      <form cForm [formGroup]="editFaqForm" novalidate>
        <div class="mb-3 position-relative">
          <label cLabel for="questionInput">Question</label>
          <input cFormControl
                 [valid]="questionControl.touched && questionControl.valid ? true : questionControl.touched && questionControl.invalid ? false : undefined"
                 formControlName="question"
                 id="questionInput"
                 data-testid="edit-faq-question-input"
                 placeholder="enter a question you want to provide an answer to"
          />

          @if (questionControl.invalid && questionControl.touched) {
            <c-form-feedback [valid]="false" tooltip>
              <div data-testid="edit-faq-question-required-error">question is required</div>
            </c-form-feedback>
          }
        </div>

        <div class="mb-3 position-relative">
          <label cLabel for="answerInput">Answer (markdown)</label>
          <textarea cFormControl
                    [valid]="answerContentControl.touched && answerContentControl.valid ? true : answerContentControl.touched && answerContentControl.invalid ? false: undefined"
                    formControlName="answer"
                    placeholder="enter your markdown answer here"
                    id="answerInput"
                    data-testid="edit-faq-answer-input"
                    rows="10"></textarea>
          @if (answerContentControl.invalid && answerContentControl.touched && answerContentControl.errors) {
            <c-form-feedback [valid]="false" tooltip>
              <div data-testid="edit-faq-answer-required-error">answer is required</div>
            </c-form-feedback>
          }
        </div>

        <div class="d-grid gap-2 mb-3">
          <button cButton color="primary" [cModalToggle]="verticallyCenteredModal.id">Preview Answer</button>
        </div>

        <c-modal #verticallyCenteredModal alignment="center" id="verticallyCenteredModal" [backdrop]="false">
          <c-modal-header>
            <h5 cModalTitle>{{questionControl.value}}</h5>
            <button [cModalToggle]="verticallyCenteredModal.id" cButtonClose></button>
          </c-modal-header>
          <c-modal-body>
            <markdown [data]="answerContentControl.getRawValue()"></markdown>
          </c-modal-body>
          <c-modal-footer>
            <button [cModalToggle]="verticallyCenteredModal.id" cButton color="secondary">
              Close
            </button>
          </c-modal-footer>
        </c-modal>

        <div class="mb-3 position-relative">
          <c-form-check [switch]="true" [sizing]="'xl'">
            <input cFormCheckInput type="checkbox" data-testid="edit-faq-active-toggle" formControlName="active"/>
            <label cFormCheckLabel>Should this FAQ be active?</label>
          </c-form-check>
        </div>

        @if(!activeControl.value) {
          <div class="error-message-container">
            <div class="error-message" data-testid="edit-faq-inactive-message">**Note: This faq is not active. It will not appear in the web app until "Should this FAQ
              to active?" is toggled to the on position.
            </div>
          </div>
        }

      </form>
    </ng-container>
  }

  <ng-container actions>
    <button mat-button
            (click)="editFaq()"
            data-testid="finalize-edit-faq"
            [disabled]="uiState === uiStateEnumForTemplate.ShowLoading">
      Edit
    </button>
  </ng-container>
</app-dialog-base>
