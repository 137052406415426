<div class="text-center">
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon class="menu-button"
              data-testid="show-admin-settings-menu"
              matTooltipPosition="right"
              matTooltip="open winner payout options"
    >more_horiz
    </mat-icon>
  </button>
</div>
<mat-menu #menu="matMenu">
  <ng-container *hasPermission="PermissionTypes.ModifyWinnerPayouts">
    <mat-divider></mat-divider>
    <button mat-menu-item
            (click)="onPayWinnerClick()"
            data-testid="edit-payout-button">
      <mat-icon class="dark-grey menu-icon">payment</mat-icon>
      <span class="dark-grey menu-text">Edit Payout</span>
    </button>
  </ng-container>
</mat-menu>
