import {Component, inject, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarAction, MatSnackBarActions, MatSnackBarLabel, MatSnackBarRef} from '@angular/material/snack-bar';
import {MatButton} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'app-error-snackbar',
  standalone: true,
  templateUrl: './error-snackbar.component.html',
  imports: [
    MatSnackBarLabel,
    MatSnackBarActions,
    MatIconModule,
    MatButton,
    MatSnackBarAction
  ],
  styleUrl: './error-snackbar.component.scss'
})
export class ErrorSnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string) {
  }

  snackBarRef = inject(MatSnackBarRef);
}
