<div data-testid="success-snackbar" class="full-width flex-row justify-space-between align-center">
  <div class="flex-row">
    <div class="flex-full-center">
      <mat-icon>check_circle</mat-icon>
    </div>
    <div class="flex-full-center margin-left-med">
      {{ data }}
    </div>
  </div>
  <button data-testid="close-snackbar-button" mat-icon-button
          (click)="snackBarRef.dismissWithAction()">
    <mat-icon>close</mat-icon>
  </button>
</div>

