import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'phoneFormat'
})
export class PhoneFormatPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }

    const numericValue = value.replace(/\D/g, '');
    const firstPart = numericValue.slice(0, 3);
    const secondPart = numericValue.slice(3, 6);
    const thirdPart = numericValue.slice(6, 10);

    let formattedValue;

    if (numericValue.length <= 3) {
      formattedValue = firstPart;
    } else if (numericValue.length <= 6) {
      formattedValue = `${firstPart}-${secondPart}`;
    } else {
      formattedValue = `${firstPart}-${secondPart}-${thirdPart}`;
    }

    // Format the value with hyphens
    return formattedValue;
  }
}
