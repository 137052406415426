import {Component} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {MatDialog} from "@angular/material/dialog";
import {
  ViewPlayerNumberDialogComponent
} from "../../dialogs/view-player-number-dialog/view-player-number-dialog.component";
import {IGameInstancePlayerNumberWithPayment} from "../../../interfaces/IGameInstancePlayerNumberWithPayment";
import {ViewPlayerPaymentComponent} from "../../dialogs/view-player-payment/view-player-payment.component";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {CdkCopyToClipboard} from "@angular/cdk/clipboard";

@Component({
  selector: 'app-entity-view-renderer-dialog',
  standalone: true,
  templateUrl: './entity-view-renderer.component.html',
  imports: [
    MatIcon,
    MatTooltip,
    CdkCopyToClipboard
  ],
  styleUrls: ['./entity-view-renderer.component.scss']
})
export class EntityViewRenderer implements ICellRendererAngularComp {

  public entityId!: any;
  public entity!: IGameInstancePlayerNumberWithPayment;
  public params!: ICellRendererParams;

  constructor(private matDialog: MatDialog) {
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.entityId = params.value;
    this.entity = params.data;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  viewEntity() {
    const paramType = this.params.colDef?.cellRendererParams.type;

    if (paramType == 'PlayerPayment') {
      this.matDialog.open(ViewPlayerPaymentComponent, {
        data: this.entity
      });
    } else if (paramType == 'PlayerNumber') {
      this.matDialog.open(ViewPlayerNumberDialogComponent, {
        data: this.entity
      });
    }
  }
}
