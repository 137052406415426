import {Pipe, PipeTransform} from '@angular/core';
import {GameTypeEnum} from "../enum/GameTypeEnum";

@Pipe({
  name: 'gameType'
})
export class GameTypePipe implements PipeTransform {
  public transform(statusP: GameTypeEnum): string {
    if (statusP) {
      switch (statusP){
        case GameTypeEnum.ProgressiveFiftyFifty: return '50/50';
        case GameTypeEnum.GoldRush: return 'Gold Rush';
      }
    }
    return 'Unknown Status';
  }
}
