import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {NewGameService} from "../../../services/new-game.service";
import {DateService} from "../../../services/date.service";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {IGameDrawSettingsForm} from "../../../interfaces/new-game/forms/IGameDrawSettingsForm";
import {MatError, MatFormField, MatHint, MatLabel} from "@angular/material/form-field";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatInput} from "@angular/material/input";
import {NgIf} from "@angular/common";
import {MatCheckbox} from "@angular/material/checkbox";

@Component({
  selector: 'app-game-draw-settings-form',
  templateUrl: './game-draw-settings-component-form.component.html',
  standalone: true,
  imports: [
    MatFormField,
    ReactiveFormsModule,
    MatSelect,
    MatLabel,
    MatHint,
    MatError,
    MatOption,
    MatInput,
    NgIf,
    MatCheckbox
  ],
  styleUrl: './game-draw-settings-component-form.component.css'
})
export class GameDrawSettingsComponentForm implements OnInit {

  private newGameService: NewGameService = inject(NewGameService);
  private dateService: DateService = inject(DateService);

  private militaryTime: RegExp = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

  @Input() newGameDrawSettingsForm: FormGroup<IGameDrawSettingsForm> = new FormGroup<IGameDrawSettingsForm>({
    drawDayOfWeek: new FormControl<number>(0, Validators.required),
    drawTimeMinutesFromMidnightLocal: new FormControl<string>('', [Validators.required, Validators.pattern(this.militaryTime)]),
    instanceIntervalType: new FormControl<number>(1, Validators.required),
    manualDraw: new FormControl<boolean>(false, Validators.required),
    maxTicketsPerDraw: new FormControl<number>(999999, Validators.required),
    maxAmountPlayersCanSpendPerDraw: new FormControl<number>(0, [Validators.required, Validators.min(5)]),
    minimumGameLengthInHours: new FormControl<number>(72, Validators.required),
    notificationsStartMinutesFromMidnightLocal: new FormControl<string>('', [Validators.required, Validators.pattern(this.militaryTime)])
  });

  @Output() newGameDrawSettingsFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  ngOnInit(): void {
    this.populateGameDrawFromState()
  }

  onFormChange() {
    this.newGameDrawSettingsFormChange.emit(this.newGameDrawSettingsForm)
  }

  private populateGameDrawFromState() {
    const newGameDrawFromState = this.newGameService.getNewGameRequest()?.newGameDraw;
    if (newGameDrawFromState) {
      if (newGameDrawFromState.drawTimeMinutesFromMidnightLocal) {
        this.newGameDrawSettingsForm.controls.drawTimeMinutesFromMidnightLocal
          .setValue(this.dateService.convertToMilitaryTime(newGameDrawFromState.drawTimeMinutesFromMidnightLocal));
      }

      if (newGameDrawFromState.notificationsStartMinutesFromMidnightLocal) {
        this.newGameDrawSettingsForm.controls.notificationsStartMinutesFromMidnightLocal
          .setValue(this.dateService.convertToMilitaryTime(newGameDrawFromState.notificationsStartMinutesFromMidnightLocal));
      }

      this.newGameDrawSettingsForm.controls.maxTicketsPerDraw.setValue(newGameDrawFromState.maxTicketsPerDraw);
      this.newGameDrawSettingsForm.controls.manualDraw.setValue(newGameDrawFromState.manualDraw);

      if (newGameDrawFromState.instanceIntervalType) {
        this.newGameDrawSettingsForm.controls.instanceIntervalType.setValue(newGameDrawFromState.instanceIntervalType);
      }

      this.newGameDrawSettingsForm.controls.minimumGameLengthInHours.setValue(newGameDrawFromState.minimumGameLengthInHours);
      this.newGameDrawSettingsForm.controls.maxAmountPlayersCanSpendPerDraw.setValue(newGameDrawFromState.maxAmountPlayersCanSpendPerDraw / 100);
      this.newGameDrawSettingsForm.controls.drawDayOfWeek.setValue(newGameDrawFromState.drawDayOfWeek);
    }

    this.newGameDrawSettingsFormChange.emit(this.newGameDrawSettingsForm)
  }

}
