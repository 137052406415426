<div class="flex-row justify-center align-start margin-top-large">
  <mat-card class="flex-50">
    <mat-card-header>
      <mat-card-title class="text-center">Change Your Email Address</mat-card-title>
    </mat-card-header>

    <mat-card-content class="text-center margin-top-large">
      <div class="text-center margin-bottom-med">Current Email Address: <strong>{{ activeAdmin.Email }}</strong></div>
      <form [formGroup]="updateEmailForm">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>New Email Address</mat-label>
          <input
            data-testid="new-email-address-input"
            matInput
            name="newEmail"
            formControlName="newEmail"
            email
            required
            placeholder="Email"
          >
          @if (newEmailControl.errors && (newEmailControl.dirty || newEmailControl.touched)) {
            <mat-error data-testid="update-email-email-error">
              @if (newEmailControl.hasError('required')) {
                <strong>required.</strong>
              }
              @if (newEmailControl.hasError('email')) {
                incorrect email format. <strong>{{ 'ex: example@test.com' }}</strong>
              }
            </mat-error>
          }

        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width margin-bottom-small">
          <mat-label>Password</mat-label>
          <input
            data-testid="update-email-password-input"
            matInput
            [type]="showPassword ? 'text' : 'password'"
            formControlName="password"
            required
            placeholder="enter your password"
          >
          @if (passwordControl.hasError('required') && (passwordControl.dirty || passwordControl.touched)) {
            <mat-error data-testid="update-email-password-error">
              <strong>required.</strong>
            </mat-error>
          }

          <mat-icon matSuffix
                    (click)="showPassword = !showPassword">{{ showPassword ? 'visibility_off' : 'visibility' }}
          </mat-icon>
        </mat-form-field>

      </form>

      @if (showConfirmationCompleteMessage) {
        <p data-testid="confirmation-message">Please check your new email inbox to
          continue updating your email
          address.</p>
      }

      <button mat-raised-button
              data-testid="update-email-button"
              class="login-button"
              [disabled]="uiStateEnum == uiStateEnumForTemplate.ShowLoading || showConfirmationCompleteMessage"
              (click)="updateEmailAddress()">Update Email Address
      </button>
    </mat-card-content>

  </mat-card>
</div>
