import {Component} from '@angular/core';
import {OidcService} from "../../services/oidc.service";
import {MatCardContent, MatCardHeader, MatCardModule} from "@angular/material/card";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-user-is-not-admin-page',
  standalone: true,
  templateUrl: './user-is-not-admin-page.component.html',
  imports: [
    MatCardModule,
    MatCardHeader,
    MatCardContent,
    MatButton
  ],
  styleUrls: ['./user-is-not-admin-page.component.scss']
})
export class UserIsNotAdminPageComponent {

  constructor(private oidcService: OidcService) {
  }

  onLogoutClick(): void {
    this.oidcService.logout();
  }

}
