@if (uiState === UIStateEnum.ShowLoading) {
  <mat-spinner class="center-margin"></mat-spinner>
} @else if (uiState == UIStateEnum.ShowData) {
  @if (gamesPaymentTiers.length > 0) {
    <div class="margin-bottom-med">Here is your current payment tier(s):</div>

    @for (tier of gamesPaymentTiers; track tier) {
      <div>{{ tier.NumberOfTickets }} for {{ tier.Price | formatCurrency }}</div>
    }
  } @else {
    <div>Choose a payment tier(s) for your game</div>
    <ul>
      @for (tier of availablePaymentTiers; track tier) {
        <li>
          <mat-checkbox (change)="update($event.checked, tier.Id)">
            {{ tier.NumberOfTickets }} for {{ tier.Price | formatCurrency }}
          </mat-checkbox>
        </li>
      }
    </ul>

    @if (showNoPaymentTierMessage) {
      <mat-error class="margin-bottom-med">You must choose at least one payment tier</mat-error>
    }
    <div>
      <button mat-stroked-button data-testid="edit-how-to-play-button" (click)="addPaymentTierToGame()">Save</button>
    </div>

    <div>
      <div class="margin-top-med">Don't see a tier you want?</div>
      <button class="margin-top-med" mat-stroked-button data-testid="edit-how-to-play-button" (click)="addNewTier()">Add New Tier</button>
    </div>

  }
}
