<p>Choose a date range for your report. Only games with a draw date within provided date range will be considered in the report</p>
<form [formGroup]="comprehensiveDatesFromGroup">
  <mat-form-field class="full-width">
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate placeholder="Start date" formControlName="startDate" data-testid="date-range-report-start-date-input">
      <input matEndDate placeholder="End date" formControlName="endDate" data-testid="date-range-report-end-date-input">
    </mat-date-range-input>
    <mat-datepicker-toggle matIconSuffix [for]="picker" data-testid="date-range-report-datepicker-toggle"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
</form>

<div class="text-end">
  <button mat-raised-button (click)="onSubmit()" class="margin-top-med" data-testid="date-range-report-generate-button">Generate Reports</button>
</div>

@if(showReports) {
  <div class="margin-top-large">
    <mat-tab-group disableRipple dynamicHeight data-testid="report-tab-group">
      <mat-tab
        *hasPermission="permissionTypes.ViewComprehensiveGameReport"
        label="Comprehensive Report"
        labelClass="comprehensive-report-tab"
        data-testid="date-range-report-comprehensive-tab">
        <ng-template matTabContent>
          <app-comprehensive-report-by-date-range
            [dateRangesSetter]="dateRanges"></app-comprehensive-report-by-date-range>
        </ng-template>
      </mat-tab>
      <mat-tab
        *hasPermission="permissionTypes.ViewLotteryLicenseReport"
        label="Lottery License Report"
        labelClass="lottery-license-report-tab"
        data-testid="date-range-report-license-report-tab">
        <ng-template matTabContent>
          <app-lottery-license-report [dateRangesSetter]="dateRanges"></app-lottery-license-report>
        </ng-template>

      </mat-tab>
    </mat-tab-group>
  </div>
}
