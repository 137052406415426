<div class="text-center" *hasPermission="permissionTypes.ModifyAdminUsers">
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon class="menu-button"
              data-testid="show-admin-settings-menu"
              matTooltipPosition="right"
              matTooltip="open admin user menu"
    >more_horiz
    </mat-icon>
  </button>
</div>
<mat-menu #menu="matMenu">
  <button mat-menu-item
          data-testid="navigate-to-admin-details"
          (click)="onViewDetailsClick()">
    <mat-icon class="dark-grey menu-icon">settings</mat-icon>
    <span class="dark-grey menu-text">View Details</span>
  </button>

  <mat-divider></mat-divider>

  @if (!adminUser.Active) {
    <button mat-menu-item
            (click)="onReactiveUserClick()"
            data-testid="reactivate-user-menu-option">
      <mat-icon class="dark-grey">refresh</mat-icon>
      <span class="dark-grey menu-text">Reactivate</span>
    </button>
  } @else {
    <button mat-menu-item
            (click)="onDeactivateUserClick()"
            data-testid="deactivate-user-menu-option">
      <mat-icon class="red-text">do_disturb</mat-icon>
      <span class="red-text menu-text">Deactivate</span>
    </button>
  }
</mat-menu>
