<mat-nav-list data-testid="side-nav-container">
  @if ((activeUser$ | async); as activeUser) {
    @if (activeUser.CausableAdmin) {
      <div style="padding: 16px 0 0 16px;" class="white-text">
        Causable Admin
      </div>
      <hr class="white-text">
      @if (currentEnvironment.toLowerCase() === "development" || currentEnvironment.toLowerCase() === "local") {
        <mat-list-item [routerLink]="['testing-page']"
                       [matTooltipDisabled]="sideNavShouldOpen"
                       data-testid="testing-page-link"
                       matTooltip="Testing"
                       matTooltipPosition="right">
          <mat-icon matListItemIcon>checklist</mat-icon>
          <div matListItemLine>Testing</div>
        </mat-list-item>
      }

      <mat-list-item [routerLink]="['frequently-asked-questions']"
                     [matTooltipDisabled]="sideNavShouldOpen"
                     data-testid="current-draw-page-link"
                     matTooltip="FAQs"
                     matTooltipPosition="right">
        <mat-icon matListItemIcon>question_mark</mat-icon>
        <div matListItemLine data-testid="sidenav-current-draw">Frequently Asked Questions
        </div>
      </mat-list-item>
      <mat-list-item [routerLink]="['new-game']"
                     [matTooltipDisabled]="sideNavShouldOpen"
                     *userHasCausableAdminAccess
                     data-testid="new-game-page-link"
                     matTooltip="New Game"
                     matTooltipPosition="right">
        <mat-icon matListItemIcon>add</mat-icon>
        <div matListItemLine data-testid="sidenav-new-game">New Game
        </div>
      </mat-list-item>
      <mat-list-item [routerLink]="['game-instances']"
                     [matTooltipDisabled]="sideNavShouldOpen"
                     data-testid="game-instances-page-link"
                     *userHasCausableAdminAccess
                     matTooltip="Game Instances"
                     matTooltipPosition="right">
        <mat-icon matListItemIcon>supervised_user_circle</mat-icon>
        <div matListItemLine data-testid="sidenav-game-instances">Game
          Instances
        </div>
      </mat-list-item>
      <mat-list-item [routerLink]="['support-desk']"
                     *hasPermission="permissionTypes.ViewSupportRequests"
                     data-testid="causable-admin-support-desk-link"
                     matTooltip="Support Desk"
                     [matTooltipDisabled]="sideNavShouldOpen">
        <mat-icon matListItemIcon
                  [matBadge]="assignedSupportRequestsCount$ | async"
                  matBadgeColor="warn"
                  [matBadgeHidden]="(assignedSupportRequestsCount$ | async) == 0">support_agent</mat-icon>
        <div matListItemLine data-testid="causable-admin-support-desk">Support Desk</div>
      </mat-list-item>
      <mat-list-item [routerLink]="['player-search']"
                     *hasPermission="permissionTypes.ViewGlobalPlayerSearch"
                     data-testid="global-player-search">
        <mat-icon matListItemIcon>settings</mat-icon>
        <div matListItemLine data-testid="sidenav-global-search">Global Player Search</div>
      </mat-list-item>
      <mat-list-item [routerLink]="['profanity-filter-manager']"
                     data-testid="profanity-filter-manager-page-link"
                     *userHasCausableAdminAccess
                     matTooltip="Profanity Filter Manager"
                     [matTooltipDisabled]="sideNavShouldOpen">
        <mat-icon matListItemIcon>lock</mat-icon>
        <div matListItemLine data-testid="sidenav-profanity-filter-manager">Profanity Filter Manager</div>
      </mat-list-item>
    }

    @if (activeGameId$ | async; as gameId) {
      <div style="padding: 16px 0 0 16px;" class="white-text">
        Game Admin
      </div>
      <hr class="white-text">
      <mat-list-item [routerLink]="[gameId, 'active-game-instances']"
                     [matTooltipDisabled]="sideNavShouldOpen"
                     data-testid="current-draw-page-link"
                     matTooltip="Current Draw"
                     matTooltipPosition="right">
        <mat-icon matListItemIcon>games</mat-icon>
        <div matListItemLine data-testid="sidenav-current-draw">
          Current
          Draw
        </div>
      </mat-list-item>

      <mat-list-item [routerLink]="[gameId, 'manage-game']"
                     [matTooltipDisabled]="sideNavShouldOpen"
                     *hasPermission="permissionTypes.ViewGameSettings"
                     data-testid="manage-game-page-link"
                     matTooltip="Manage Game"
                     matTooltipPosition="right">
        <mat-icon matListItemIcon>settings</mat-icon>
        <div matListItemLine data-testid="sidenav-game-settings">Game
          Settings
        </div>
      </mat-list-item>
      <mat-list-item [routerLink]="[gameId, 'reports']"
                     [matTooltipDisabled]="sideNavShouldOpen"
                     *hasPermission="permissionTypes.ViewReports"
                     data-testid="reports-page-link"
                     matTooltip="Reports"
                     matTooltipPosition="right">
        <mat-icon matListItemIcon>request_page</mat-icon>
        <div matListItemLine data-testid="sidenav-reports">Reports
        </div>
      </mat-list-item>
      <mat-list-item [routerLink]="[gameId, 'manage-charities']"
                     [matTooltipDisabled]="sideNavShouldOpen"
                     data-testid="manage-charities-page-link"
                     *hasPermission="permissionTypes.ViewCharities"
                     matTooltip="Manage Charities"
                     matTooltipPosition="right">
        <mat-icon matListItemIcon>volunteer_activism</mat-icon>
        <div matListItemLine data-testid="sidenav-charities">Charities
        </div>
      </mat-list-item>
      <mat-list-item [routerLink]="[gameId, 'winner-payouts']"
                     data-testid="winner-payout-page-link"
                     *hasPermission="permissionTypes.ViewWinnerPayouts">
        <mat-icon matListItemIcon>money</mat-icon>
        <div matListItemLine data-testid="sidenav-winner-payouts">Winner
          Payouts
        </div>
      </mat-list-item>
      <mat-list-item [routerLink]="[gameId, 'player-search']"
                     [matTooltipDisabled]="sideNavShouldOpen"
                     data-testid="player-search-page-link"
                     *hasPermission="permissionTypes.ViewPlayerInfo"
                     matTooltip="Manage Players"
                     matTooltipPosition="right">
        <mat-icon matListItemIcon>manage_accounts</mat-icon>
        <div matListItemLine data-testid="sidenav-players">Players
        </div>
      </mat-list-item>
      @if(gameDrawnManually$ | async) {
        <mat-list-item [routerLink]="[gameId, 'draw-winner']"
                       [matTooltipDisabled]="sideNavShouldOpen"
                       data-testid="draw-winner-page-link"
                       *hasPermission="permissionTypes.DrawGameWinner"
                       matTooltip="Draw Winner"
                       matTooltipPosition="right">
          <mat-icon matListItemIcon>celebration</mat-icon>
          <div matListItemLine data-testid="sidenav-draw-winner">Draw
            Winner
          </div>
        </mat-list-item>
      }

      <!--        Disabled features-->

      <mat-list-item [routerLink]="[gameId, 'auditing']"
                     [matTooltipDisabled]="sideNavShouldOpen"
                     *hasPermission="permissionTypes.ViewAuditing"
                     matTooltip="Auditing"
                     data-testid="auditing-page-link"
                     matTooltipPosition="right">
        <mat-icon matListItemIcon>edit</mat-icon>
        <div matListItemLine data-testid="sidenav-auditing">Auditing
        </div>
      </mat-list-item>

      <mat-list-item [routerLink]="[gameId, 'manage-users']"
                     [matTooltipDisabled]="sideNavShouldOpen"
                     data-testid="manage-users-page-link"
                     *hasPermission="permissionTypes.ViewAdminUsers"
                     matTooltip="Manage Users"
                     matTooltipPosition="right">
        <mat-icon matListItemIcon>supervised_user_circle</mat-icon>
        <div matListItemLine data-testid="sidenav-admin-user">Admin
          Users
        </div>
      </mat-list-item>
      <mat-list-item [routerLink]="[gameId, 'manage-permissions']"
                     [matTooltipDisabled]="sideNavShouldOpen"
                     data-testid="manage-permissions-page-link"
                     *hasPermission="permissionTypes.ViewRolesAndPermissions"
                     matTooltip="Manage Permissions"
                     matTooltipPosition="right">
        <mat-icon matListItemIcon>supervised_user_circle</mat-icon>
        <div matListItemLine data-testid="sidenav-manage-permissions">
          Manage Permissions
        </div>
      </mat-list-item>
    }
  }

  @if (!(userIsLoggedIn$ | async)) {
    <mat-list-item routerLink="/login"
                   [matTooltipDisabled]="sideNavShouldOpen"
                   matTooltip="Login"
                   matTooltipPosition="right">
      <mat-icon matListItemIcon>login</mat-icon>
      <div matListItemLine data-testid="sidenav-login">Login</div>
    </mat-list-item>

  }
  @if ((availableGamesToUser$ | async)?.length! > 1) {
    <mat-list-item [routerLink]="['']"
                   [matTooltipDisabled]="sideNavShouldOpen"
                   data-testid="change-game-page-link"
                   matTooltip="Change Game"
                   matTooltipPosition="right">
      <mat-icon matListItemIcon>home</mat-icon>
      <div matListItemLine data-testid="sidenav-change-game">Landing
      </div>
    </mat-list-item>
  }

</mat-nav-list>
