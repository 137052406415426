import {Component, inject} from '@angular/core';
import {MatCardModule} from "@angular/material/card";
import {ChooseGameInstanceComponent} from "../../choose-game-instance/choose-game-instance.component";
import {IGameInstance} from "../../../interfaces/IGameInstance";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {CharityService} from "../../../services/charity.service";
import {PipesModule} from "../../../pipes/pipes.module";
import {ICharityDateRangeReport} from "../../../interfaces/charity/ICharityDateRangeReport";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-charity-draw-reporting',
  standalone: true,
  imports: [
    MatCardModule,
    ChooseGameInstanceComponent,
    MatProgressSpinner,
    PipesModule,
    MatButton
  ],
  templateUrl: './charity-draw-reporting.component.html',
  styleUrl: './charity-draw-reporting.component.css'
})
export class CharityDrawReportingComponent {
  private charityService: CharityService = inject(CharityService);
  private errorHandlingService: ErrorHandlingService = inject(ErrorHandlingService);

  public chosenGameInstance: IGameInstance | null = null;
  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnum = UIStateEnum;
  protected charityGameInstanceReport: ICharityDateRangeReport | null = null;

  updateChosenGameInstance(gameInstanceP: IGameInstance) {
    this.chosenGameInstance = gameInstanceP;
    this.uiState = UIStateEnum.ShowLoading;

    this.fetchCharityDrawReport(gameInstanceP.Id);
  }

  onGenerateDateRangeReport() {
    this.chosenGameInstance = null;
    this.charityGameInstanceReport = null;
  }

  private fetchCharityDrawReport(idP: string) {
    this.charityService.fetchCharityGameInstanceReport(this.charityService.activeCharity()?.Id!, idP).subscribe({
      next: (charityReportP) => {
        this.charityGameInstanceReport = charityReportP;
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    })
  }
}
