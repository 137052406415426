import {Component} from '@angular/core';
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {GameService} from "../../services/game.service";
import {AppConfigService} from "../../services/app-config.service";
import {IGameInstance} from "../../interfaces/IGameInstance";
import {MatDialog} from "@angular/material/dialog";
import {UpdateSensitiveGameSettingsComponent} from "../../components/dialogs/update-sensitive-game-settings/update-sensitive-game-settings.component";
import {Observable} from "rxjs";
import {PermissionTypes} from "../../enum/PermissionTypes";
import {
  UpdateGenericGameSettingsComponent
} from "../../components/dialogs/update-generic-game-settings/update-generic-game-settings.component";
import {
  RecalculateGameTotalsComponent
} from "../../components/dialogs/recalculate-game-totals/recalculate-game-totals.component";
import {GameStateEnum} from "../../enum/GameStateEnum";
import {ActiveUserService} from "../../services/active-user.service";
import {Router} from "@angular/router";
import {MatButton, MatFabButton, MatIconButton, MatMiniFabButton} from "@angular/material/button";
import {HasPermissionDirective} from "../../directives/permissions/has-permission";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {AsyncPipe, DatePipe} from "@angular/common";
import {MatTab, MatTabGroup} from "@angular/material/tabs";
import {PipesModule} from "../../pipes/pipes.module";
import {LotteryLicencesTableComponent} from "../../components/lottery-licences/lottery-licences-table/lottery-licences-table.component";
import {
  LotteryLicenceAddComponent
} from "../../components/lottery-licences/lottery-licence-add/lottery-licence-add.component";
import {TestimonialsTableComponent} from "../../components/testimonials/testimonials-table/testimonials-table.component";
import {AddTestimonialComponent} from "../../components/testimonials/add-testimonial/add-testimonial.component";

@Component({
  selector: 'app-manage-game',
  standalone: true,
  templateUrl: './manage-game.component.html',
  imports: [
    MatIconButton,
    HasPermissionDirective,
    MatMenuTrigger,
    MatIcon,
    MatTooltip,
    AsyncPipe,
    MatMenu,
    MatMenuItem,
    MatTab,
    MatTabGroup,
    MatButton,
    PipesModule,
    DatePipe,
    LotteryLicencesTableComponent,
    MatFabButton,
    MatMiniFabButton,
    TestimonialsTableComponent
  ],
  styleUrls: ['./manage-game.component.scss']
})
export class ManageGameComponent {

  public activeGame$: Observable<IGameQueryResult | undefined> = this.gameService.selectActiveGame$();
  public cdnRoot = this.appConfigService.cdnRoot();
  public gameStateEnum = GameStateEnum;
  public chosenGameInstance: IGameInstance | null = null;
  public activeUser =  this.activeUserService.activeUser();
  permissionTypes = PermissionTypes;

  constructor(private gameService: GameService,
              private matDialog: MatDialog,
              private activeUserService: ActiveUserService,
              private appConfigService: AppConfigService,
              private router: Router) {
  }

  public resetActiveGameInstance() {
    this.chosenGameInstance = null;
  }

  public onEditSensitiveSettingsClick() {
    this.matDialog.open(UpdateSensitiveGameSettingsComponent, {
      width: '650px'
    });
  }

  public onEditGenericSettingsClick() {
    this.matDialog.open(UpdateGenericGameSettingsComponent, {
      width: '650px'
    });
  }

  onRecalculateGameClick() {
    this.matDialog.open(RecalculateGameTotalsComponent, {
      width: '80vw'
    });
  }

  editGameRules() {
    let gameId = this.gameService.activeGame().Id;
    this.router.navigateByUrl(`${gameId}/edit-game-rules`);
  }

  editHowToPlay() {
    let gameId = this.gameService.activeGame().Id;
    this.router.navigateByUrl(`${gameId}/edit-how-to-play`)
  }

  openAddLicenceNumber() {
    this.matDialog.open(LotteryLicenceAddComponent, {
      width: '650px'
    });
  }

  openAddTestimonial() {
    this.matDialog.open(AddTestimonialComponent, {
      width: '650px'
    });
  }
}
