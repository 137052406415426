import {inject} from "@angular/core";
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import {map} from "rxjs";
import {catchError} from "rxjs/operators";
import {OidcService} from "../services/oidc.service";
import {ActiveUserService} from "../services/active-user.service";

export const CharityAdminGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const activeUserService = inject(ActiveUserService);
  const oidcService = inject(OidcService);

  const charityIdFromRoute = route.paramMap.get('charityId');

  const router = inject(Router);

  const is_authenticated = oidcService.isAuthenticated();
  if (!is_authenticated) {
    return router.navigateByUrl('auth/login');
  }

  const adminId = oidcService.getAdminIdFromClaims();

  if (adminId) {
    return activeUserService.retrieveActiveUser(adminId)
      .pipe(map((adminP) => {
          let hasPermissionToViewCharity = adminP.Charities.find((charityP) => {
            return charityP.Id === charityIdFromRoute;
          })

          if (hasPermissionToViewCharity) {
            return true;
          }

          return router.navigateByUrl('not-authorized');
        }),
        catchError(() => {
          return router.navigateByUrl('not-admin');
        }))
  } else {
    return router.navigateByUrl('not-admin');
  }

};
