import {Injectable} from '@angular/core';
import {concatMap, Observable} from 'rxjs';
import {createStore} from "@ngneat/elf";
import {APIService} from "./api.service";
import {selectAllEntities, setEntities, withEntities} from "@ngneat/elf-entities";
import {withRequestsCache} from "@ngneat/elf-requests";
import {tap} from "rxjs/operators";
import {IGameTestimonials} from "../interfaces/IGameTestimonials";
import {IAddTestimonials} from "../interfaces/IAddTestimonials";
import {IEditTestimonials} from "../interfaces/IEditTestimonials";

@Injectable({
  providedIn: 'root'
})
export class TestimonialsService{

  private testimonialsStore = createStore(
    {name: 'all-game-testimonials'},
    withEntities<IGameTestimonials, 'Id'>({idKey: 'Id'}),
    withRequestsCache<'all-games'>()
  );

  constructor(private apiService: APIService) {
  }

  public selectGameTestimonials$(): Observable<IGameTestimonials[]> {
    return this.testimonialsStore.pipe(selectAllEntities());
  }

  public fetchTestimonials(gameIdP: string): Observable<IGameTestimonials[]> {
    return this.apiService.MakeGetRequest<IGameTestimonials[]>(`testimonials/${gameIdP}?activeOnlyP=false`).pipe(tap((res: IGameTestimonials[]) => {
      this.testimonialsStore.update(setEntities(res));
    }));
  }

  public addTestimonial(lotteryLicenceP: IAddTestimonials) {
    return this.apiService.MakePostRequest(`game/testimonials`, lotteryLicenceP).pipe(concatMap(() => {
      return this.fetchTestimonials(lotteryLicenceP.gameId);
    }));
  }

  public editTestimonials(lotteryLicenceP: IEditTestimonials) {
    return this.apiService.MakePutRequest(`game/testimonials`, lotteryLicenceP).pipe(concatMap(() => {
      return this.fetchTestimonials(lotteryLicenceP.gameId);
    }));
  }
}
