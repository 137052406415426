@if(activeCharity$ | async; as chosenCharity) {
  <div class="flex-row justify-space-between">
    <h2 data-testid="charity-title">{{chosenCharity.Name}}
      @if(!chosenCharity.Active) {
        <span class="inactive"> (inactive)</span>
      }
      <button mat-icon-button
              data-testid="open-charity-options-menu-button"
              id="charity-menu-button"
              *hasPermission="permissionTypes.ModifyCharities"
              [matMenuTriggerFor]="menu">
        <mat-icon matTooltipPosition="right"
                  matTooltip="open admin user menu"
        >more_vertical
        </mat-icon>
      </button>
    </h2>
  </div>
  <div class="margin-bottom-med">
    <button mat-raised-button (click)="onBackButtonClick()" data-testid="back-to-charities-per-category-button">
      <mat-icon>arrow_back_ios</mat-icon>
      Back
    </button>
  </div>
  <div class="custom-card-border margin-top-med" data-testid="charity-detail-section">
    <div class="flex-row-wrap justify-start gap-normal margin-bottom-med margin-top-med">

      <div class="detail-section">
        <div class="detail-label">Name</div>
        <div class="detail-value" data-testid="charity-details-name">{{chosenCharity.Name}}</div>
      </div>

      <div class="detail-section">
        <div class="detail-label">Belongs to Category</div>
        <div class="detail-value" data-testid="charity-category-name">{{chosenCharity.CharityCategoryName}}</div>
      </div>

      <div class="detail-section">
        <div class="detail-label">Status</div>
        <div class="detail-value" data-testid="charity-active-status">
          {{chosenCharity.Active ? 'Active' : 'Deactivated'}}
        </div>
      </div>

      <div class="detail-section">
        <div class="detail-label">Created On</div>
        <div class="detail-value" data-testid="charity-created-on">{{chosenCharity.CreatedOn | date: 'yyyy-MM-dd h:mm a'}}</div>
      </div>

      <div class="detail-section">
        <div class="detail-label">Total Amount Raised</div>
        <div class="detail-value" data-testid="charity-total-amount-raised">{{chosenCharity.TotalCharityContributions | formatCurrency}}</div>
      </div>

      <div class="detail-section">
        <div class="detail-label">Year to Date Amount Raised</div>
        <div class="detail-value" data-testid="charity-year-to-date-raised">{{chosenCharity.YearToDateContributions | formatCurrency}}</div>
      </div>
    </div>
  </div>

  <mat-menu #menu="matMenu">
    <button mat-menu-item
            (click)="openChangeNameDialog()"
            data-testid="edit-charity-name-option">
      <mat-icon class="dark-grey menu-icon">edit</mat-icon>
      <span class="dark-grey menu-text">Edit Name</span>
    </button>

    <button mat-menu-item
            (click)="onChangeCategoriesClick(chosenCharity)"
            data-testid="change-charity-categories-option">
      <mat-icon class="dark-grey menu-icon">compare_arrows</mat-icon>
      <span class="dark-grey menu-text">Change categories</span>
    </button>
    @if(!chosenCharity.Active) {
      <button mat-menu-item
              *hasPermission="permissionTypes.ReactivateCharities"
              data-testid="reactivate-charity-option"
              (click)="onReactivateCharityClick(chosenCharity)">
        <mat-icon class="green-text">refresh</mat-icon>
        <span class="green-text menu-text">Reactivate</span>
      </button>
    }@else {
      <button mat-menu-item
              (click)="onDeactivateCharityClick(chosenCharity)"
              *hasPermission="permissionTypes.DeactivateCharities"
              data-testid="deactivate-charity-option">
        <mat-icon class="red-text">do_disturb</mat-icon>
        <span class="red-text menu-text">Deactivate</span>
      </button>
    }
  </mat-menu>
}



