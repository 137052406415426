import {Component, Input} from '@angular/core';
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {DialogLevelErrorMessageComponent} from "../../display-errors/dialog-level-error-message.component";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-dialog-base',
  standalone: true,
  templateUrl: './dialog-base.component.html',
  imports: [
    MatDialogTitle,
    DialogLevelErrorMessageComponent,
    MatDialogContent,
    MatProgressSpinner,
    MatDialogActions,
    MatDialogClose,
    MatButton
  ],
  styleUrls: ['./dialog-base.component.css']
})
export class DialogBaseComponent {

  @Input()
  public showLoadingSpinner: boolean = false;

  constructor(private errorHandlingService: ErrorHandlingService) {
    this.errorHandlingService.clearDialogErrorMessages();
  }

}
