<app-dialog-base [showLoadingSpinner]="uiState == uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Audit Event Data</span>

  <ng-container dialog-content>
    <div>
      <div class="margin-top-med">
        <div class="bold-text">Event Type</div>
        <div data-testid="audit-event-type">{{ data.audit.AuditEventTypeId | auditEventTypeToDisplay }}</div>
      </div>

      <div class="margin-top-med">
        <div class="bold-text">Created On</div>
        <div data-testid="audit-created-on">{{ data.audit.CreatedOn | date: 'medium' }}</div>
      </div>

      <div class="margin-top-med meta-data">
        <div class="bold-text">Meta Data</div>
        @if (metaData) {
          <pre><app-formatter [data]="metaData"></app-formatter></pre>
        }
      </div>
    </div>

  </ng-container>
  <ng-container actions></ng-container>
</app-dialog-base>
