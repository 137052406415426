import {Pipe, PipeTransform} from '@angular/core';
import {GameInstanceStatuses} from "../enum/GameInstanceStatusEnum";

@Pipe({
  name: 'gameInstanceStatus'
})
export class GameInstanceStatusPipe implements PipeTransform {
  public transform(statusP: GameInstanceStatuses): string {
    if (statusP) {
      switch (statusP){
        case GameInstanceStatuses.Active: return 'Active';
        case GameInstanceStatuses.ReadyForDraw: return 'Ready For Draw';
        case GameInstanceStatuses.Drawn: return 'Drawn';
        case GameInstanceStatuses.WinnerAnnounced: return 'Winner Announced';
        case GameInstanceStatuses.Pending: return 'Pending';
        case GameInstanceStatuses.EndedWithoutPlayers: return 'Ended Without Players';
      }
    }
    return 'Unknown Status';
  }
}
