import {Injectable} from '@angular/core';
import {APIService} from "./api.service";
import {IPlayerNote} from "../interfaces/player/IPlayerNote";
import {IPlayerNoteCommand} from "../interfaces/player/IPlayerNoteCommand";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PlayerGameNoteService {

  public fetchNotesSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public fetchNotesSubject$: Observable<boolean> = this.fetchNotesSubject.asObservable();

  constructor(private apiService: APIService) {
  }

  public addPlayerGameNote(noteP: IPlayerNoteCommand) {
    return this.apiService.MakePostRequest<IPlayerNote>("player/add-note", noteP);
  }

  public updatePlayerGameNote(noteP: IPlayerNoteCommand) {
    return this.apiService.MakePutRequest<IPlayerNote>("player/update-note", noteP);
  }

  public getPlayerNotesForGame(playerIdP: string, gameIdP: string): Observable<IPlayerNote[]> {
    return this.apiService.MakeGetRequest<IPlayerNote[]>(`player/notes/${playerIdP}/${gameIdP}`);
  }

}
