import {Component, inject, Inject} from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarAction,
  MatSnackBarActions,
  MatSnackBarLabel,
  MatSnackBarRef
} from "@angular/material/snack-bar";
import {MatIcon} from "@angular/material/icon";
import {MatButton, MatIconButton} from "@angular/material/button";

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  standalone: true,
  imports: [
    MatSnackBarLabel,
    MatIcon,
    MatSnackBarAction,
    MatButton,
    MatSnackBarActions,
    MatIconButton
  ],
  styleUrls: ['./snack-bar.component.css']
})
export class SnackBarComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string) {
  }
  snackBarRef = inject(MatSnackBarRef);
}
