import {Injectable} from '@angular/core';
import {Content} from 'pdfmake/interfaces';

@Injectable(
  {providedIn: 'root'}
)
export class PdfUtilityService {

  public PdfStyles: any = {
    pageTitle: {
      bold: true,
      fontSize: 16,
      alignment: 'center'
    },
    subtitle: {
      bold: true,
      color: '#737373',
      fontSize: 12,
      alignment: 'center'
    },
    sectionTitle: {
      bold: true,
      fontSize: 13
    },
    footer: {
      alignment: 'center',
      fontSize: 9,
      margin: [0, 10, 0, 10]
    }
  };

  public generateLabelValueRow(labelP: string, valueP: string): Content {
    return {
      text: [
        {
          text: labelP,
          bold: true,
          fontSize: 11
        },
        {
          text: valueP,
          fontSize: 10
        }
      ],
      margin: [16, 4, 0, 4]
    };
  }

  public generateSectionTitle(titleP: string): Content {
    return {
      text: titleP,
      style: this.PdfStyles.sectionTitle,
      margin: [0, 8, 0, 8]
    };
  }

  public AddLineBreak(): Content {
    return {
      canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.5}],
      margin: [0, 4, 0, 4]
    };
  }
}
