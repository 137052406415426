<app-dialog-base [showLoadingSpinner]="uiState == uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Add Charities</span>
  <ng-container dialog-content>
    @if (uiState === uiStateEnumForTemplate.ShowData) {
    <form
      [formGroup]="createCharityForm">
      <p>Choose the category you would like to add charities to:</p>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Charity Category</mat-label>
        <mat-select
          data-testid="add-charity-category-select"
          formControlName="categoryControl">
          <mat-option [value]="null"> ----</mat-option>

          @for (category of charityCategories; track category) {
    <mat-option data-testid="add-charity-category-option"
                [value]="category">
      {{ category.Name }}
    </mat-option>
    }

    </mat-select>

    @if (createCharityForm.controls['categoryControl'].hasError('required')) {
    <mat-error data-testid="charity-category-error">
      category is <span class="bold-text">required</span>
    </mat-error>
    }

    </mat-form-field>

    @if (createCharityForm.controls.categoryControl.value?.HighestCharitySortOrder === 0) {
    <small class="red-text">
      Note*: The chosen category currently has no associated charities. The category will not appear in the web
      application until a charity has been linked to it.
    </small>

    }

    @if (!createCharityForm.controls['categoryControl'].valid) {
    <p class="text-end">Can't find your category?
      <button mat-flat-button (click)="openAddNewCategoryDialog()" data-testid="add-new-charity-category-button">
        <span class="text-underline">ADD NEW CATEGORY</span>
      </button>
    </p>
    }

    @if (createCharityForm.controls['categoryControl'].valid) {
    <p>Enter new charity name and then click "Add to request". Multiple charities can be added to one request.</p>
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Enter a new charity name</mat-label>
      <input
        data-testid="new-charity-name-input"
        formControlName="charityNameControl"
        type="text"
        matInput
        placeholder="enter charity name">

      @if (createCharityForm.controls['charityNameControl'].hasError('notAddedToRequest')) {
      <mat-error
        data-testid="add-request-error">
        make sure you click "Add to request"
      </mat-error>
      }

    </mat-form-field>
    <button class="float-right"
            mat-raised-button
            data-testid="add-charity-to-request-button"
            [disabled]="createCharityForm.controls.charityNameControl.value === ''" (click)="addToCharityList()">
      <mat-icon>add</mat-icon>
      Add to request
    </button>
    }

    </form>
    }
    @if (listOfNewCharities.length > 0) {
    <h3>New Charities</h3>
    @for (charity of listOfNewCharities; track charity) {
    <div class="flex-row justify-space-between align-center">
      <div>
        <span class="margin-left-med" data-testid="new-charity-name-from-request">{{ charity.name }}</span>
      </div>
      <button matSuffix
              data-testid="remove-new-charity-name-from-request-button"
              mat-icon-button
              aria-label="remove from request"
              matTooltip="remove from request"
              (click)="removeFromCharityList(charity)">
        <mat-icon [matTooltip]="'click here to remove from list'">remove</mat-icon>
      </button>
    </div>
    }
    }
  </ng-container>
  <ng-container actions>
    <button
      mat-button
      data-testid="save-new-charities-button"
      color="primary"
      [disabled]="uiState == uiStateEnumForTemplate.ShowLoading"
      (click)="submitNewCharities()">Save
    </button>
  </ng-container>
</app-dialog-base>
