import {Injectable} from '@angular/core';
import {concatMap, Observable} from 'rxjs';
import {createStore} from "@ngneat/elf";
import {APIService} from "./api.service";
import {selectAllEntities, setEntities, withEntities} from "@ngneat/elf-entities";
import {withRequestsCache} from "@ngneat/elf-requests";
import {tap} from "rxjs/operators";
import {IWinnerPayoutWithWithWinnerInfo} from "../interfaces/IWinnerPayoutWithWithWinnerInfo";
import {IUpdateWinnerPayout} from "../interfaces/IUpdateWinnerPayout";

@Injectable({
  providedIn: 'root'
})
export class WinnerPayoutService {

  private payoutsStore = createStore(
    {name: 'all-winner-payouts'},
    withEntities<IWinnerPayoutWithWithWinnerInfo, 'Id'>({idKey: 'Id'}),
    withRequestsCache<'all-games'>()
  );

  constructor(private apiService: APIService) {
  }

  public selectPayouts$(): Observable<IWinnerPayoutWithWithWinnerInfo[]> {
    return this.payoutsStore.pipe(selectAllEntities());
  }

  public fetchWinnerPayouts(gameIdP: string): Observable<IWinnerPayoutWithWithWinnerInfo[]> {
    return this.apiService.MakeGetRequest<IWinnerPayoutWithWithWinnerInfo[]>(`winner-payout/for-game/${gameIdP}`).pipe(tap((res: IWinnerPayoutWithWithWinnerInfo[]) => {
      this.payoutsStore.update(setEntities(res));
    }));
  }

  public updateWinnerPayout(winnerPayoutP: IUpdateWinnerPayout): Observable<IWinnerPayoutWithWithWinnerInfo[]> {
    return this.apiService.MakePutRequest<IWinnerPayoutWithWithWinnerInfo[]>(`winner-payout`, winnerPayoutP).pipe(concatMap((res) => {
      return this.fetchWinnerPayouts(winnerPayoutP.gameId);
    }));
  }
}
