import {Component} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {GameService} from "../../../services/game.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {IGroupedRecalculationEntries} from "../../../interfaces/IGroupedRecalculationEntries";
import {ColDef} from "ag-grid-community";
import {DatePipe} from "@angular/common";
import {EntityChangesComponent} from "../../cell-renderers/entity-changes/entity-changes.component";
import {CopyValueComponent} from "../../cell-renderers/copy-value/copy-value.component";
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {MatTabsModule} from "@angular/material/tabs";
import {AgGridAngular} from "ag-grid-angular";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-recalculate-game-totals',
  standalone: true,
  templateUrl: './recalculate-game-totals.component.html',
  imports: [
    DialogBaseComponent,
    MatTabsModule,
    AgGridAngular,
    MatButton
  ],
  styleUrls: ['./recalculate-game-totals.component.scss']
})
export class RecalculateGameTotalsComponent {
  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateForTemplate = UIStateEnum;
  public recalculationSubmitted = false;
  public recalculationCompleted = false;
  public groupedEntries: IGroupedRecalculationEntries[] = [];
  public changeColDefs: ColDef[] = [
    {
      headerName: 'Id',
      field: 'EntityId',
      resizable: true,
      sortable: true,
      cellRenderer: CopyValueComponent,
      flex: 1,
      wrapText: true,
      autoHeight: true
    },
    {
      headerName: 'Created On',
      resizable: true,
      sortable: true,
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y');
        return convertedDate ? convertedDate : '';
      },
      field: 'EntityCreatedOnDate',
      wrapText: true,
      width: 130,
      autoHeight: true
    },
    {
      headerName: 'Changes',
      resizable: true,
      sortable: true,
      cellRenderer: EntityChangesComponent,
      field: 'FieldUpdates',
      flex: 2,
      wrapText: true,
      autoHeight: true
    }
  ];

  constructor(private gameService: GameService,
              private datePipe: DatePipe,
              private errorHandlingService: ErrorHandlingService) {
  }

  onRecalculateGameClick() {
    this.uiState = UIStateEnum.ShowLoading;
    this.recalculationCompleted = false;
    this.gameService.recalculateGame().subscribe({
      next: (resP) => {
        this.groupedEntries = resP;
        this.recalculationSubmitted = true;
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage(err, true);
      }
    })
  }

  onSubmitChanges() {
    this.uiState = UIStateEnum.ShowLoading;
    this.gameService.commitRecalculationChanges().subscribe({
      next: () => {
        this.groupedEntries = [];
        this.recalculationCompleted = true;
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage(err, true);
      }
    })
  }
}
