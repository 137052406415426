import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'prettyprint'
})
export class PrettyPrintPipe implements PipeTransform {
  transform(json: any): any {

    json = JSON.stringify(json, undefined, 2);

    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match: any) {
      var cls = 'meta-data-number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'meta-data-key';
        } else {
          cls = 'green-text';
        }
      } else if (/true|false/.test(match)) {
        cls = 'meta-data-boolean';
      } else if (/null/.test(match)) {
        cls = 'meta-data-null';
      }
      return '<span class="' + cls + '">' + match + '</span>';
    });
  }

}
