import {Component} from '@angular/core';
import {MatProgressBar} from "@angular/material/progress-bar";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-loading-card-content',
  standalone: true,
  templateUrl: './loading-card-content.component.html',
  imports: [
    MatProgressBar,
    MatProgressSpinner
  ],
  styleUrls: ['./loading-card-content.component.scss']
})
export class LoadingCardContentComponent {

  constructor() {
  }


}
