import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {NewGameService} from "../../../services/new-game.service";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {IGameMetadataSettingsForm} from "../../../interfaces/new-game/forms/IGameMetadataSettingsForm";
import {MatError, MatFormField, MatHint, MatLabel} from "@angular/material/form-field";
import {NgIf} from "@angular/common";
import {MatInput} from "@angular/material/input";

@Component({
  selector: 'app-game-metadata-settings-form',
  standalone: true,
  templateUrl: './game-metadata-settings-form.component.html',
  imports: [
    MatFormField,
    ReactiveFormsModule,
    MatHint,
    MatLabel,
    MatError,
    NgIf,
    MatInput
  ],
  styleUrl: './game-metadata-settings-form.component.css'
})
export class GameMetadataSettingsFormComponent implements OnInit {

  private newGameService: NewGameService = inject(NewGameService);

  private urlRegex: RegExp = /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9][a-zA-Z0-9_-]+\.[a-zA-Z]{2,9}(\/.*)?$/;

  @Input() newGameMetadataSettingsForm: FormGroup<IGameMetadataSettingsForm> = new FormGroup<IGameMetadataSettingsForm>({
    metadataDescription: new FormControl<string>(''),
    metadataImageUrl: new FormControl<string>('', Validators.pattern(this.urlRegex)),
    metadataSiteName: new FormControl<string>(''),
    metadataTitle: new FormControl<string>(''),
    metadataUrl: new FormControl<string>('', Validators.pattern(this.urlRegex)),
    metadataType: new FormControl<string>(''),
    metadataFaviconUrl: new FormControl<string>('', Validators.pattern(this.urlRegex))
  });

  @Output() newGameMetadataSettingsFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  ngOnInit(): void {
    this.populateGameMetaDataFromState()
  }

  onFormChange() {
    this.newGameMetadataSettingsFormChange.emit(this.newGameMetadataSettingsForm)
  }

  private populateGameMetaDataFromState() {
    const newGameMetadata = this.newGameService.getNewGameRequest()?.newGameMetadata;

    if (newGameMetadata) {

      this.newGameMetadataSettingsForm.controls.metadataDescription.setValue(newGameMetadata.metadataDescription);
      this.newGameMetadataSettingsForm.controls.metadataImageUrl.setValue(newGameMetadata.metadataImageUrl);
      this.newGameMetadataSettingsForm.controls.metadataSiteName.setValue(newGameMetadata.metadataSiteName);
      this.newGameMetadataSettingsForm.controls.metadataTitle.setValue(newGameMetadata.metadataTitle);
      this.newGameMetadataSettingsForm.controls.metadataUrl.setValue(newGameMetadata.metadataUrl);
    }

    this.newGameMetadataSettingsFormChange.emit(this.newGameMetadataSettingsForm)
  }

}
