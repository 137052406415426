import {Component, Inject, OnInit} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent, MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInput} from "@angular/material/input";
import {PipesModule} from "../../../pipes/pipes.module";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatButton} from "@angular/material/button";
import {IWinnerPayoutWithWithWinnerInfo} from "../../../interfaces/IWinnerPayoutWithWithWinnerInfo";
import {DatePipe} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {WinnerPayoutService} from "../../../services/winner-payout.service";
import {IUpdateWinnerPayout} from "../../../interfaces/IUpdateWinnerPayout";
import {WinnerPayoutStatusTypes} from "../../../enum/WinnerPayoutStatusTypes";
import {SnackbarService} from "../../../services/snackbar.service";
import {GameService} from "../../../services/game.service";
import {AgGridAngular} from "ag-grid-angular";

@Component({
  selector: 'app-edit-winner-payout-dialog',
  standalone: true,
  templateUrl: './edit-winner-payout-dialog.component.html',
  imports: [
    MatDialogContent,
    MatDialogTitle,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInput,
    ReactiveFormsModule,
    PipesModule,
    MatProgressSpinner,
    MatDialogActions,
    MatDialogClose,
    MatButton,
    DatePipe,
    MatIcon,
    MatSlideToggle,
    AgGridAngular
  ],
  styleUrls: ['./edit-winner-payout-dialog.component.css']
})
export class EditWinnerPayoutDialogComponent implements OnInit {

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public overrideEnabled = false;
  public paymentAmountControl = new FormControl<number | null>(0, [
    Validators.required
  ]);
  public paymentOverrideReasonControl = new FormControl<string>('');
  public paymentDateControl = new FormControl<Date>(new Date(), Validators.required);

  public makeWinnerPaymentForm = new FormGroup({
    paymentDateControl: this.paymentDateControl,
    paymentAmount: this.paymentAmountControl,
    paymentOverrideReason: this.paymentOverrideReasonControl
  });

  constructor(@Inject(MAT_DIALOG_DATA) public winnerPayout: IWinnerPayoutWithWithWinnerInfo,
              private snackBarService: SnackbarService,
              private gameService: GameService,
              private matDialogRef: MatDialogRef<EditWinnerPayoutDialogComponent>,
              private winnerPayoutService: WinnerPayoutService) {
  }

  ngOnInit(): void {
    this.updateOverrideReasonValidators();
    this.populateForm();
  }

  private populateForm() {
    this.paymentDateControl.setValue(this.winnerPayout.PaidOutOnDate ? this.winnerPayout.PaidOutOnDate: new Date());
    this.paymentAmountControl.setValue(this.winnerPayout.PayoutAmount ? this.winnerPayout.PayoutAmount / 100 : 0);
    this.paymentOverrideReasonControl.setValue(this.winnerPayout.PayoutOverrideReason);
    this.overrideEnabled = this.winnerPayout.PayoutOverrideCreated;
    this.updateOverrideReasonValidators();
  }

  onToggleChange(event: any): void {
    this.overrideEnabled = event.checked;
    this.updateOverrideReasonValidators();
  }

  private updateOverrideReasonValidators(): void {
    if (this.overrideEnabled) {
      this.paymentOverrideReasonControl.setValidators(Validators.required);
      this.paymentAmountControl.clearValidators();
      this.paymentAmountControl.setValidators(Validators.required)
    } else {
      this.paymentOverrideReasonControl.setValue('');
      this.paymentOverrideReasonControl.clearValidators();
      this.paymentAmountControl.clearValidators();
      this.paymentAmountControl.setValidators([Validators.required, this.paymentAmountValidator(this.winnerPayout.PlayerWinnings)])
    }

    this.paymentOverrideReasonControl.updateValueAndValidity();
    this.paymentAmountControl.updateValueAndValidity();
  }

  paymentAmountValidator(winnings: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {

      if (this.overrideEnabled) {
        return null;
      }
      const convertedDollarAmount = control.value * 100;
      return convertedDollarAmount != winnings ? {'amountExceedsWinnings': true} : null;
    };
  }

  submitPayout() {

    if (!this.makeWinnerPaymentForm.valid) {
      this.makeWinnerPaymentForm.markAllAsTouched();
      return;
    }
    this.uiState = UIStateEnum.ShowRequestProcessing;

    let pay_winner_request: IUpdateWinnerPayout = {
      id: this.winnerPayout.Id,
      // have to make it pennies for DB
      payoutAmount: this.paymentAmountControl.value! * 100,
      paidOutOnDate: this.paymentDateControl.value!,
      payoutClaimStatus: WinnerPayoutStatusTypes.Paid,
      payoutOverrideReason: this.overrideEnabled ? this.paymentOverrideReasonControl.value! : '',
      payoutOverrideCreated: this.overrideEnabled,
      gameId: this.gameService.activeGame().Id
    }

    this.winnerPayoutService.updateWinnerPayout(pay_winner_request).subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
        this.snackBarService.openSuccessfulSnackBar('Successfully updated winner payout');
        this.matDialogRef.close();
      },
      error: () => {
        this.uiState = UIStateEnum.ShowData;
        this.snackBarService.openErrorSnackBar('Failed to update winner payout');
      }
    })
  }
}
