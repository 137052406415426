import {Component, inject, Input} from '@angular/core';
import {GameService} from "../../services/game.service";
import {IPaymentTier} from "../../interfaces/player/IPaymentTier";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {GameTypeEnum} from "../../enum/GameTypeEnum";
import {PipesModule} from "../../pipes/pipes.module";
import {FormsModule} from "@angular/forms";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatButton} from "@angular/material/button";
import {MatError} from "@angular/material/form-field";
import {IAddPaymentTiersToGame} from "../../interfaces/IAddPaymentTiersToGame";
import {ActiveUserService} from "../../services/active-user.service";
import {SnackbarService} from "../../services/snackbar.service";
import {NewPaymentTierComponent} from "../dialogs/new-payment-tier/new-payment-tier.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-edit-payment-tiers-fifty-fifty',
  standalone: true,
  imports: [
    MatProgressSpinner,
    PipesModule,
    FormsModule,
    MatCheckbox,
    MatButton,
    MatError
  ],
  templateUrl: './edit-payment-tiers-fifty-fifty.component.html',
  styleUrl: './edit-payment-tiers-fifty-fifty.component.css'
})
export class EditPaymentTiersFiftyFiftyComponent {
  private gameService: GameService = inject(GameService);
  private activeUserService: ActiveUserService = inject(ActiveUserService);
  private errorHandlingService: ErrorHandlingService = inject(ErrorHandlingService);
  private snackbarService: SnackbarService = inject(SnackbarService);
  private matDialog: MatDialog = inject(MatDialog);

  @Input() set gameSetter(gameP: IGameQueryResult | null) {
    if (gameP?.Id) {
      this.game = gameP;
      this.fetchPaymentTiers(gameP);
    }
  }

  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  protected readonly UIStateEnum = UIStateEnum;
  protected readonly GameTypeEnum = GameTypeEnum;

  public availablePaymentTiers: IPaymentTier[] = [];
  public gamesPaymentTiers: IPaymentTier[] = [];
  public gameId: string = '';
  public game: IGameQueryResult | null = null;

  public selectedPaymentTiers: IPaymentTier[] = [];
  public showNoPaymentTierMessage: boolean = false;

  update(checked: boolean, paymentTierId: string) {
    const tierToUpdate = this.availablePaymentTiers.find((tierP) => tierP.Id === paymentTierId);
    if (tierToUpdate) {
      if (checked) {
        this.selectedPaymentTiers.push(tierToUpdate);
      } else {
        this.selectedPaymentTiers = this.selectedPaymentTiers.filter((tier) => tier.Id !== paymentTierId);
      }
    }
  }

  fetchPaymentTiers(gameP: IGameQueryResult) {
    this.uiState = UIStateEnum.ShowLoading;
    this.gameService.getAllPaymentTiers(gameP.Id)
      .subscribe({
        next: (paymentTiersP) => {
          this.uiState = UIStateEnum.ShowData;
          this.gameId = gameP.Id;
          this.availablePaymentTiers = paymentTiersP;
          this.gamesPaymentTiers = gameP.PaymentTiers;
        }, error: (err) => {
          this.uiState = UIStateEnum.ShowData;
          this.errorHandlingService.displayPageLevelErrorMessage(err);
        }
      })
  }

  addPaymentTierToGame() {
    if (this.selectedPaymentTiers.length <= 0) {
      this.showNoPaymentTierMessage = true;
      return;
    }

    this.showNoPaymentTierMessage = false;
    this.uiState = UIStateEnum.ShowLoading;

    const addPaymentTierRequest: IAddPaymentTiersToGame = {
      gameId: this.gameId,
      adminId: this.activeUserService.activeUser().Id,
      paymentTierIds: this.selectedPaymentTiers.map((tier) => tier.Id)
    };

    this.gameService.addPaymentTierToGame(addPaymentTierRequest)
      .subscribe({
        next: (updatedGameRes) => {
          this.game = updatedGameRes;
          this.gamesPaymentTiers = updatedGameRes.PaymentTiers;
          this.uiState = UIStateEnum.ShowData;
          this.snackbarService.openSuccessfulSnackBar('Successfully added payment tier');
        }, error: () => {
          this.uiState = UIStateEnum.ShowData;
          this.snackbarService.openErrorSnackBar('Failed to add payment tier');
        }
      })
  }

  addNewTier() {
    const dialogRef = this.matDialog.open(NewPaymentTierComponent,
      {
        data: this.gameId
      });

    dialogRef.afterClosed().subscribe({
      next: (successfullyAddedTier) => {
        if (successfullyAddedTier) {
          this.fetchPaymentTiers(this.game!)
        }
      }
    })
  }

}
