<div class="flex-row justify-center align-start margin-top-large">
  <mat-card class="login-card">
    <mat-card-header>
      <mat-card-title class="text-center">Logout Page</mat-card-title>
    </mat-card-header>

    <mat-card-content class="text-center margin-top-large">
      <button mat-raised-button class="login-button" (click)="onLogoutClick()" data-testid="logout-button">LOGOUT</button>
    </mat-card-content>
  </mat-card>
</div>

