<form [formGroup]="newGamePaymentSettingsForm" (change)="onFormChange()">

  <mat-form-field appearance="outline" class="full-width">
    <mat-label>Causable Fee Multiplier (%)</mat-label>
    <input formControlName="causableFeeMultiplier"
           placeholder="enter causable fee percentage" matInput>
    <mat-hint>% of each payment that will go to causable</mat-hint>
    <mat-error data-testid="causable-fee-multiplier-error"
      *ngIf="newGamePaymentSettingsForm.controls.causableFeeMultiplier.errors && (newGamePaymentSettingsForm.controls.causableFeeMultiplier.touched || newGamePaymentSettingsForm.controls.causableFeeMultiplier.dirty)">
      <ng-container *ngIf="newGamePaymentSettingsForm.controls.causableFeeMultiplier.hasError('required')">
        required
      </ng-container>
      <ng-container *ngIf="newGamePaymentSettingsForm.controls.causableFeeMultiplier.hasError('min')">
        cannot be 0%
      </ng-container>
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>Tax Multiplier (%)</mat-label>
    <input formControlName="taxMultiplier"
           placeholder="enter tax %" matInput>
    <mat-hint>% of each payment that will go to taxes
    </mat-hint>
    <mat-error data-testid="tax-multiplier-error"
      *ngIf="newGamePaymentSettingsForm.controls.taxMultiplier.errors && (newGamePaymentSettingsForm.controls.taxMultiplier.touched || newGamePaymentSettingsForm.controls.taxMultiplier.dirty)">
      <ng-container *ngIf="newGamePaymentSettingsForm.controls.taxMultiplier.hasError('required')">
        required
      </ng-container>
      <ng-container *ngIf="newGamePaymentSettingsForm.controls.taxMultiplier.hasError('min')">
        cannot be 0%
      </ng-container>
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>Administration Commission Multiplier (%)</mat-label>
    <input formControlName="administrationCommissionMultiplier"
           placeholder="enter admin fee percentage" matInput>
    <mat-hint>% taken from each payment that goes to a third party as a commission for administering the game
    </mat-hint>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>Stripe Public Key</mat-label>
    <input formControlName="stripePublicKey" placeholder="enter the public api key for your payment processor"
           matInput data-testid="stripe-public-key-input">
    <mat-error data-testid="payment-processor-public-key-error"
      *ngIf="newGamePaymentSettingsForm.controls.stripePublicKey.errors && (newGamePaymentSettingsForm.controls.stripePublicKey.touched || newGamePaymentSettingsForm.controls.stripePublicKey.dirty)">
      required
    </mat-error>
  </mat-form-field>

  <mat-checkbox class="margin-top-large" formControlName="deductTaxesFromCharityContribution">Deduct Taxes From Charity Contribution
  </mat-checkbox>

</form>
