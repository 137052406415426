import {Component, Inject, inject} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {MatError, MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatSelect} from "@angular/material/select";
import {PipesModule} from "../../../pipes/pipes.module";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {NgIf} from "@angular/common";
import {GameService} from "../../../services/game.service";
import {IAddNewPaymentTier} from "../../../interfaces/IAddNewPaymentTier";
import {ActiveUserService} from "../../../services/active-user.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {IApiErrorResponse} from "../../../interfaces/error/IApiErrorResponse";

@Component({
  selector: 'app-new-payment-tier',
  standalone: true,
  imports: [
    MatButton,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatFormField,
    MatHint,
    MatInput,
    MatLabel,
    MatOption,
    MatProgressSpinner,
    MatSelect,
    MatSuffix,
    PipesModule,
    ReactiveFormsModule,
    MatDialogClose,
    MatError,
    NgIf
  ],
  templateUrl: './new-payment-tier.component.html',
  styleUrl: './new-payment-tier.component.css'
})
export class NewPaymentTierComponent {
  private gameService: GameService = inject(GameService);
  private activeUserService: ActiveUserService = inject(ActiveUserService);
  private snackbarService: SnackbarService = inject(SnackbarService);

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;

  constructor(@Inject(MAT_DIALOG_DATA) public gameId: string,
              private matDialogRef: MatDialogRef<NewPaymentTierComponent>) {
  }

  public newPaymentTierForm = new FormGroup({
    price: new FormControl<number | null>(0, Validators.required),
    numberOfTickets: new FormControl<number>(0, [Validators.required, Validators.min(1)])
  });

  public saveNewPaymentTier() {
    if (!this.newPaymentTierForm.valid) {
      this.newPaymentTierForm.markAllAsTouched();
      return;
    }
    this.uiState = UIStateEnum.ShowRequestProcessing;

    const newRequest: IAddNewPaymentTier = {
      gameId: this.gameId,
      adminId: this.activeUserService.activeUser().Id,
      numberOfTickets: this.newPaymentTierForm.controls.numberOfTickets.value!,
      price: this.newPaymentTierForm.controls.price.value! * 100
    }
    this.gameService.addNewPaymentTier(newRequest).subscribe({
      next: () => {
        this.snackbarService.openSuccessfulSnackBar('Successfully added new payment tier');
        this.matDialogRef.close(true);
      },
      error: (res: IApiErrorResponse)=>{
        if (res.Error?.ClientErrorMessages?.length > 0){
          this.uiState = UIStateEnum.ShowData;
          this.snackbarService.openErrorSnackBar(res.Error.ClientErrorMessages.toString());
        } else {
          this.snackbarService.openErrorSnackBar('There was an issue adding your new payment tier');
        }
      }
    })
  }
}
