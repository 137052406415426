<app-dialog-base [showLoadingSpinner]="uiState == uiStateEnumForTemplate.ShowLoading">
  <span
    dialog-title>Charity Settings</span>

  <ng-container dialog-content>
    @if (activeCharity && uiState === uiStateEnumForTemplate.ShowData) {
      <form [formGroup]="updateCharityForm">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Charity Name</mat-label>
          <input data-testid="edit-charity-name-input"
                 formControlName="charityName"
                 type="text"
                 matInput
                 placeholder="enter charity name"
                 required>
          @if (charityNameControl.hasError('required')) {
            <mat-error data-testid="edit-charity-name-error">
              charity name is required
            </mat-error>
          }
        </mat-form-field>
      </form>
    }

  </ng-container>

  <ng-container actions>
    <button
      data-testid="save-update-charity"
      mat-button
      (click)="updateCharity()"
      [disabled]="uiState === uiStateEnumForTemplate.ShowLoading">Confirm
    </button>
  </ng-container>
</app-dialog-base>
