import { Component } from '@angular/core';
import {ActiveUserService} from "../../services/active-user.service";
import {OidcService} from "../../services/oidc.service";
import {MatIcon} from "@angular/material/icon";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {AsyncPipe} from "@angular/common";
import {MatButton} from "@angular/material/button";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-user-profile-menu',
  standalone: true,
  templateUrl: './user-profile-menu.component.html',
  imports: [
    MatIcon,
    MatMenuTrigger,
    MatMenu,
    AsyncPipe,
    MatButton,
    MatMenuItem,
    RouterLink
  ],
  styleUrls: ['./user-profile-menu.component.scss']
})
export class UserProfileMenuComponent {
  public activeUser$ = this.activeUserService.selectActiveUser();

  constructor(private activeUserService: ActiveUserService,
              private oidcService: OidcService) {
  }

  onLogoutClick(): void
  {
    this.oidcService.logout();
  }
}
