import {Component} from '@angular/core';
import {GameInstanceService} from "../../services/game-instance.service";
import {GameService} from "../../services/game.service";
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {Observable} from "rxjs";
import {IActiveGameInstance} from "../../interfaces/IActiveGameInstance";
import {PermissionTypes} from "../../enum/PermissionTypes";
import {EndGameInstanceComponent} from "../../components/dialogs/end-game-instance/end-game-instance.component";
import {MatDialog} from "@angular/material/dialog";
import {AsyncPipe} from "@angular/common";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatMiniFabButton} from "@angular/material/button";
import {MatTooltip} from "@angular/material/tooltip";
import {
  DisplayActiveGameInstanceComponent
} from "../../components/display-active-game-instance/display-active-game-instance.component";
import {MatIcon} from "@angular/material/icon";
import {
  UserHasCausableAdminAccessDirective
} from "../../directives/permissions/user-has-causable-admin-access.directive";
import {EmailBlastComponent} from "../../components/dialogs/email-blast/email-blast.component";
import {HasPermissionDirective} from "../../directives/permissions/has-permission";

@Component({
  selector: 'app-active-game-instances',
  standalone: true,
  templateUrl: './active-game-instances.component.html',
  imports: [
    AsyncPipe,
    MatMenu,
    MatIcon,
    MatMenuItem,
    MatMiniFabButton,
    MatMenuTrigger,
    MatTooltip,
    DisplayActiveGameInstanceComponent,
    UserHasCausableAdminAccessDirective,
    HasPermissionDirective
  ],
  styleUrls: ['./active-game-instances.component.scss']
})
export class ActiveGameInstancesComponent {
  public activeGame: IGameQueryResult | undefined = this.gameService.activeGame();
  public activeGameInstance$: Observable<IActiveGameInstance> = this.gameInstanceService.selectActiveGameInstance();
  protected readonly PermissionTypes = PermissionTypes;

  constructor(private gameInstanceService: GameInstanceService,
              private matDialog: MatDialog,
              private gameService: GameService) {
  }

  protected readonly permissionTypes = PermissionTypes;


  openEndGameInstance() {
    this.matDialog.open(EndGameInstanceComponent, {
      data: this.gameInstanceService.activeGameInstance(),
      disableClose: true
    })
  }

  openEmailBlast() {
    this.matDialog.open(EmailBlastComponent)
  }
}
