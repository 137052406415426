import {Component, inject, OnInit} from '@angular/core';
import {GamePageBase} from "../../shared/game-page-base";
import {ActivatedRoute, Router} from "@angular/router";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {PlayersService} from "../../services/players.service";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {HasPermissionDirective} from "../../directives/permissions/has-permission";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {PermissionTypes} from "../../enum/PermissionTypes";
import {DatePipe} from "@angular/common";
import {
  ColComponent,
  ContainerComponent,
  ListGroupDirective,
  ListGroupItemDirective,
  RowComponent
} from "@coreui/angular";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {PlayerScreenState} from "../../enum/PlayerScreenState";
import {
  ManagePlayerNumbersComponent
} from "../../components/manage-player/manage-player-numbers/manage-player-numbers.component";
import {
  ManagePlayerNotesComponent
} from "../../components/manage-player/manage-player-notes/manage-player-notes.component";
import {IGeneralPlayerInfo} from "../../interfaces/player/IGeneralPlayerInfo";
import {AppConfigService} from "../../services/app-config.service";
import {GameThemeType} from "../../enum/GameThemeType";
import {
  ManagePlayerTransactionComponent
} from "../../components/manage-player/manage-player-transaction/manage-player-transaction.component";
import {
  ManagePlayerPledgesComponent
} from "../../components/manage-player/manage-player-pledges/manage-player-pledges.component";
import {MatIconModule} from "@angular/material/icon";
import {
  ManagePlayerSuspensionsComponent
} from "../../components/manage-player/manage-player-suspensions/manage-player-suspensions.component";
import {IPlayerSuspension} from "../../interfaces/player/IPlayerSuspension";
import {concatMap, of} from "rxjs";
import {DialogFunctionService} from "../../services/dialog-function.service";
import {IDialogGenericData} from "../../interfaces/IDialogGenericData";
import {MatDialog} from "@angular/material/dialog";
import {
  ConfirmationActionDialogComponent
} from "../../components/dialogs/confirmation-action-dialog/confirmation-action-dialog.component";
import {
  UpdateBasicPlayerInfoComponent
} from "../../components/dialogs/update-basic-player-info/update-basic-player-info.component";
import {PermissionsService} from "../../services/permissions.service";
import {
  ManagePlayerSendGridInfoComponent
} from "../../components/manage-player/manage-player-send-grid-info/manage-player-send-grid-info.component";
import {ActiveUserService} from "../../services/active-user.service";

@Component({
  selector: 'app-manage-player',
  standalone: true,
  imports: [
    ContainerComponent,
    RowComponent,
    ColComponent,
    MatButtonModule,
    HasPermissionDirective,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    ListGroupDirective,
    ListGroupItemDirective,
    DatePipe,
    ManagePlayerNumbersComponent,
    ManagePlayerNotesComponent,
    ManagePlayerPledgesComponent,
    ManagePlayerTransactionComponent,
    MatIconModule,
    ManagePlayerSuspensionsComponent,
    ManagePlayerSendGridInfoComponent
  ],
  templateUrl: './manage-player.component.html',
  styleUrl: './manage-player.component.scss'
})
export class ManagePlayerComponent extends GamePageBase implements OnInit {
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private playerService: PlayersService = inject(PlayersService);
  private activeUserService: ActiveUserService = inject(ActiveUserService);
  private appConfigService: AppConfigService = inject(AppConfigService);
  private permissionService: PermissionsService = inject(PermissionsService);
  private errorHandlingService: ErrorHandlingService = inject(ErrorHandlingService);
  private matDialog: MatDialog = inject(MatDialog);
  private dialogFunctionService: DialogFunctionService = inject(DialogFunctionService);
  private router: Router = inject(Router);
  protected permissionTypes = PermissionTypes;
  protected readonly GameThemeType = GameThemeType;
  protected readonly PermissionTypes = PermissionTypes;

  public chosenGame: IGameQueryResult | null = null;

  public currentScreenState: PlayerScreenState = PlayerScreenState.GeneralInfo;
  public screenStateEnum = PlayerScreenState;

  public playerInfo: IGeneralPlayerInfo | null = null;
  public playerSuspensions: IPlayerSuspension[] = [];
  public playerIsSuspendedInCurrentGame = false;
  public gameLogo = '';
  public allowUserToChangeGame = false;
  public gameIdFromRoute = '';

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((paramMapP) => {
      let playerId = paramMapP.get('playerId');
      let gameId = paramMapP.get('gameId');
      if (gameId && this.gameService.activeGame()?.Id == gameId) {
        this.gameIdFromRoute = gameId;
        this.chosenGame = this.gameService.activeGame();
      }

      if (playerId) {
        this.getAllPlayerInfo(playerId);
      }
    });
  }

  setupFetchSuspensions() {
    // if subject gets value pushed, go get suspensions. This will happen on game change, or if a user adds / resolves a suspension
    this.playerService.fetchUpdatedSuspensionsSubject
      .pipe(concatMap((shouldFetchSuspensions) => {
          if (shouldFetchSuspensions && this.chosenGame) {
            return this.playerService.getPlayerSuspensionsForGame(this.playerInfo?.Id!, this.chosenGame.Id)
          }
          return of(null);
        }
      ))
      .subscribe({
        next: (suspensionsP) => {
          if (suspensionsP) {
            this.playerSuspensions = suspensionsP;
            this.playerIsSuspendedInCurrentGame = this.playerSuspensions.some((suspensionP) => suspensionP.GameId == this.chosenGame?.Id && !suspensionP.Resolved)
          }

        }, error: (err) => {
          this.errorHandlingService.displayPageLevelErrorMessage(err);
        }
      })
  }

  public onUpdateBasicInfoClick() {
    const dialog_ref = this.matDialog.open(UpdateBasicPlayerInfoComponent, {
      data: this.playerInfo,
      width: '650px'
    });
    dialog_ref.afterClosed().subscribe({
      next: (res) => {
        if (res) {
          this.getAllPlayerInfo(this.playerInfo?.Id!);
        }
      }
    })
  }


  onGameChange(chosenGame: IGameQueryResult) {
    this.gameLogo = `${this.appConfigService.cdnRoot()}/assets/${chosenGame.Subdomain}/game-logo.svg`;
    this.playerService.fetchUpdatedSuspensionsSubject.next(true);
  }

  chooseNewPlayerClick() {
    if (this.gameIdFromRoute) {
      this.router.navigateByUrl(`/${this.gameIdFromRoute}/player-search`);
    } else {
      this.router.navigateByUrl(`player-search`);
    }
  }

  getAllPlayerInfo(playerId: string) {
    this.uiState = UIStateEnum.ShowLoading;
    this.playerService.getGeneralPlayerData(playerId, this.activeUserService.activeUser().Id)
      .subscribe({
        next: (res) => {
          this.playerInfo = res;

          if (this.playerInfo.PlayerGames.length > 1) {
            this.allowUserToChangeGame = true;
          } else if (this.playerInfo.PlayerGames.length == 1) {
            this.chosenGame = this.playerInfo.PlayerGames[0];
          }

          if (this.permissionService.userHasPermission(PermissionTypes.ViewPlayerSuspensions)) {
            this.setupFetchSuspensions();
          }
          this.uiState = UIStateEnum.ShowData;
        },
        error: (err) => {
          this.uiState = UIStateEnum.ShowData;
          this.errorHandlingService.displayPageLevelErrorMessage(err);
        }
      })
  }

  public onDeactivatePlayerClick() {
    this.dialogFunctionService.setCallback(() => {
      return this.playerService.deactivatePlayer({
        playerId: this.playerInfo?.Id!,
        gameId: this.chosenGame?.Id
      })
    });

    if (this.playerInfo) {
      let deactivatePlayerDialogData: IDialogGenericData = {
        title: 'Confirm Player Deactivation',
        message: `Please confirm that you would like to deactivate the following player`,
        playerData: {
          firstName: this.playerInfo.FirstName,
          email: this.playerInfo.Email,
          lastName: this.playerInfo.LastName
        },
        dataCyTag: 'confirm-deactivate-player-button',
        successMessage: 'Successfully deactivated player',
        extraDetails: 'Deactivating a player will remove all of their numbers from all games, except any numbers already played for for any active games. The user will no longer have access to any games.'
      };

      const dialogRef = this.matDialog.open(ConfirmationActionDialogComponent, {
        data: deactivatePlayerDialogData,
        width: '650px'
      });

      dialogRef.afterClosed().subscribe(
        {
          next: (updateSuccessful: boolean) => {
            if (updateSuccessful) {
              this.getAllPlayerInfo(this.playerInfo?.Id!);
            }
          }
        })
    }
  }

  public onReactivatePlayerClick() {
    this.dialogFunctionService.setCallback(() => {
      return this.playerService.reactivatePlayer({
        playerId: this.playerInfo?.Id!,
        gameId: this.gameService.activeGame().Id
      })
    });

    if (this.playerInfo) {
      let reactivatePlayerDialogData: IDialogGenericData = {
        title: 'Confirm Player Reactivation',
        message: `Please confirm that you would like to reactivate the following player`,
        playerData: {
          firstName: this.playerInfo.FirstName,
          email: this.playerInfo.Email,
          lastName: this.playerInfo.LastName
        },
        dataCyTag: 'confirm-reactivate-player-button',
        successMessage: 'Successfully reactivated player',
        extraDetails: 'Reactivating a player allows them to log back into any game, while their numbers remain deactivated. You can selectively reactivate numbers per game or strip using the "Reactivate Strip" feature.'
      };

      const dialogRef = this.matDialog.open(ConfirmationActionDialogComponent, {
        data: reactivatePlayerDialogData,
        width: '650px'
      });

      dialogRef.afterClosed().subscribe(
        {
          next: (updateSuccessful: boolean) => {
            if (updateSuccessful) {
              this.getAllPlayerInfo(this.playerInfo?.Id!);
            }
          }
        })
    }
  }

}
