<app-dialog-base [showLoadingSpinner]="uiState === uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Add New Testimonial</span>

  @if (uiState === uiStateEnumForTemplate.ShowData) {
    <ng-container dialog-content>
      <form cForm [formGroup]="testimonialForm" novalidate>
        <div class="mb-3 position-relative">
          <label cLabel for="userName">Name of testimonial provider</label>
          <input cFormControl
                 [valid]="userNameControl.touched && userNameControl.valid ? true : userNameControl.touched && userNameControl.invalid ? false : undefined"
                 formControlName="userName"
                 data-testid="add-testimonial-name"
                 id="userName"
                 placeholder="enter name of user: Ex John Doe"
          />

          @if (userNameControl.invalid && userNameControl.touched) {
            <c-form-feedback [valid]="false" tooltip>
              <div data-testid="add-testimonial-name-error">users name is required</div>
            </c-form-feedback>
          }
        </div>
        <div class="mb-3 position-relative">
          <label cLabel for="userName">Title of testimonial provider</label>
          <input cFormControl
                 [valid]="userPositionControl.touched && userPositionControl.valid ? true : userPositionControl.touched && userPositionControl.invalid ? false : undefined"
                 formControlName="userPosition"
                 id="userPosition"
                 data-testid="add-testimonial-position"
                 placeholder="enter position of user: Ex: Vice President, Causable High School"
          />
          @if (userPositionControl.invalid && userPositionControl.touched) {
            <c-form-feedback [valid]="false" tooltip>
              <div data-testid="add-testimonial-position-error">users position is required</div>
            </c-form-feedback>
          }
        </div>
        <div class="mb-3 position-relative">
          <label cLabel for="testimonialContent">Testimonial</label>
          <textarea cFormControl
                    [valid]="testimonialContentControl.touched && testimonialContentControl.valid ? true : testimonialContentControl.touched && testimonialContentControl.invalid ? false: undefined"
                    formControlName="content"
                    data-testid="add-testimonial-testimonial"
                    placeholder="enter your testimonial here"
                    id="testimonialContent"
                    rows="10"></textarea>
          @if (testimonialContentControl.invalid && testimonialContentControl.touched && testimonialContentControl.errors) {
            <c-form-feedback [valid]="false" tooltip>
              <div>
                @if (testimonialContentControl.errors['required']) {
                  <div data-testid="add-testimonial-required-error">testimonial is required</div>
                } @else if (testimonialContentControl.errors['minlength']) {
                  <div data-testid="add-testimonial-min-error">must contain at least 10 characters</div>
                } @else if (testimonialContentControl.errors['maxlength']) {
                  <div data-testid="add-testimonial-max-error">cannot exceed 1100 characters</div>
                }
              </div>

            </c-form-feedback>
          }
        </div>
        <div class="mb-3 position-relative">
          <c-form-check [switch]="true" [sizing]="'xl'">
            <input cFormCheckInput type="checkbox" formControlName="active" data-testid="add-testimonial-active-toggle"/>
            <label cFormCheckLabel>Should this testimonial be active?</label>
          </c-form-check>
        </div>

        @if(!activeControl.value) {
          <div class="error-message-container">
            <div class="error-message" data-testid="add-testimonial-inactive-message">**Note: This testimonial is not active. It will not appear in the web app until "Should this testimonial
              to active?" is toggled to the on position.
            </div>
          </div>
        }

      </form>
    </ng-container>
  }

  <ng-container actions>
    <button mat-button
            (click)="addLicenceNumber()"
            data-testid="finalize-add-testimonial-button"
            [disabled]="uiState === uiStateEnumForTemplate.ShowLoading">
      Add
    </button>
  </ng-container>
</app-dialog-base>
