import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {OidcService} from '../services/oidc.service';
import {ActiveUserService} from "../services/active-user.service";
import {map} from "rxjs";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UserIsAdminGuard implements CanActivate {
  constructor(private oidcService: OidcService,
              private activeUserService: ActiveUserService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const is_authenticated = this.oidcService.isAuthenticated();
    if (!is_authenticated) {
      return this.router.navigateByUrl('auth/login');
    }

    const adminId = this.oidcService.getAdminIdFromClaims();
    if (adminId) {
      return this.activeUserService.retrieveActiveUser(adminId)
        .pipe(map((adminP) => {
            return adminP !== null;
          }),
          catchError(() => {
            return this.router.navigateByUrl('not-admin');
          }))
    }

    return this.router.navigateByUrl('not-admin');
  }
}
