import {Component, Inject} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {CharityService} from "../../../services/charity.service";
import {ICreateCharityCategoryCommand} from "../../../interfaces/charity/ICreateCharityCategoryCommand";
import {SnackbarService} from "../../../services/snackbar.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ICharityCategory} from "../../../interfaces/charity/ICharityCategory";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatButton} from "@angular/material/button";
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";

@Component({
  selector: 'app-add-new-category',
  templateUrl: './add-new-category.component.html',
  standalone: true,
  imports: [
    MatLabel,
    MatError,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatButton,
    DialogBaseComponent
  ],
  styleUrls: ['./add-new-category.component.css']
})
export class AddNewCategoryComponent {

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public categoryNameControl: FormControl<string | null> = new FormControl<string | null>('', Validators.required);
  public createCharityCategoryForm = new FormGroup({
    categoryName: this.categoryNameControl
  });
  public charityCategories: ICharityCategory[] = [];

  constructor(private charityService: CharityService,
              private snackBarService: SnackbarService,
              private errorHandlingService: ErrorHandlingService,
              @Inject(MAT_DIALOG_DATA) public gameId: string,
              private matDialogRef: MatDialogRef<AddNewCategoryComponent>) {
  }

  submitNewCharity() {
    if (this.createCharityCategoryForm.valid) {
      this.uiState = UIStateEnum.ShowLoading;
      let newCategoryCommand: ICreateCharityCategoryCommand = {
        charityCategoryName: this.categoryNameControl.value!,
        gameId: this.gameId,
        active: true,
        sortOrder: this.charityCategories.length + 1
      };

      this.charityService.createNewCharityCategory(newCategoryCommand).subscribe({
        next: (res) => {
          this.uiState = UIStateEnum.ShowData;
          this.matDialogRef.close(res.NewCategoryId);
          this.snackBarService.openSuccessfulSnackBar('Successfully Add New Charity Category');
        },
        error: (err) => {
          this.uiState = UIStateEnum.ShowData;
          this.errorHandlingService.displayDialogLevelErrorMessage(err, true);
        }
      })
    }

  }

}
