import {Component} from '@angular/core';
import {OidcService} from "../../services/oidc.service";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {Observable} from "rxjs";
import {MatCardModule} from "@angular/material/card";
import {AsyncPipe} from "@angular/common";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-not-authorized',
  standalone: true,
  templateUrl: './not-authorized.component.html',
  imports: [
    MatCardModule,
    AsyncPipe,
    MatButton
  ],
  styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent {

  public unauthorizedErrorMessage$: Observable<string> = this.errorHandlingService.unauthorizedErrorMessage$;

  constructor(private oidcService: OidcService,
              private errorHandlingService: ErrorHandlingService) {
  }

  onLogoutClick(): void {
    this.oidcService.logout();
  }
}
