import {Component} from '@angular/core';
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatTooltip} from "@angular/material/tooltip";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {MatDialog} from "@angular/material/dialog";
import {ICellRendererParams} from "ag-grid-community";
import {IFrequentlyAskedQuestions} from "../../../interfaces/IFrequentlyAskedQuestions";
import {FaqsEditComponent} from "../faqs-edit/faqs-edit.component";

@Component({
  selector: 'app-faq-table-settings',
  standalone: true,
  imports: [
    HasPermissionDirective,
    MatIcon,
    MatIconButton,
    MatTooltip
  ],
  templateUrl: './faq-table-setting-components.component.html',
  styleUrl: './faq-table-setting-components.component.css'
})
export class FaqTableSettingComponents implements ICellRendererAngularComp {
  public permissionTypes = PermissionTypes;
  public rowData!: IFrequentlyAskedQuestions;
  public params!: any;

  constructor(private matDialog: MatDialog) {
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.rowData = params.data;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  onEditFaqClick() {
    this.matDialog.open(FaqsEditComponent, {
      data: this.rowData,
      width: '650px'
    });
  }
}
