import {Component} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {CharityService} from "../../../services/charity.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {ICharityCategory} from "../../../interfaces/charity/ICharityCategory";
import {IUpdateCharityCategoryNameCommand} from "../../../interfaces/charity/IUpdateCharityCategoryNameCommand";
import {ICharitiesByCategoryResult} from "../../../interfaces/charity/ICharitiesByCategoryResult";
import {Observable} from "rxjs";
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-charity-category-change-name',
  standalone: true,
  templateUrl: './charity-category-change-name.component.html',
  imports: [
    DialogBaseComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInput,
    MatButton
  ],
  styleUrls: ['./charity-category-change-name.component.css']
})
export class CharityCategoryChangeNameComponent {

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public activeCategory: ICharityCategory | null = null;
  public categoryNameControl: FormControl<string | null> = new FormControl<string | null>('', Validators.required);
  public updateCharityCategoryForm = new FormGroup({
    categoryName: this.categoryNameControl
  });

  private activeCharityCategory$: Observable<ICharitiesByCategoryResult | undefined> = this.charityService.selectActiveCategory();

  constructor(private charityService: CharityService,
              private errorHandlingService: ErrorHandlingService,
              private matDialogRef: MatDialogRef<CharityCategoryChangeNameComponent>,
              private snackBarService: SnackbarService) {
    this.setFormData();
  }

  setFormData(): void {
    this.activeCharityCategory$.subscribe({
      next: (charityCategoryP) => {
        if (charityCategoryP) {
          this.activeCategory = charityCategoryP.Category;
          this.categoryNameControl.setValue(charityCategoryP.Category.Name);
        }
      }
    })
  }

  updateCharityCategory() {
    if (this.updateCharityCategoryForm.valid) {
      this.uiState = UIStateEnum.ShowLoading;
      let categoryUpdate: IUpdateCharityCategoryNameCommand = {
        categoryId: this.activeCategory?.Id!,
        gameId: this.activeCategory?.GameId!,
        name: this.categoryNameControl.value!
      };

      this.charityService.updateCharityCategoryName(categoryUpdate).subscribe({
        next: () => {
          this.snackBarService.openSuccessfulSnackBar('Successfully updated charity category');
          this.matDialogRef.close(true);
        },
        error: (err) => {
          this.uiState = UIStateEnum.ShowData;
          this.errorHandlingService.displayDialogLevelErrorMessage(err, true);
        }
      })
    }
  }

}
