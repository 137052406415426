import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IGameInstancePlayerNumberWithPayment} from "../../../interfaces/IGameInstancePlayerNumberWithPayment";
import {Router} from "@angular/router";
import {GameService} from "../../../services/game.service";
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {MatIcon} from "@angular/material/icon";
import {CdkCopyToClipboard} from "@angular/cdk/clipboard";
import {MatTooltip} from "@angular/material/tooltip";
import {DatePipe} from "@angular/common";
import {GameTypeEnum} from "../../../enum/GameTypeEnum";

@Component({
  selector: 'app-entity-view-renderer-dialog',
  standalone: true,
  templateUrl: './view-player-number-dialog.component.html',
  imports: [
    DialogBaseComponent,
    MatIcon,
    CdkCopyToClipboard,
    MatTooltip,
    DatePipe
  ],
  styleUrls: ['./view-player-number-dialog.component.css']
})
export class ViewPlayerNumberDialogComponent {

  protected gameType: GameTypeEnum = this.gameService.activeGame().Type;
  protected gameTypes = GameTypeEnum;

  constructor(@Inject(MAT_DIALOG_DATA) public gameData: IGameInstancePlayerNumberWithPayment,
              private router: Router,
              private matDialogRef: MatDialogRef<ViewPlayerNumberDialogComponent>,
              private gameService: GameService) {
  }

  openPlayer(playerIdP: string) {
    let activeGame = this.gameService.activeGame().Id;
    this.router.navigateByUrl(`${activeGame}/manage-player/${playerIdP}`).then(() => {
      this.matDialogRef.close();
    });
  }
}
