import {Component, Inject} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {SnackbarService} from "../../../services/snackbar.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogFunctionService} from "../../../services/dialog-function.service";
import {IDialogGenericData} from "../../../interfaces/IDialogGenericData";
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-confirmation-action-dialog',
  standalone: true,
  templateUrl: './confirmation-action-dialog.component.html',
  imports: [
    DialogBaseComponent,
    MatButton
  ],
  styleUrls: ['./confirmation-action-dialog.component.css']
})
export class ConfirmationActionDialogComponent {

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateForTemplate = UIStateEnum;

  constructor(private snackBarService: SnackbarService,
              private errorHandlingService: ErrorHandlingService,
              private dialogFunctionService: DialogFunctionService,
              private matDialogRef: MatDialogRef<ConfirmationActionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public genericData: IDialogGenericData) {
  }

  onConfirmationClick() {
    this.uiState = UIStateEnum.ShowLoading;
    // Get the method from the service and call it
    const callback = this.dialogFunctionService.getCallback();
    if (callback) {
      callback().subscribe({
        next: () => {
          this.uiState = UIStateEnum.ShowData;
          this.snackBarService.openSuccessfulSnackBar(this.genericData.successMessage);
          this.matDialogRef.close(true);
        },
        error: (err: any) => {
          this.uiState = UIStateEnum.ShowData;
          this.errorHandlingService.displayDialogLevelErrorMessage(err, true);
        }
      });
    }
  }
}
