import {Component} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {AutoplayStatusTypes} from "../../../enum/AutoplayStatusTypes";
import {PipesModule} from "../../../pipes/pipes.module";

@Component({
  selector: 'app-subscription-status-renderer',
  standalone: true,
  templateUrl: './subscription-status-renderer.component.html',
  imports: [
    PipesModule
  ],
  styleUrls: ['./subscription-status-renderer.component.css']
})
export class SubscriptionStatusRendererComponent implements ICellRendererAngularComp {

  public subValue!: AutoplayStatusTypes;

  constructor() {
  }

  agInit(params: ICellRendererParams): void {
    this.subValue = params.value;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }
}
