<app-dialog-base [showLoadingSpinner]="uiState == uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Add Charity Category</span>
  <ng-container dialog-content>
    <form [formGroup]="createCharityCategoryForm">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Enter New Category Name</mat-label>
        <input
          data-testid="charity-category-input"
          formControlName="categoryName"
          type="text"
          matInput
          placeholder="enter category name">
        @if(categoryNameControl.hasError('required')) {
          <mat-error data-testid="charity-category-error">
            category name is <span class="bold-text">required</span>
          </mat-error>
        }
      </mat-form-field>
    </form>
  </ng-container>
  <ng-container actions>
    <button mat-button (click)="submitNewCharity()" [disabled]="uiState == uiStateEnumForTemplate.ShowLoading"
            data-testid="add-charity-category-button">
      Add Category
    </button>
  </ng-container>
</app-dialog-base>
