<app-dialog-base [showLoadingSpinner]="uiState == uiStateEnumForTemplate.ShowLoading">
  <span
    dialog-title>Update Game Instance End Date</span>

  @if (activeGameInstance && uiState === uiStateEnumForTemplate.ShowData) {

    <ng-container dialog-content>
      <div>Current End Date</div>
      <div>{{ activeGameInstance.EndedOn | date :'MMM d, y hh:mm a' }}</div>

      <input
        class="margin-top-med"
        type="datetime-local"
        id="updated-end-date"
        data-testid="date-input"
        [(ngModel)]="formattedDateTimeString"
        [value]="formattedDateTimeString"
        name="end date"/>
    </ng-container>
  }

  <ng-container actions>
    <button
      data-testid="save-update-game-instance"
      mat-button
      (click)="updateEndDate()"
      [disabled]="uiState === uiStateEnumForTemplate.ShowLoading">Confirm
    </button>
  </ng-container>
</app-dialog-base>
