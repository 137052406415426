<mat-card class="margin-bottom-med">
  <mat-card-header>
    <mat-card-title>Player Notes</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <button mat-stroked-button
            *hasPermission="PermissionTypes.AddPlayerNote"
            data-testid="add-note-to-player-button"
            class="green-text margin-top-med full-width margin-bottom-med"
            (click)="addPlayerNote()">
      <mat-icon>note_add</mat-icon>
      Add Note to Player
    </button>
    <ag-grid-angular
      id="playerNotesGrid"
      class="ag-theme-quartz margin-top-med large-table"
      data-testid="player-notes-grid"
      [tooltipShowDelay]=0
      rowClass="table-row"
      domLayout='autoHeight'
      overlayNoRowsTemplate="<span>No notes for this player</span>"
      (firstDataRendered)="onFirstDataRendered($event)"
      (gridSizeChanged)="gridSizeChanged($event)"
      (rowClicked)="onPlayerNotesRowClicked($event)"
      [loading]="uiState == uiStateEnum.ShowLoading"
      [rowData]="playerNotes"
      [pagination]="true"
      [columnDefs]="playerNotesColumnDefs">
    </ag-grid-angular>
  </mat-card-content>
</mat-card>
