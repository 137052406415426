import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {createStore, select, setProp, withProps} from '@ngneat/elf';
import {IAppConfig} from "../interfaces/IAppConfig";
import {createRequestsCacheOperator, updateRequestCache, withRequestsCache} from "@ngneat/elf-requests";

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private defaultConfig: IAppConfig = {
    authConfig: {
      issuer: '',
      showDebugInformation: false
    },
    cdnRoot: '',
    captchaPublicKey: '',
    commonUrl: '',
    currentEnvironment: '',
    raygunApiKey: '',
    raygunVersionNumber: ''
  };
  private appConfigStore = createStore(
    {name: 'app-config'},
    withProps<{ appConfig: IAppConfig }>({
      appConfig: this.defaultConfig
    }),
    withRequestsCache<'app-config-cache'>()
  );

  private skipWhileOrgConfigCached = createRequestsCacheOperator(this.appConfigStore);
  public appConfig$: Observable<IAppConfig> = this.appConfigStore.pipe(select((state) => state.appConfig));

  constructor(private http: HttpClient) {
  }

  public recaptchaSiteKey(): string {
    return this.appConfigStore.getValue().appConfig.captchaPublicKey
  }

  public commonApiUrl(): string {
    return this.appConfigStore.getValue().appConfig.commonUrl;
  }

  public cdnRoot(): string {
    return this.appConfigStore.getValue().appConfig.cdnRoot;
  }

  public appConfigFromApiOrCache(): Observable<IAppConfig> {
    const api_url = `api/config`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };

    return this.http.request<IAppConfig>('get', api_url, options).pipe(
      tap(config => this.appConfigStore.update(
        updateRequestCache('app-config-cache', {value: 'full'}),
        setProp('appConfig', config))),
      this.skipWhileOrgConfigCached('app-config-cache'));
  }

}
