import {Component, OnInit} from '@angular/core';
import {GameService} from "../../services/game.service";
import {ActiveUserService} from "../../services/active-user.service";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {SnackbarService} from "../../services/snackbar.service";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NgIf} from "@angular/common";
import {MarkdownModule} from "ngx-markdown";
import {MatButtonModule} from "@angular/material/button";
import {HowToPlayService} from "../../services/how-to-play.service";
import {IEditGameHowToPlayText} from "../../interfaces/IEditGameHowToPlayText";
import {Router} from "@angular/router";
import {UIStateEnum} from "../../enum/UIStateEnum";

@Component({
  selector: 'app-how-to-play-editor',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    MarkdownModule,
    MatButtonModule
  ],
  templateUrl: './how-to-play-editor.component.html',
  styleUrl: './how-to-play-editor.component.scss'
})
export class HowToPlayEditorComponent implements OnInit {

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;

  public activeGameId = this.gameService.activeGame().Id;
  public howToPlayId?: string = "";
  public howToPlayControl: FormControl<string | null> = new FormControl<string | null>("", Validators.required);

  public howToPlayForm = new FormGroup({
    howToPlay: this.howToPlayControl
  });

  constructor(private gameService: GameService,
              private activeUserService: ActiveUserService,
              private errorHandlingService: ErrorHandlingService,
              private snackBarService: SnackbarService,
              private howToPlayService: HowToPlayService,
              private router: Router) {
  }

  ngOnInit() {
    this.fetchCurrentHowToPlayText();
  }

  fetchCurrentHowToPlayText() {
    this.howToPlayService.getGameHowToPlayText(this.activeGameId).subscribe(res => {
      this.howToPlayControl.setValue(res.HowToPlayText);
      this.howToPlayId = res.Id;
    });
  }

  submit() {
    if (!this.howToPlayForm.valid) {
      this.howToPlayForm.markAllAsTouched();
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;

    let how_to_play: IEditGameHowToPlayText = {
      AdminId: this.activeUserService.activeUser().Id,
      GameId: this.activeGameId,
      HowToPlayText: this.howToPlayControl.value!
    };

    if (this.howToPlayId) {
      this.howToPlayService.updateGameHowToPlayText(how_to_play).subscribe({
        next: () => {
          this.snackBarService.openSuccessfulSnackBar("Successfully edited Game's How To Play Text!");
          this.router.navigateByUrl(`${this.activeGameId}/manage-game`);
        },
        error: err => {
          this.uiState = UIStateEnum.ShowData;
          this.errorHandlingService.displayPageLevelErrorMessage(err);
        }
      });
    } else {
      this.howToPlayService.addGameHowToPlayText(how_to_play).subscribe({
        next: () => {
          this.snackBarService.openSuccessfulSnackBar("Successfully Added How To Play Text!");
          this.router.navigateByUrl(`${this.activeGameId}/manage-game`);
        },
        error: err => {
          this.uiState = UIStateEnum.ShowData;
          this.errorHandlingService.displayPageLevelErrorMessage(err);
        }
      });
    }
  }

  formatMarkdown(textP: string) {
    if (textP == undefined) {
      return "";
    }
    return textP.replace(/\n/, "<br>");
  }
}
