import {Injectable} from "@angular/core";
import {SnackBarComponent} from "../shared/snack-bar/snack-bar.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ErrorSnackbarComponent} from "../shared/error-snackbar/error-snackbar.component";

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {
  }

  openSuccessfulSnackBar(message: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: message,
      duration: 3000,
      panelClass: 'success-snackbar'
    });
  }

  public openErrorSnackBar(messageP: string) {
    this.snackBar.openFromComponent(ErrorSnackbarComponent, {
      data: messageP,
      panelClass: 'failure-snackbar'
    });
  }
}
