import {Component, Inject} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {
  IPlayerNumberWithCheckedForUpdatingStatus
} from "../../../interfaces/player/IPlayerNumberWithCheckedForUpdatingStatus";
import {SnackbarService} from "../../../services/snackbar.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {PlayersService} from "../../../services/players.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IPlayerNumberQueryResult} from "../../../interfaces/player/IPlayerNumberQueryResult";
import {IReactivatePlayerNumbers} from "../../../interfaces/player/IReactivatePlayerNumbers";
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatButton} from "@angular/material/button";
import {FormsModule} from "@angular/forms";
import {SelectionModel} from "@angular/cdk/collections";
import {IGoldRushStrip} from "../../../interfaces/IGoldRushStrip";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable,
  MatTableDataSource
} from "@angular/material/table";
import {GameTypeEnum} from "../../../enum/GameTypeEnum";
import {GameService} from "../../../services/game.service";
import {IDeactivatePlayerNumbers} from "../../../interfaces/player/IDeactivatePlayerNumbers";

@Component({
  selector: 'app-activate-player-numbers',
  standalone: true,
  templateUrl: './activate-player-numbers.component.html',
  imports: [
    DialogBaseComponent,
    MatCheckbox,
    MatButton,
    FormsModule,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    MatHeaderCellDef
  ],
  styleUrls: ['./activate-player-numbers.component.css']
})
export class ActivatePlayerNumbersComponent {
  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public deactivatedNumbers: IPlayerNumberWithCheckedForUpdatingStatus[] = [];
  public gameType: GameTypeEnum = this.gameService.activeGame().Type;
  public gameTypes = GameTypeEnum;
  selectedStripsForActivation = new SelectionModel<IGoldRushStrip>(true, []);
  deactivatedStripsDataSource = new MatTableDataSource<IGoldRushStrip>();
  displayedColumns: string[] = ['Select', 'GoldRushStripNumber', 'Numbers'];

  constructor(private snackBarService: SnackbarService,
              private errorHandlingService: ErrorHandlingService,
              private playersService: PlayersService,
              private gameService: GameService,
              private matDialogRef: MatDialogRef<ActivatePlayerNumbersComponent>,
              @Inject(MAT_DIALOG_DATA) public deactivateRequestData: {
                PlayerId: string;
                GameId: string;
                AllPlayerTickets: IPlayerNumberQueryResult[]
              }) {
    this.deactivatedNumbers = this.deactivateRequestData.AllPlayerTickets.filter((numberP) => !numberP.Active);

    if (this.gameType === GameTypeEnum.GoldRush) {
      this.deactivatedStripsDataSource.data = this.organizeIntoStrips();
    }
  }

  getNumbersString(numbersP: IPlayerNumberQueryResult[]): string {
    return numbersP.map((number) => number.TicketNumber).toString();
  }

  organizeIntoStrips(): IGoldRushStrip[] {
    return this.deactivatedNumbers.reduce((acc: IGoldRushStrip[], currentValue) => {
      const existingGroup = acc.find(group => group.GoldRushStripNumber === currentValue.GoldRushStripNumber);
      if (existingGroup) {
        existingGroup.Numbers.push(currentValue);
      } else {
        acc.push({GoldRushStripNumber: currentValue.GoldRushStripNumber, Numbers: [currentValue]});
      }
      return acc;
    }, []);
  }

  isAllSelected() {
    const numSelected = this.selectedStripsForActivation.selected.length;
    const numRows = this.deactivatedStripsDataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selectedStripsForActivation.clear();
      return;
    }

    this.selectedStripsForActivation.select(...this.deactivatedStripsDataSource.data);
  }


  activateNumbers() {

    let reactivationRequest: IDeactivatePlayerNumbers | null;

    if (this.gameType == GameTypeEnum.GoldRush) {
      reactivationRequest = this.buildReactivateGoldRushStripsRequest();
    } else {
      reactivationRequest = this.buildReactivate5050NumbersRequest();
    }


    if(reactivationRequest) {
      this.uiState = UIStateEnum.ShowLoading;
      this.playersService.activatePlayerNumbers(reactivationRequest).subscribe({
        next: () => {
          this.snackBarService.openSuccessfulSnackBar('You have successfully activated players numbers');
          this.matDialogRef.close(true);
        },
        error: (err) => {
          this.uiState = UIStateEnum.ShowData;
          this.errorHandlingService.displayDialogLevelErrorMessage(err, true);
        }
      })
    }
  }

  buildReactivate5050NumbersRequest(): IReactivatePlayerNumbers | null {
    const checkedNumbers = this.deactivatedNumbers
      .filter(numberP => numberP.Checked)
      .map(checkedNumberP => checkedNumberP.Id);

    if (checkedNumbers.length === 0) {
      this.snackBarService.openErrorSnackBar('you must select at least one number to activate')
      return null;
    }

    return {
      PlayerNumberIds: checkedNumbers,
      PlayerId: this.deactivateRequestData.PlayerId,
      GameId: this.deactivateRequestData.GameId
    }
  }

  buildReactivateGoldRushStripsRequest(): IReactivatePlayerNumbers | null {
    const numberIds = this.selectedStripsForActivation.selected.flatMap((strip) => strip.Numbers.map((number) => number.Id))

    if (numberIds.length > 0) {
      return {
        PlayerNumberIds: numberIds,
        PlayerId: this.deactivateRequestData.PlayerId,
        GameId: this.deactivateRequestData.GameId
      }
    }

    this.snackBarService.openErrorSnackBar('you must select at least one strip to activate')
    return null;
  }
}
