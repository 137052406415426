import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-component-level-error-message',
  standalone: true,
  templateUrl: './component-level-error-message.component.html',
  styleUrls: ['../base-display-error/base-display-error-message.component.scss']
})
export class ComponentLevelErrorMessageComponent {


  @Input()
  public errorMessage: string = '';

  constructor() {
  }


}
