import {Pipe, PipeTransform} from '@angular/core';
import {WinnerPayoutStatusTypes} from "../enum/WinnerPayoutStatusTypes";

@Pipe({
  name: 'winnerPayoutStatus'
})
export class WinnerPayoutStatusPipe implements PipeTransform {
  public transform(claimStatusP: WinnerPayoutStatusTypes): string {
    if (claimStatusP) {
      switch (claimStatusP) {
        case WinnerPayoutStatusTypes.Paid:
          return 'Paid';
        case WinnerPayoutStatusTypes.NotPaid:
          return 'Not Paid';
        case WinnerPayoutStatusTypes.Forfeited:
          return 'Forfeited';
        case WinnerPayoutStatusTypes.Expired:
          return 'Expired';
      }
    }
    return 'Unknown Interval Type';
  }
}
