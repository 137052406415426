import { Injectable } from '@angular/core';
import {APIService} from "./api.service";
import {IProfanityListRequestResult} from "../interfaces/IProfanityListRequestResult";
import {IUpdateBlackList} from "../interfaces/IUpdateBlackList";

@Injectable({
  providedIn: 'root'
})
export class ProfanityService {

  constructor(private apiService: APIService)
  { }

  public getBlackList() {
    return this.apiService.MakeGetRequest<IProfanityListRequestResult>("profanity/black-list");
  }

  public updateBlackList(commandP: IUpdateBlackList) {
    return this.apiService.MakePutRequest("profanity/update-black-list", commandP);
  }
}
