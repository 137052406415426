import {Component} from '@angular/core';
import {UIStateEnum} from "../../enum/UIStateEnum";
import {CharityService} from "../../services/charity.service";
import {GameService} from "../../services/game.service";
import {Observable} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {ChangeCharityNameComponent} from "../dialogs/change-charity-name/change-charity-name.component";
import {CharityScreens} from "../../enum/CharityScreens";
import {ICharityWithContributionTotals} from "../../interfaces/charity/ICharityWithContributionTotals";
import {PermissionTypes} from "../../enum/PermissionTypes";
import {IDialogGenericData} from "../../interfaces/IDialogGenericData";
import {
  ConfirmationActionDialogComponent
} from "../dialogs/confirmation-action-dialog/confirmation-action-dialog.component";
import {DialogFunctionService} from "../../services/dialog-function.service";
import {MoveCharityCategoriesComponent} from "../dialogs/move-charity-categories/move-charity-categories.component";
import {AsyncPipe, DatePipe} from "@angular/common";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {PipesModule} from "../../pipes/pipes.module";
import {HasPermissionDirective} from "../../directives/permissions/has-permission";

@Component({
  selector: 'app-charity-view',
  templateUrl: './charity-view.component.html',
  standalone: true,
  imports: [
    AsyncPipe,
    MatMenuTrigger,
    MatIconButton,
    MatIcon,
    MatTooltip,
    MatButton,
    DatePipe,
    PipesModule,
    MatMenu,
    MatMenuItem,
    HasPermissionDirective
  ],
  styleUrls: ['./charity-view.component.scss']
})
export class CharityViewComponent {
  public permissionTypes = PermissionTypes;
  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  public uiStateEnumForTemplate = UIStateEnum;
  public activeGame = this.gameService.activeGame();
  public activeCharity$: Observable<ICharityWithContributionTotals | undefined> = this.charityService.activeCharity$;
  constructor(private charityService: CharityService,
              private matDialog: MatDialog,
              private dialogFunctionService: DialogFunctionService,
              private gameService: GameService) {
  }

  public openChangeNameDialog() {
    this.matDialog.open(ChangeCharityNameComponent, {
      width: '650px'
    });
  }

  public onDeactivateCharityClick(charityP: ICharityWithContributionTotals) {
    this.dialogFunctionService.setCallback(() => {
      return this.charityService.deactivateCharity({
        charityId: charityP.Id,
        gameId: this.gameService.activeGame().Id,
        redirectUrl: `${window.location.origin}/login`
      })
    });

    let reactivateCharityDialogData: IDialogGenericData = {
      title: 'Confirm Charity Deactivation',
      message: `Please confirm that you would like to deactivate <span class="bold-text">${charityP.Name}</span>`,
      dataCyTag: 'confirm-deactivate-charity-button',
      successMessage: 'Successfully deactivated charity',
      extraDetails: 'After deactivating this charity, all players with active charity pledges using this charity, will have their pledges deactivated. These players will receive a notification of these changes and how to proceed.'
    };

    this.matDialog.open(ConfirmationActionDialogComponent, {
      data: reactivateCharityDialogData,
      width: '650px'
    });
  }

  public onReactivateCharityClick(charityP: ICharityWithContributionTotals) {
    this.dialogFunctionService.setCallback(() => {
      return this.charityService.reactivateCharity({
        charityId: charityP.Id,
        gameId: this.gameService.activeGame().Id
      })
    });

    let reactivateCharityDialogData: IDialogGenericData = {
      title: 'Confirm Charity Reactivation',
      message: `Please confirm that you would like to reactivate <span class="bold-text">${charityP.Name}</span>`,
      dataCyTag: 'confirm-reactivate-charity-button',
      successMessage: 'Successfully reactivated charity',
      extraDetails: 'Reactivated charities will immediately appear under the associated category in the game.'
    };

    this.matDialog.open(ConfirmationActionDialogComponent, {
      data: reactivateCharityDialogData,
      width: '650px'
    });
  }

  onChangeCategoriesClick(chosenCategoryP: ICharityWithContributionTotals) {
    this.matDialog.open(MoveCharityCategoriesComponent, {
      width: '650px',
      data: chosenCategoryP
    });
  }

  onBackButtonClick() {
    this.charityService.activeCharityScreenBehaviourSubject.next(CharityScreens.Category);
    this.charityService.activeCharityBehaviourSubject.next(undefined);
  }
}
