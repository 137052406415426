import {Pipe, PipeTransform} from '@angular/core';
import {IAuditEventType} from "../interfaces/audit/IAuditEventTypes";
import {AuditingService} from "../services/auditing.service";

@Pipe({
  name: 'auditEventTypeToDisplay'
})
export class AuditEventTypesPipe implements PipeTransform {
  public auditEventTypes: IAuditEventType[] = this.auditingService.getAuditEventTypes();

  constructor(private auditingService: AuditingService) {
  }

  public transform(auditEventTypeP: number): string {
    let auditEventType = this.auditEventTypes
      .find((currentAuditEventType) => auditEventTypeP === currentAuditEventType.Value);

    if(auditEventType) {
      return auditEventType.Name;
    }

    return 'Unknown';
  }
}
