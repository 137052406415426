import {Component, Input, OnInit} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {PlayersService} from "../../../services/players.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {GameService} from "../../../services/game.service";
import {ActiveUserService} from "../../../services/active-user.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {PlayerInfoTableDefs} from "../../player-game-info/player-info-table-defs";
import {IPlayerUnsubscribeResult} from "../../../interfaces/player/IPlayerUnsubscribeResult";
import {ILastFiveFailedEmailsQueryResult} from "../../../interfaces/player/ILastFiveFailedEmailsQueryResult";
import {Observable} from "rxjs";
import {GridApi, GridReadyEvent} from "ag-grid-community";
import {ISendGridMessageData} from "../../../interfaces/ISendGridMessageData";
import {IRemoveUnsubscribesForPlayer} from "../../../interfaces/player/IRemoveUnsubscribesForPlayer";
import {MatList, MatListItem} from "@angular/material/list";
import {MatIcon} from "@angular/material/icon";
import {AgGridAngular} from "ag-grid-angular";
import {AsyncPipe} from "@angular/common";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatButton} from "@angular/material/button";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";

@Component({
  selector: 'app-manage-player-send-grid-info',
  standalone: true,
  imports: [
    MatListItem,
    MatList,
    MatIcon,
    AgGridAngular,
    AsyncPipe,
    MatProgressSpinner,
    MatButton,
    HasPermissionDirective
  ],
  templateUrl: './manage-player-send-grid-info.component.html',
  styleUrl: './manage-player-send-grid-info.component.css'
})
export class ManagePlayerSendGridInfoComponent implements OnInit{
  @Input() public playerEmail: string = "";
  public playerSuppressions: IPlayerUnsubscribeResult | null = null;
  public lastFiveFailedEmails$: Observable<ILastFiveFailedEmailsQueryResult> | null = null;
  public sendGridMessageDataGridApi!: GridApi<ISendGridMessageData>;
  public sendGridColDefs = this.colDefs.sendGridMessageDataColDefs;
  public uiState = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public permissionTypes = PermissionTypes;

  constructor(private playerService: PlayersService,
              private snackBarService: SnackbarService,
              private gameService: GameService,
              private activeUserService: ActiveUserService,
              private errorHandlingService: ErrorHandlingService,
              private colDefs: PlayerInfoTableDefs) {
  }

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    this.uiState = UIStateEnum.ShowLoading;
    this.playerService.getUnsubscribesForPlayer(this.playerEmail).subscribe({
      next: (result) => {
        this.uiState = UIStateEnum.ShowData;
        this.playerSuppressions = result;
      },
      error: err => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage("Failed to fetch unsubscribe info for player");
      }
    });
    this.lastFiveFailedEmails$ = this.playerService.getLastFiveFailedEmailsForPlayer(this.playerEmail);
  }

  removeUnsubscribes() {
    let command: IRemoveUnsubscribesForPlayer = {
      PlayerEmail: this.playerEmail,
      GameId: this.gameService.activeGame().Id,
      AdminId: this.activeUserService.activeUser().Id
    };

    this.uiState = UIStateEnum.ShowLoading;

    this.playerService.deleteUnsubscribesForPlayer(command).subscribe({
      next: () => {
        this.snackBarService.openSuccessfulSnackBar("Successfully removed unsubscribes and spam reports");
        this.uiState = UIStateEnum.ShowData;
        this.fetchData();
      },
      error: err => {
        this.snackBarService.openErrorSnackBar("Failed to remove unsubscribes and spam reports");
        this.uiState = UIStateEnum.ShowData;
      }
    });
  }

  onSendGridMessageDataGridReady(params: GridReadyEvent<ISendGridMessageData>) {
    this.sendGridMessageDataGridApi = params.api;
    this.sendGridMessageDataGridApi.sizeColumnsToFit({
      defaultMinWidth: 100
    });
  }
}
