<mat-card>
  <mat-card-content>
    <h3>Transaction/Payment Filter</h3>
    <form [formGroup]="transactionSearchForm" class="margin-top-med">
      <div class="flex-row-wrap justify-space-between align-center">
        <mat-form-field class="flex-33" appearance="outline">
          <mat-label>Charge Id</mat-label>
          <input matInput placeholder="enter charge id" formControlName="chargeId" data-testid="charge-id-input">
          <mat-hint>enter entire or part of charge id</mat-hint>
        </mat-form-field>
        <mat-form-field class="flex-33" appearance="outline">
          <mat-label>Ticket Number</mat-label>
          <input matInput placeholder="enter a ticket number" formControlName="ticketNumber"
                 data-testid="ticket-number-input">
          <mat-hint>enter whole ticket number</mat-hint>
        </mat-form-field>
        <mat-form-field class="flex-33" appearance="outline">
          <mat-label>Transaction Date</mat-label>
          <input placeholder="filter by transaction date" matInput [matDatepicker]="picker"
                 formControlName="transactionDate" data-testid="transaction-date-input">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-hint>YYYY-MM-DD</mat-hint>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="text-end margin-bottom-med margin-top-med">
        <button matTooltip="search players" [disabled]="!transactionSearchForm.valid" mat-raised-button type="submit"
                color="primary" class="margin-right-small" (click)="onFormSubmit()"
                data-testid="search-players-button">Filter
          <mat-icon>search</mat-icon>
        </button>
        <button matTooltip="reset filters" mat-raised-button color="warn" (click)="onResetClick()"
                data-testid="reset-players-button">Reset
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </form>
    <h3>Transaction/Payment</h3>
    <ag-grid-angular
      id="playerTransactionsGrid"
      class="ag-theme-quartz margin-top-med large-table"
      data-testid="player-transaction-table"
      [tooltipShowDelay]=0
      rowClass="table-row"
      domLayout='autoHeight'
      overlayNoRowsTemplate="<span>No transactions for this game</span>"
      (gridReady)="onTransactionGridReady($event)"
      [rowData]="filteredTransactionsPerGame"
      [pagination]="true"
      [columnDefs]="transactionColumnDefs">
    </ag-grid-angular>
  </mat-card-content>
</mat-card>

