<div class="flex-row justify-center align-start margin-top-large">
  <mat-card class="flex-50">
    <mat-card-header>
      <mat-card-title class="text-center">Change your Password</mat-card-title>
    </mat-card-header>
    <mat-card-content class="text-center margin-top-large">
      <form [formGroup]="updatePasswordForm">
        <mat-form-field appearance="outline" class="full-width margin-bottom-small">
          <mat-label>Old Password</mat-label>
          <input
            data-testid="change-password-old-input"
            matInput
            [type]="showOldPassword ? 'text' : 'password'"
            formControlName="oldPassword"
            required
            placeholder="enter your old (existing) password"
          >

          @if (oldPasswordControl.hasError('required') && (oldPasswordControl.dirty || oldPasswordControl.touched)) {
            <mat-error
              data-testid="old-password-error">
              <strong>required.</strong>
            </mat-error>
          }
          <mat-icon matSuffix
                    (click)="showOldPassword = !showOldPassword">{{ showOldPassword ? 'visibility_off' : 'visibility' }}
          </mat-icon>
        </mat-form-field>

        <mat-form-field class="full-width margin-bottom-small" appearance="outline">
          <mat-label>New Password</mat-label>
          <input
            data-testid="change-password-new-input"
            matInput
            [type]="showNewPassword ? 'text' : 'password'"
            formControlName="newPassword"
            required
            placeholder="enter your new password"
          >

          @if (newPasswordControl.errors && (newPasswordControl.dirty || newPasswordControl.touched)) {
            <mat-error data-testid="new-password-error">
              @if (newPasswordControl.hasError('required')) {
                <strong>required.</strong>
              }

              @if (newPasswordControl.hasError('maxlength')) {
                cannot exceed <strong>50 chars.</strong>
              }

              @if (newPasswordControl.hasError('minlength')) {
                must be at least <strong>6 chars.</strong>
              }

              @if (newPasswordControl.hasError('pattern')) {
                must contain at least one <strong>uppercase</strong>, and one <strong>number.</strong>
              }
            </mat-error>
          }

          <mat-icon matSuffix
                    (click)="showNewPassword = !showNewPassword">{{ showNewPassword ? 'visibility_off' : 'visibility' }}
          </mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width margin-bottom-small">
          <mat-label>Confirm Password</mat-label>
          <input
            matInput
            data-testid="change-password-new-confirm-input"
            [type]="showConfirmPassword ? 'text' : 'password'"
            formControlName="confirmNewPassword"
            required
            placeholder="confirm your new password"
          >
          @if (confirmNewPasswordControl.errors && (confirmNewPasswordControl.dirty || confirmNewPasswordControl.touched)) {
            <mat-error
              data-testid="confirm-password-error">
              @if (confirmNewPasswordControl.hasError('required')) {
                <strong>required.</strong>
              }
              @if (confirmNewPasswordControl.hasError('confirmedValidator')) {
                new and confirm passwords must match
              }
            </mat-error>
          }

          <mat-icon matSuffix
                    (click)="showConfirmPassword = !showConfirmPassword">{{ showConfirmPassword ? 'visibility_off' : 'visibility' }}
          </mat-icon>
        </mat-form-field>

      </form>

      <button mat-raised-button
              data-testid="change-password-confirm-button"
              class="login-button"
              (click)="updatePassword()">Update Password
      </button>
    </mat-card-content>
  </mat-card>
</div>
