import {ActivatedRouteSnapshot, Resolve, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {IGameQueryResult} from "../interfaces/IGameQueryResult";
import {inject, Injectable} from "@angular/core";
import {GameService} from "../services/game.service";
import {Observable} from "rxjs";
import {ActiveUserService} from "../services/active-user.service";


export const GetGamesForUserResolver: ResolveFn<IGameQueryResult[]> = () => {
  const gameService = inject(GameService);
  const activeUserService = inject(ActiveUserService);

  return gameService.getGamesForUser(activeUserService.activeUser().Id);
}
