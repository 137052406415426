import {Pipe, PipeTransform} from '@angular/core';
import {EventSourceType} from "../enum/EventSourceType";

@Pipe({
  name: 'eventSourceToDisplay'
})
export class EventSourceToDisplayPipe implements PipeTransform {

  public transform(auditEventSourceValueP: EventSourceType): string {
    if (auditEventSourceValueP === EventSourceType.AdminApp) {
      return 'Admin App'
    } else if (auditEventSourceValueP === EventSourceType.WebApp) {
      return 'Web App'
    } else if (auditEventSourceValueP === EventSourceType.System) {
      return 'System'
    }

    return 'Unknown';
  }
}
