<app-dialog-base>
  <span dialog-title>Player Number {{gameData.PlayerNumber.Id}}</span>
  <ng-container dialog-content>
    <div class="flex-row-wrap justify-start gap-normal margin-bottom-med margin-top-med">
      <div class="detail-section">
        <div class="detail-label">Id</div>
        <div class="detail-value">
          <mat-icon [cdkCopyToClipboard]="gameData.PlayerNumber.Id" class="copy-icon">content_copy</mat-icon>
          {{gameData.PlayerNumber.Id}}
        </div>
      </div>
      <div class="detail-section">
        <div class="detail-label">Player Id</div>
        <div class="detail-value">
          <mat-icon
            [cdkCopyToClipboard]="gameData.PlayerNumber.PlayerId"
            class="copy-icon">
            content_copy
          </mat-icon>
          <span class="href-text" [matTooltip]="'navigate to player info'" (click)="openPlayer(gameData.PlayerNumber.PlayerId)">{{gameData.PlayerNumber.PlayerId}}</span>
        </div>
      </div>
      <div class="detail-section">
        <div class="detail-label">Ticket Number</div>
        <div class="detail-value">{{gameData.PlayerNumber.TicketNumber}}</div>
      </div>
      <div class="detail-section">
        <div class="detail-label">Created On</div>
        <div class="detail-value">{{gameData.PlayerNumber.CreatedOn | date: 'MMM d, y HH:mm'}}</div>
      </div>
      <div class="detail-section">
        <div class="detail-label">Active</div>
        <div class="detail-value">
          <mat-icon
            [class]="gameData.PlayerNumber.Active ? 'green-text': 'red-text'">{{gameData.PlayerNumber.Active ? 'check' : 'clear'}}
            check
          </mat-icon>
        </div>
      </div>
      <div class="detail-section">
        <div class="detail-label">Autoplay</div>
        <div class="detail-value">
          <mat-icon
            [class]="gameData.PlayerNumber.Autoplay ? 'green-text': 'red-text'">{{gameData.PlayerNumber.Autoplay ? 'check' : 'clear'}}
            check
          </mat-icon>
        </div>
      </div>

      @if(gameType == gameTypes.GoldRush) {
        <div class="detail-section">
          <div class="detail-label">Gold Rush Strip Id</div>
          <div class="detail-value">{{gameData.PlayerNumber.GoldRushStripNumber}}</div>
        </div>
      }
    </div>
  </ng-container>
  <ng-container actions>
  </ng-container>
</app-dialog-base>
