import {Component, Inject} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {PlayerGameNoteService} from "../../../services/player-game-note.service";
import {ActiveUserService} from "../../../services/active-user.service";
import {IAddPlayerNote} from "../../../interfaces/player/IAddPlayerNote";
import {SnackbarService} from "../../../services/snackbar.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {IPlayerNoteCommand} from "../../../interfaces/player/IPlayerNoteCommand";
import {PlayersService} from "../../../services/players.service";
import {IPlayerNote} from "../../../interfaces/player/IPlayerNote";
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatSelectModule} from "@angular/material/select";
import {MatButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";

@Component({
  selector: 'app-add-player-game-note',
  standalone: true,
  templateUrl: './add-player-game-note.component.html',
  imports: [
    DialogBaseComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCheckbox,
    MatSelectModule,
    MatButton,
    MatInput
  ],
  styleUrl: './add-player-game-note.component.scss'
})
export class AddPlayerGameNoteComponent {

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public currentUser = this.activeUserService.activeUser();
  public noteReasonTypes = [{name: "Deactivated Numbers", value: 1}, {name: "Deactivated Player", value: 2},
    {name: "Flagged Player", value: 3}, {name: "Other", value: 4}];

  public messageControl: FormControl<string | null> = new FormControl<string | null>("", Validators.required);
  public isCausableAdminNoteControl: FormControl<boolean | null> = new FormControl<boolean | null>(false);
  public reasonTypeControl: FormControl<number | null> = new FormControl<number | null>(null, Validators.required);

  public playerGameNoteForm = new FormGroup({
    message: this.messageControl,
    isCausableAdminNote: this.isCausableAdminNoteControl,
    reasonType: this.reasonTypeControl
  });

  constructor(private playerGameNoteService: PlayerGameNoteService,
              private activeUserService: ActiveUserService,
              private snackBarService: SnackbarService,
              private errorHandlingService: ErrorHandlingService,
              private matDialogRef: MatDialogRef<AddPlayerGameNoteComponent>,
              @Inject(MAT_DIALOG_DATA) public noteToUpdate: {
                note: IPlayerNote | undefined;
                playerId: string;
                gameId: string;
              }) {
    if (noteToUpdate?.note != undefined) {
      // if a note is passed in, that means the game id is not, and can be set from the note
      this.noteToUpdate.gameId = this.noteToUpdate.note!.GameId;
      this.populateForm();
    }
  }

  submit() {
    if (!this.playerGameNoteForm.valid) {
      this.playerGameNoteForm.markAllAsTouched();
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;

    let note: IAddPlayerNote = {
      GameId: this.noteToUpdate.gameId,
      PlayerId: this.noteToUpdate.playerId,
      Message: this.messageControl.value!,
      ReasonType: this.reasonTypeControl.value!,
      IsCausableAdminNote: this.isCausableAdminNoteControl ? this.isCausableAdminNoteControl.value! : false,
      CreatedBy: this.activeUserService.activeUser().Id,
    };

    let command: IPlayerNoteCommand = {
      gameId: this.noteToUpdate.gameId,
      playerId: this.noteToUpdate.playerId,
      adminId: this.activeUserService.activeUser().Id,
      note: note
    }

    this.playerGameNoteService.addPlayerGameNote(command).subscribe({
      next: () => {
        this.snackBarService.openSuccessfulSnackBar("Successfully added note to player");
        this.matDialogRef.close(true);
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage(err, true);
      }
    });
  }

  updateNote() {
    if (!this.playerGameNoteForm.valid) {
      this.playerGameNoteForm.markAllAsTouched();
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;
    const updatedNote = this.noteToUpdate?.note as IPlayerNote;

    let note: IPlayerNote = {
      Id: updatedNote.Id!,
      GameId: this.noteToUpdate.gameId,
      PlayerId: this.noteToUpdate.playerId,
      Message: this.messageControl.value!,
      ReasonType: this.reasonTypeControl.value!,
      IsCausableAdminNote: updatedNote.IsCausableAdminNote!,
      CreatedBy: updatedNote.CreatedBy!,
      CreatedOn: updatedNote.CreatedOn!,
      LastUpdatedBy: this.activeUserService.activeUser().Id
    }

    let command: IPlayerNoteCommand = {
      gameId: this.noteToUpdate.gameId,
      note: note,
      playerId: this.noteToUpdate.playerId,
      adminId: this.activeUserService.activeUser().Id
    };

    this.playerGameNoteService.updatePlayerGameNote(command).subscribe({
      next: () => {
        this.snackBarService.openSuccessfulSnackBar("Successfully updated note!");
        this.matDialogRef.close(true);
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage(err, true);
      }
    });
  }

  populateForm() {
    const updatedNote = this.noteToUpdate?.note as IPlayerNote;
    this.messageControl.setValue(updatedNote.Message);
    this.reasonTypeControl.setValue(updatedNote.ReasonType);
  }

}
