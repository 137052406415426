import {Injectable} from '@angular/core';

import {Observable, Subject, timer} from 'rxjs';
import {tap} from 'rxjs/operators';
import {GameInstanceService} from "./game-instance.service";

@Injectable({
  providedIn: 'root'
})
export class CountdownService {
  constructor(private gameInstanceService: GameInstanceService) {
  }

  public isDrawTakingPlace$: Subject<boolean> = new Subject<boolean>();
  public timeRemainingUntilDraw$: Subject<string> = new Subject<string>();

  public createCountdownTimerSubscription(gameInstanceEndDateP: Date): Observable<number> {
    return timer(0, 1000).pipe(tap(() => {

      if (gameInstanceEndDateP) {
        this.timeRemainingUntilDraw$.next(this.formatRemainingTimeUntilDraw(gameInstanceEndDateP));
        this.isDrawTakingPlace$.next(this.isDrawTakingPlace(gameInstanceEndDateP));
      }
    }));
  }

  private isDrawTakingPlace(valueP: Date): boolean {
    const end_date = Date.parse(valueP.toISOString());
    const today = Date.parse(new Date().toISOString());

    return end_date <= today;
  }

  public formatRemainingTimeUntilDraw(gameEndTimeP: Date): string {
    const end_date = Date.parse(gameEndTimeP.toISOString());
    const now = Date.parse(new Date().toISOString());

    if (end_date > now) {
      const total = end_date - now;

      const days = Math.floor(total / (1000 * 60 * 60 * 24));
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const seconds = Math.floor((total / 1000) % 60);

      let time_value = days;
      let time_indicator = '';

      if (days > 0) {
        time_indicator = 'day';
      } else if (hours > 0) {
        time_value = hours;
        time_indicator = 'hour';
      } else if (minutes) {
        time_value = minutes;
        time_indicator = 'minute';
      } else if (seconds) {
        time_value = seconds;
        time_indicator = 'second';
      }

      if (time_value > 1) {
        time_indicator += 's';
      }

      if (time_value === 0) {
        return '0 seconds';
      }

      return `${time_value} ${time_indicator}`;
    } else if (end_date <= now) {
      this.gameInstanceService.gameInstanceHasEndedSubject.next(true);
      return 'Ready for Draw';
    }

    return '';
  }
}
