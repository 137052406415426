<app-dialog-base>
  <span dialog-title>Player Payment {{gameData.PlayerPayment.Id}}</span>
  <ng-container dialog-content>
    <h3>Payment Data</h3>
    <div class="flex-row-wrap justify-start gap-normal margin-bottom-med margin-top-med">
      <div class="detail-section">
        <div class="detail-label">Id</div>
        <div class="detail-value">
          <mat-icon [cdkCopyToClipboard]="gameData.PlayerPayment.Id" class="copy-icon">content_copy</mat-icon>
          {{gameData.PlayerPayment.Id}}
        </div>
      </div>
      <div class="detail-section">
        <div class="detail-label">Player Id</div>
        <div class="detail-value">
          <mat-icon
            [cdkCopyToClipboard]="gameData.PlayerPayment.PlayerId"
            class="copy-icon">
            content_copy
          </mat-icon>
          <span class="href-text" [matTooltip]="'navigate to player info'"
                (click)="openPlayer(gameData.PlayerPayment.PlayerId)">{{gameData.PlayerPayment.PlayerId}}</span>
        </div>
      </div>
      <div class="detail-section">
        <div class="detail-label">Created On</div>
        <div class="detail-value">{{gameData.PlayerPayment.CreatedOn | date: 'MMM d, y HH:mm'}}</div>
      </div>
      <div class="detail-section">
        <div class="detail-label">Made Via Auto Play</div>
        <div class="detail-value">
          <mat-icon
            [class]="gameData.PlayerPayment.MadeViaAutoPlay ? 'green-text': 'red-text'">{{gameData.PlayerPayment.MadeViaAutoPlay ? 'check' : 'clear'}}
            check
          </mat-icon>
        </div>
      </div>
    </div>

    <h3>Payment Total : {{gameData.PlayerPayment.Total | formatCurrency}}</h3>
    <h4>Payment Breakdown
      <mat-icon
        [matTooltip]="'pot contribution + admin commission + causable fees + causable fees tax + charity contribution + stripe fees'"
        class="info-icon"
      >info
      </mat-icon>
    </h4>
    <div class="flex-row-wrap justify-start gap-normal margin-bottom-med margin-top-med">
      <div class="detail-section">
        <div class="detail-label">Pot Contribution (winner contribution)</div>
        <div class="detail-value">{{gameData.PlayerPayment.PotContribution | formatCurrency}}</div>
      </div>
      <div class="detail-section">
        <div class="detail-label">Administration Commission</div>
        <div class="detail-value">{{gameData.PlayerPayment.AdministrationCommission | formatCurrency}}</div>
      </div>
      <div class="detail-section">
        <div class="detail-label">Causable Fees</div>
        <div class="detail-value">{{gameData.PlayerPayment.CausableFees | formatCurrency}}</div>
      </div>
      <div class="detail-section">
        <div class="detail-label">Causable Fees Tax</div>
        <div class="detail-value">{{gameData.PlayerPayment.CausableFeesTax | formatCurrency}}</div>
      </div>
      <div class="detail-section">
        <div class="detail-label">Charity Contribution</div>
        <div class="detail-value">{{gameData.PlayerPayment.TotalCharityPortionAfterFees | formatCurrency}}</div>
      </div>
      <div class="detail-section">
        <div class="detail-label">Stripe Fees</div>
        <div class="detail-value">{{gameData.PlayerPayment.PaymentProcessorFees | formatCurrency}} ({{gameData.PlayerPayment.PaymentProcessorChargeId}})</div>
      </div>
    </div>
    <hr>
    <h3>Charity Contribution : {{gameData.PlayerPayment.TotalCharityPortionAfterFees | formatCurrency}}</h3>
    <h4>Breakdown
      <mat-icon
        [matTooltip]="'total before fees - (total taxes deducted from charity contribution + total taxes deducted from charity contribution)'"
        class="info-icon"
      >info
      </mat-icon>
    </h4>
    <div class="flex-row-wrap justify-start gap-normal margin-bottom-med margin-top-med">
      <div class="detail-section">
        <div class="detail-label">Total Before Fees</div>
        <div
          class="detail-value">{{gameData.PlayerPayment.Total / 2 | formatCurrency}}</div>
      </div>
      <div class="detail-section">
        <div class="detail-label">Total Fees Deducted From Charity Contribution</div>
        <div
          class="detail-value">{{gameData.PlayerPayment.TotalFeesDeductedFromCharityPortion | formatCurrency}}</div>
      </div>
      <div class="detail-section">
        <div class="detail-label">Total Taxes Deducted From Charity Contribution</div>
        <div
          class="detail-value">{{gameData.PlayerPayment.TotalTaxesDeductedFromCharityPortion| formatCurrency}}</div>
      </div>
    </div>
  </ng-container>
  <ng-container actions>
  </ng-container>
</app-dialog-base>
