import {DateAdapter, MAT_DATE_FORMATS} from "@angular/material/core";
import * as Sentry from "@sentry/angular-ivy";
import {APP_INITIALIZER, ErrorHandler, NgModule} from "@angular/core";
import {OAuthModule, OAuthStorage} from "angular-oauth2-oidc";
import {Router} from "@angular/router";
import {MatSidenavModule} from "@angular/material/sidenav";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AppComponent} from "./app.component";
import {AppRoutingModule} from "./app-routing.module";
import {BrowserModule} from "@angular/platform-browser";
import {HttpClientModule} from "@angular/common/http";
import {LuxonDateAdapter} from "@angular/material-luxon-adapter";
import {MarkdownModule} from "ngx-markdown";
import {
  ComponentLevelErrorMessageComponent
} from "./components/display-errors/component-level-error-message/component-level-error-message.component";
import {PageLevelErrorMessageComponent} from "./components/display-errors/page-level-error-message.component";
import {GameBannerComponent} from "./components/game-banner/game-banner.component";
import {SideNavComponent} from "./components/side-nav/side-nav.component";

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MMM dd, yyyy',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'MMM dd, yyyy',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};

export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    OAuthModule.forRoot(),
    MarkdownModule.forRoot(),
    GameBannerComponent,
    MatSidenavModule,
    SideNavComponent,
    PageLevelErrorMessageComponent,
    ComponentLevelErrorMessageComponent
  ],
  providers: [
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {provide: DateAdapter, useClass: LuxonDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {provide: OAuthStorage, useFactory: storageFactory},
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
