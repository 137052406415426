import {Component, inject, Input} from '@angular/core';
import {ColDef, FirstDataRenderedEvent, GridSizeChangedEvent} from "ag-grid-community";
import {IPlayerNote} from "../../../interfaces/player/IPlayerNote";
import {PlayerInfoTableDefs} from "../../player-game-info/player-info-table-defs";
import {MatCardModule} from "@angular/material/card";
import {MatIcon} from "@angular/material/icon";
import {AgGridAngular} from "ag-grid-angular";
import {AddPlayerGameNoteComponent} from "../../dialogs/add-player-game-note/add-player-game-note.component";
import {MatDialog} from "@angular/material/dialog";
import {IGamePlayerInputData} from "../../../interfaces/player/IGamePlayerInputData";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {concatMap} from "rxjs";
import {PlayersService} from "../../../services/players.service";
import {PlayerGameNoteService} from "../../../services/player-game-note.service";
import {MatButton} from "@angular/material/button";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";

@Component({
  selector: 'app-manage-player-notes',
  standalone: true,
  imports: [
    MatCardModule,
    MatIcon,
    AgGridAngular,
    MatButton,
    HasPermissionDirective
  ],
  templateUrl: './manage-player-notes.component.html',
  styleUrl: './manage-player-notes.component.css'
})
export class ManagePlayerNotesComponent {
  private playerGameNoteService: PlayerGameNoteService = inject(PlayerGameNoteService);
  private playerInfoTables: PlayerInfoTableDefs = inject(PlayerInfoTableDefs);
  private errorHandlingService: ErrorHandlingService = inject(ErrorHandlingService);
  private matDialog: MatDialog = inject(MatDialog);

  @Input()
  public set gamePlayerData(gamePlayerDataP: IGamePlayerInputData) {
    if (gamePlayerDataP.playerId && gamePlayerDataP.game) {
      this.playerId = gamePlayerDataP.playerId;
      this.gameId = gamePlayerDataP.game.Id;
      this.getPlayerNotes(gamePlayerDataP.playerId, this.gameId);
    }
  }

  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  public uiStateEnum = UIStateEnum;

  public playerNotes: IPlayerNote[] = [];
  public playerId: string = '';
  public gameId: string = '';

  public playerNotesColumnDefs: ColDef[] = this.playerInfoTables.playerNotesColumnDefs;

  private getPlayerNotes(playerIdP: string, gameIdP: string) {
    this.uiState = UIStateEnum.ShowLoading;
    // when subject gets pushed a value (will happen on de-active ticket numbers) get updated ticket data from api.
    this.playerGameNoteService.fetchNotesSubject$
      .pipe(
        concatMap(() => this.playerGameNoteService.getPlayerNotesForGame(playerIdP, gameIdP))
      ).subscribe({
      next: (notes) => {
        this.playerNotes = notes;
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err, 'Looks there was an issue retrieving player notes.' +
          ' Please try reloading and if the problem persists contact a system administrator');
      }
    });
  }

  gridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  addPlayerNote() {
    const dialogRef = this.matDialog.open(AddPlayerGameNoteComponent, {
      width: '650px',
      //This is to make sure that the dialog opened is set to add a note to player, not update one
      data: {
        gameId: this.gameId,
        playerId: this.playerId,
        note: undefined
      }
    });
    dialogRef.afterClosed().subscribe({
      next: (addSuccessful: boolean) => {
        if (addSuccessful) {
          this.playerGameNoteService.fetchNotesSubject.next(true);
        }
      }
    })
  }

  onPlayerNotesRowClicked(rowData: any) {
    const note = rowData.data as IPlayerNote;

    const dialogRef = this.matDialog.open(AddPlayerGameNoteComponent,
      {
        width: '650px',
        data: {
          gameId: note.GameId,
          playerId: this.playerId,
          note: note
        }
      });
    dialogRef.afterClosed().subscribe({
      next: (updateSuccessful: boolean) => {
        if (updateSuccessful) {
          this.playerGameNoteService.fetchNotesSubject.next(true);
        }
      }
    });
  }

  protected readonly PermissionTypes = PermissionTypes;
}
