<app-dialog-base [showLoadingSpinner]="uiState == uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Update Generic Game Settings</span>
  <ng-container dialog-content>
    <form [formGroup]="genericGameSettingsForm">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Enter New License Number</mat-label>
        <input
          formControlName="faqUrl"
          type="text"
          matInput
          data-testid="update-faq-input"
          placeholder="enter faq url">
        <mat-hint>if this field is left empty, the FAQ menu item will not appear up in the game menu</mat-hint>
      </mat-form-field>
    </form>
  </ng-container>
  <ng-container actions>
    <button
      mat-button
      data-testid="update-generic-settings-submit"
      (click)="updateGenericSettings()"
      [disabled]="uiState == uiStateEnumForTemplate.ShowLoading || !genericGameSettingsForm.dirty">Update
    </button>
  </ng-container>
</app-dialog-base>

