import { Injectable } from '@angular/core';
import {APIService} from "./api.service";
import {IEditGameHowToPlayText} from "../interfaces/IEditGameHowToPlayText";
import {IGameHowToPlay} from "../interfaces/IGameHowToPlay";

@Injectable({
  providedIn: 'root'
})
export class HowToPlayService {

  constructor(private apiService: APIService) {
  }

  public getGameHowToPlayText(gameIdP: string) {
    return this.apiService.MakeGetRequest<IGameHowToPlay>("how-to-play-for-game/" + gameIdP);
  }

  public addGameHowToPlayText(howToPlayP: IEditGameHowToPlayText) {
    return this.apiService.MakePostRequest<IEditGameHowToPlayText>("game/how-to-play", howToPlayP);
  }

  public updateGameHowToPlayText(howToPlayP: IEditGameHowToPlayText) {
    return this.apiService.MakePutRequest<IEditGameHowToPlayText>("game/how-to-play", howToPlayP);
  }
}
