@if (activeGameInstance$ | async; as gameInstance) {
  <div class="one-column-page-container">
    <mat-card class="text-center padding-large" data-testid="draw-page-card">
      <div class="bold-text ready-for-draw">Pot total</div>
      <div class="bold-text ready-for-draw margin-top-small"
           data-testid="pot-total">{{ gameInstance.PotTotal | formatCurrency }}</div>
      @if (uiState == uiStateForTemplate.ShowData && !winnerIsBeingDrawn) {

        @if (gameInstance.State == gameInstanceStates.ReadyForDraw) {
          <div>
            <div class="bold-text ready-for-draw margin-top-med">Looks like the draw is ready!!</div>
            <button
              mat-raised-button
              class="margin-top-med primary-button"
              (click)="drawWinner()"
              data-testid="draw-winner-button">
              Draw Winner
            </button>
          </div>
        }
        @if (gameInstance.State != gameInstanceStates.ReadyForDraw && !showEndDatePassedButNotReadyMessage) {
          <div class="text-center">
            <p class="bold-text ready-for-draw" data-testid="draw-not-ready-message">Looks like the draw is not ready
              yet. Please wait for the game to end
              before attempting to draw a winner.</p>
          </div>
        } @else if (gameInstance.State != gameInstanceStates.ReadyForDraw && showEndDatePassedButNotReadyMessage) {

          <div class="bold-text ready-for-draw margin-top-med"
               data-testid="draw-over-not-ready-message">{{ endDatePassedButNotReadyMessage }}
          </div>
        }

        @if (winnerResponse) {
          @if (winnerResponse.Played) {
            <div class="text-center draw-results-title">CONGRATS !!!!</div>
            <div class="text-center win-gold" data-testid="winner-name">{{ winnerResponse.PlayerName }}</div>
            <div class="text-center draw-results-title">Winning Number</div>
            <div class="text-center ticket margin-top-med">{{ winnerResponse.TicketNumber }}</div>
          } @else {
            <div class="text-center draw-results-title">SORRY !!!!</div>
            <div class="text-center win-gold" data-testid="no-winner-name">{{ winnerResponse.PlayerName }}</div>
            <div class="text-center draw-results-title">Drawn Number</div>
            <div class="text-center ticket">{{ winnerResponse.TicketNumber }}</div>
            <div class="text-center">Looks like this weeks winning ticket number was not played. Better luck next time
            </div>
          }
        }

      } @else if (uiState == uiStateForTemplate.ShowLoading) {
        <div class="bold-text ready-for-draw margin-top-med" data-testid="draw-in-progress-message">The draw is now
          taking place. Please wait while we determine
          the winner.
        </div>
        <mat-spinner class="center-margin"></mat-spinner>
      }
    </mat-card>

  </div>
}
