<h3>Suppressions</h3>
<div class="flex-col">
  @if (uiState === uiStateEnumForTemplate.ShowData) {
    <mat-list>
      <mat-list-item>
        <span matListItemTitle>Globally Unsubscribed</span>
        <mat-icon matListItemIcon>{{ playerSuppressions!.IsGloballyUnsubscribed ? "check" : "do_not_disturb" }}
        </mat-icon>
      </mat-list-item>

      <mat-list-item>
        <span matListItemTitle>Group Unsubscribed</span>
        <mat-icon matListItemIcon>{{ playerSuppressions!.HasGroupUnsubscribe ? "check" : "do_not_disturb" }}
        </mat-icon>
      </mat-list-item>

      <mat-list-item>
        <span matListItemTitle>Marked as Spam</span>
        <mat-icon matListItemIcon>{{ playerSuppressions!.MarkedAsSpam ? "check" : "do_not_disturb" }}</mat-icon>
      </mat-list-item>
    </mat-list>

    <button *hasPermission="permissionTypes.RemoveSendgridSuppressions"
            mat-stroked-button
            (click)="removeUnsubscribes()">Remove Unsubscribes and Spam Reports</button>

    <h4>Last Five Failed Emails</h4>
    <ag-grid-angular
      id="playerLastFiveFailedEmailsGrid"
      class="ag-theme-quartz margin-top-med large-table"
      data-testid="last-five-failed-emails-table"
      [tooltipShowDelay]=0
      rowClass="table-row"
      domLayout='autoHeight'
      overlayNoRowsTemplate="<span>Player has not had any emails fail to be delivered</span>"
      (gridReady)="onSendGridMessageDataGridReady($event)"
      [rowData]="(lastFiveFailedEmails$ | async)?.Messages"
      [columnDefs]="sendGridColDefs">
    </ag-grid-angular>
  } @else {
    <mat-spinner class="center-margin"></mat-spinner>
  }
</div>
