import {Component} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {IAdminQueryResult} from "../../../interfaces/IAdminQueryResult";
import {Router} from "@angular/router";
import {GameService} from "../../../services/game.service";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-game-admin-settings',
  standalone: true,
  templateUrl: './generic-admin-settings.component.html',
  imports: [
    HasPermissionDirective,
    MatMenuTrigger,
    MatIconButton,
    MatIcon,
    MatTooltip,
    MatMenu,
    MatMenuItem
  ],
  styleUrls: ['./generic-admin-settings.component.scss']
})
export class GenericAdminSettingsComponent implements ICellRendererAngularComp {

  public adminUser!: IAdminQueryResult;
  public permissionTypes = PermissionTypes;

  constructor(private gameService: GameService,
              private router: Router) {
  }

  agInit(params: ICellRendererParams): void {
    this.adminUser = params.data;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  onViewDetailsClick() {
    let activeGame = this.gameService.activeGame().Id;
    this.router.navigateByUrl(`${activeGame}/user-details/${this.adminUser.Id}`);
  }
}
