import {Component, Inject, inject} from '@angular/core';
import {DialogBaseComponent} from "../../dialogs/dialog-base/dialog-base.component";
import {MatButton} from "@angular/material/button";
import {MatDatepicker} from "@angular/material/datepicker";
import {MatError} from "@angular/material/form-field";
import {NgIf} from "@angular/common";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {GameService} from "../../../services/game.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IApiErrorResponse} from "../../../interfaces/error/IApiErrorResponse";
import {IGameTestimonials} from "../../../interfaces/IGameTestimonials";
import {TestimonialsService} from "../../../services/testimonials.service";
import {FormModule} from "@coreui/angular";

@Component({
  selector: 'app-testimonials-edit',
  standalone: true,
  imports: [
    DialogBaseComponent,
    MatButton,
    MatDatepicker,
    MatError,
    ReactiveFormsModule,
    FormModule,
    NgIf
  ],
  templateUrl: './testimonials-edit.component.html',
  styleUrl: './testimonials-edit.component.css'
})
export class TestimonialsEditComponent {
  private testimonialsService: TestimonialsService = inject(TestimonialsService);
  private gameService: GameService = inject(GameService);
  private snackbarService: SnackbarService = inject(SnackbarService);

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;

  testimonialContentControl = new FormControl<string | null>("", [
    Validators.required,
    Validators.minLength(10),
    Validators.maxLength(1100)
  ]);
  userNameControl = new FormControl<string | null>("", Validators.required);
  userPositionControl = new FormControl<string | null>("", Validators.required);
  activeControl = new FormControl<boolean>(false);

  testimonialForm = new FormGroup({
    content: this.testimonialContentControl,
    userPosition: this.userPositionControl,
    userName: this.userNameControl,
    active: this.activeControl
  });

  constructor(private matDialogRef: MatDialogRef<TestimonialsEditComponent>,
              @Inject(MAT_DIALOG_DATA) public testimonial: IGameTestimonials) {
    this.populateForm(testimonial);

  }

  populateForm(testimonialP: IGameTestimonials) {
    this.testimonialForm.setValue({
      content: testimonialP.Content,
      userPosition: testimonialP.GivenByUserPosition,
      userName: testimonialP.GivenByUserName,
      active: testimonialP.Active
    })
  }

  editLicenceNumber() {
    this.testimonialForm.markAllAsTouched();

    if (!this.testimonialForm.valid) {
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;

    this.testimonialsService.editTestimonials({
      gameId: this.gameService.activeGame().Id!,
      active: this.activeControl.value!,
      id: this.testimonial.Id,
      content: this.testimonialContentControl.value!,
      givenByUserName: this.userNameControl.value!,
      givenByUserPosition: this.userPositionControl.value!,
    }).subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
        this.snackbarService.openSuccessfulSnackBar('Successfully edited testimonial');
        this.matDialogRef.close();
      }, error: (res: IApiErrorResponse) => {
        this.uiState = UIStateEnum.ShowData;
        if (res.Error?.ClientErrorMessages?.length > 0) {
          this.snackbarService.openErrorSnackBar(res.Error.ClientErrorMessages.toString());
        } else {
          this.snackbarService.openErrorSnackBar('There was an issue editing your testimonial');
        }
      }
    })
  }
}
