import { Injectable } from '@angular/core';
import {APIService} from "./api.service";
import {IEmailBlast} from "../interfaces/IEmailBlast";

@Injectable({
  providedIn: 'root'
})
export class EmailBlastService {

  constructor(private apiService: APIService)
  { }

  sendEmailBlast(emailBlastP: IEmailBlast) {
    return this.apiService.MakePostRequest<IEmailBlast>("game-instance/email-blast", emailBlastP);
  }
}
