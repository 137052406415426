<h2 mat-dialog-title data-testid="dialog-title"><ng-content select="[dialog-title]"></ng-content></h2>
<app-dialog-display-error></app-dialog-display-error>
<mat-dialog-content data-testid="dialog-base-content">
  <ng-content select="[dialog-content]"></ng-content>
</mat-dialog-content>

@if(showLoadingSpinner) {
  <mat-spinner class="center-margin"></mat-spinner>
}
<mat-dialog-actions align="end">
  <button mat-button class="mat-warn close-dialog-button" data-testid="cancel-dialog" [mat-dialog-close]="false">Cancel</button>
  <ng-content select="[actions]"></ng-content>
</mat-dialog-actions>
