import {Component} from '@angular/core';
import {ErrorHandlingService} from "../../services/error-handling.service";
import {Observable} from "rxjs";
import {AsyncPipe} from "@angular/common";

@Component({
  selector: 'app-page-display-error',
  standalone: true,
  imports: [
    AsyncPipe
  ],
  templateUrl: './base-display-error/base-display-error-message.component.html',
  styleUrls: ['./base-display-error/base-display-error-message.component.scss']
})
export class PageLevelErrorMessageComponent {

  public errorMessage$: Observable<string> = this.errorHandlingService.pageLevelErrorMessage$;

  constructor(private errorHandlingService: ErrorHandlingService) {
  }

}
