import {Pipe, PipeTransform} from '@angular/core';
import {DrawIntervalTypes} from "../enum/DrawIntervalTypes";

@Pipe({
  name: 'drawInterval'
})
export class DrawIntervalPipe implements PipeTransform {
  public transform(drawInterval: DrawIntervalTypes): string {
    if (drawInterval) {
      switch (drawInterval) {
        case DrawIntervalTypes.Weekly:
          return 'Weekly';
        case DrawIntervalTypes.Biweekly:
          return 'Bi-Weekly';
      }
    }
    return 'Unknown Interval Type';
  }
}
