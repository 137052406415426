import {Component, Input} from '@angular/core';
import {AgGridAngular} from "ag-grid-angular";
import {MatButton} from "@angular/material/button";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatTooltip} from "@angular/material/tooltip";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {IGamePlayerInputData} from "../../../interfaces/player/IGamePlayerInputData";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {IPlayerPaymentTransactions} from "../../../interfaces/player/IPlayerPaymentTransactions";
import {ColDef, GridApi, GridReadyEvent} from "ag-grid-community";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {GameService} from "../../../services/game.service";
import {PlayersService} from "../../../services/players.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {DateService} from "../../../services/date.service";
import {PlayerInfoTableDefs} from "../../player-game-info/player-info-table-defs";
import {FormatCurrencyPipe} from "../../../pipes/format-currency.pipe";
import {IPaymentTier} from "../../../interfaces/player/IPaymentTier";
import {MatCard, MatCardContent} from "@angular/material/card";

@Component({
  selector: 'app-manage-player-transaction',
  standalone: true,
  imports: [
    AgGridAngular,
    MatButton,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatFormField,
    MatHint,
    MatIcon,
    MatInput,
    MatLabel,
    MatSuffix,
    MatTooltip,
    ReactiveFormsModule,
    MatCard,
    MatCardContent
  ],
  templateUrl: './manage-player-transaction.component.html',
  styleUrl: './manage-player-transaction.component.css'
})
export class ManagePlayerTransactionComponent {

  @Input()
  public set gamePlayerData(gamePlayerDataP: IGamePlayerInputData) {
    if (gamePlayerDataP.playerId && gamePlayerDataP.game.Id) {
      this.getPlayerTransactions(gamePlayerDataP.playerId, gamePlayerDataP.game.Id);
    }
  }

  public permissionTypes = PermissionTypes;
  public playerTransactionsPerGame: IPlayerPaymentTransactions[] = [];
  public filteredTransactionsPerGame: IPlayerPaymentTransactions[] = [];
  public transactionGridApi!: GridApi<IPlayerPaymentTransactions>;
  public transactionColumnDefs: ColDef[] = this.playerInfoTables.transactionColumnDefs;
  public availableTiersPerGame = this.gameService.getActiveGamePaymentTiers();
  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateForTemplate = UIStateEnum;

  public transactionSearchForm = new FormGroup(
    {
      chargeId: new FormControl(''),
      transactionDate: new FormControl(''),
      ticketNumber: new FormControl('')
    }
  );

  constructor(private gameService: GameService,
              private playerService: PlayersService,
              private errorHandlingService: ErrorHandlingService,
              private dateService: DateService,
              private playerInfoTables: PlayerInfoTableDefs,
              private formatCurrencyPipe: FormatCurrencyPipe) {
  }

  getPlayerTransactions(playerIdP: string, gameIdP: string) {
    this.uiState = UIStateEnum.ShowLoading;
    this.playerService.getPlayerTransactionsByGameId(playerIdP, gameIdP).subscribe({
      next: (playerTransactionsP) => {
        playerTransactionsP = playerTransactionsP
          .map((transaction) => {
            const tier = this.availableTiersPerGame?.find((tier) => tier.Id === transaction.PaymentTierId);
            if (tier) {
              transaction.PaymentTierString = this.formatTierToReadableName(tier);
            }
            return transaction;
          });
        this.playerTransactionsPerGame = playerTransactionsP;
        this.filteredTransactionsPerGame = playerTransactionsP.sort((a, b) => new Date(a.CreatedOn).getDate() - new Date(b.CreatedOn).getDate());
        this.uiState = UIStateEnum.ShowData;
      }, error: (err) => {
        this.errorHandlingService.displayPageLevelErrorMessage(err, 'Looks there was an issue retrieving player transactions.' +
          ' Please try reloading and if the problem persists contact a system administrator');
      }
    });
  }

  onTransactionGridReady(params: GridReadyEvent<IPlayerPaymentTransactions>) {
    this.transactionGridApi = params.api;
    this.transactionGridApi.sizeColumnsToFit({
      defaultMinWidth: 100,
    });
  }

  onResetClick() {
    this.transactionSearchForm.reset();
    this.filteredTransactionsPerGame = this.playerTransactionsPerGame;
  }

  onFormSubmit() {
    if (!this.transactionSearchForm.valid) {
      this.transactionSearchForm.markAllAsTouched();
      return;
    }
    this.filteredTransactionsPerGame = this.playerTransactionsPerGame.filter((transactionP) => {
      let ticketNumberFilterPasses: boolean = true;
      let stripeIdFilterPasses: boolean = true;
      let dateFilterPasses: boolean = true;

      if (this.transactionSearchForm.value.ticketNumber) {
        ticketNumberFilterPasses = transactionP.TicketNumbers.includes(Number(this.transactionSearchForm.value.ticketNumber));
      }

      if (this.transactionSearchForm.value.chargeId) {
        stripeIdFilterPasses = transactionP.StripeChargeId.includes(this.transactionSearchForm.value.chargeId);
      }

      if (this.transactionSearchForm.value.transactionDate) {
        const parsedDate = new Date(this.transactionSearchForm.value.transactionDate);
        dateFilterPasses = this.dateService.areDatesSameDay(transactionP.CreatedOn, parsedDate)
      }


      return ticketNumberFilterPasses && stripeIdFilterPasses && dateFilterPasses;
    })
  }

  formatTierToReadableName(tierP: IPaymentTier) {
    return `${tierP.NumberOfTickets} for ${this.formatCurrencyPipe.transform(tierP.Price)}`;
  }
}
