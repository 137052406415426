import {Component} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {SnackbarService} from "../../../services/snackbar.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {GameService} from "../../../services/game.service";
import {MatDialogRef} from "@angular/material/dialog";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";
import {IUpdateSensitiveGameSettings} from "../../../interfaces/IUpdateSensitiveGameSettings";
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-update-sensitive-game-settings',
  standalone: true,
  templateUrl: './update-sensitive-game-settings.component.html',
  imports: [
    DialogBaseComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInput,
    MatButton
  ],
  styleUrls: ['./update-sensitive-game-settings.component.css']
})
export class UpdateSensitiveGameSettingsComponent {
  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public game: IGameQueryResult = this.gameService.activeGame();
  public licenceNumberControl: FormControl<string | null> = new FormControl<string | null>('', Validators.required);
  public sensitiveGameSettingsForm = new FormGroup({
    licenceNumber: this.licenceNumberControl
  });

  constructor(private snackBarService: SnackbarService,
              private errorHandlingService: ErrorHandlingService,
              private gameService: GameService,
              private matDialogRef: MatDialogRef<UpdateSensitiveGameSettingsComponent>) {
    this.populateForm();
  }

  private populateForm() {
    // this.licenceNumberControl.setValue(this.game.LicenceNumber);
  }

  updateSensitiveSettings() {

    if (!this.sensitiveGameSettingsForm.valid) {
      this.sensitiveGameSettingsForm.markAsDirty();
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;
    let updateSettingsRequest: IUpdateSensitiveGameSettings = {
      gameId: this.gameService.activeGame()?.Id!,
      licenceNumber: this.licenceNumberControl.value!
    };

    this.gameService.updateSensitiveGameSettings(updateSettingsRequest).subscribe({
      next: () => {
        this.matDialogRef.close();
        this.snackBarService.openSuccessfulSnackBar('Successfully updated game settings');
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage(err, true);
      }
    })

  }
}
