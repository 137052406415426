import {Component} from '@angular/core';
import {OidcService} from "../../../services/oidc.service";
import {MatCardContent, MatCardHeader, MatCardModule} from "@angular/material/card";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'causable-logout-page',
  standalone: true,
  styleUrls: ['logout.page.scss'],
  imports: [
    MatCardModule,
    MatCardHeader,
    MatCardContent,
    MatButton
  ],
  templateUrl: 'logout.page.html'
})
export class LogoutPage
{
  constructor(private oidcService: OidcService)
  {}

  onLogoutClick(): void
  {
    this.oidcService.logout();
  }
}
