<app-dialog-base [showLoadingSpinner]="uiState == uiStateEnumForTemplate.ShowLoading">
  <span
    dialog-title>Update Basic Player Info</span>

  <ng-container dialog-content>
    @if (chosenPlayer && uiState === uiStateEnumForTemplate.ShowData) {
      <form [formGroup]="updateBasicInfoForm">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>First Name</mat-label>
          <input data-testid="edit-player-info-fname-input" formControlName="firstName" type="text" matInput
                 placeholder="enter first name" required>

          @if (firstNameControl.hasError('required')) {
            <mat-error data-testid="fname-required-error">
              first name is required
            </mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width margin-top-small">
          <mat-label>Last Name</mat-label>
          <input data-testid="edit-player-info-lname-input" formControlName="lastName" type="text" matInput
                 placeholder="enter last name" required>
          @if (lastNameControl.hasError('required')) {
            <mat-error data-testid="lname-required-error">
              last name is required
            </mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width margin-top-small">
          <mat-label>Phone Number</mat-label>
          <input data-testid="edit-player-info-phone-input" formControlName="phoneNumber" type="text" matInput
                 placeholder="enter phone number" required>
          @if (phoneNumberControl.invalid) {
            <mat-error data-testid="phone-number-error">please enter a valid phone number</mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width margin-top-small">
          <mat-label>Email</mat-label>
          <input data-testid="edit-player-info-email-input" formControlName="email" type="text" matInput
                 placeholder="enter email" required>
          @if (emailControl.errors && (emailControl.dirty || emailControl.touched)) {
            <mat-error data-testid="email-error">
              @if (emailControl.hasError('required')) {
                <strong>required.</strong>
              }
              @if (emailControl.hasError('email')) {
                incorrect email format. <strong>{{ 'ex: example@test.com' }}</strong>
              }
            </mat-error>
          }

        </mat-form-field>
      </form>

      @if(areChanges()) {
        <h3>Proposed Changes</h3>
        @for(key of Object.keys(changes); track key) {
          <div data-testid="proposed-changes">
            @if(changes[key]) {
              <div [innerHTML]="changes[key]"></div>
            }
          </div>
        }
      }
    }

  </ng-container>

  <ng-container actions>
    <button
      data-testid="save-update-charity"
      mat-button
      (click)="updateBasicInfo()"
      [disabled]="uiState === uiStateEnumForTemplate.ShowLoading  || !areChanges()">Confirm
    </button>
  </ng-container>
</app-dialog-base>
