import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class DateService {

  public twoWeeksAgo(dateP = new Date()) {
    dateP.setDate(dateP.getDate() - 14);

    return dateP;
  }
  public areDatesSameDay(dateOneP: Date, dateTwoP: Date) {
    dateOneP = new Date(dateOneP);
    dateTwoP = new Date(dateTwoP);

    return dateOneP.toDateString() == dateTwoP.toDateString();
  }

  public tomorrow(dateP = new Date()) {
    dateP.setDate(dateP.getDate() + 1);

    return dateP;
  }

  public convertToMilitaryTime(minutesFromMidnightP: number) {
    const hours = Math.floor(minutesFromMidnightP / 60);
    const mins = minutesFromMidnightP % 60;

    const militaryHours = String(hours).padStart(2, '0');
    const militaryMins = String(mins).padStart(2, '0');

    return `${militaryHours}:${militaryMins}`;
  }

  public convertMilitaryTimeToMinutes(militaryTime: string): number {
    const [hoursStr, minutesStr] = militaryTime.split(':');

    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);

    if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
      throw new Error('Invalid military time format. Must be in HH:mm format.');
    }

    return hours * 60 + minutes;
  }
}
