import {Component, Input} from '@angular/core';
import {ReportingService} from "../../../services/reporting.service";
import {IHourlyTicketSalesPerHourPerGame} from "../../../interfaces/reporting/IHourlyTicketSalesPerHourPerGame";
import {ColDef, GridApi, GridReadyEvent} from "ag-grid-community";
import {DatePipe} from "@angular/common";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {GameService} from "../../../services/game.service";
import {DateService} from "../../../services/date.service";
import {
  ComponentLevelErrorMessageComponent
} from "../../display-errors/component-level-error-message/component-level-error-message.component";
import {MatFormField, MatHint, MatLabel} from "@angular/material/form-field";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {FormsModule} from "@angular/forms";
import {MatInput} from "@angular/material/input";
import {AgGridAngular} from "ag-grid-angular";
import {LoadingCardContentComponent} from "../../../shared/loading-card-content/loading-card-content.component";

@Component({
  selector: 'app-hourly-ticket-sales',
  standalone: true,
  templateUrl: './hourly-ticket-sales.component.html',
  imports: [
    ComponentLevelErrorMessageComponent,
    MatFormField,
    MatDatepickerInput,
    MatLabel,
    MatHint,
    FormsModule,
    MatInput,
    MatDatepickerToggle,
    MatDatepicker,
    AgGridAngular,
    LoadingCardContentComponent
  ],
  styleUrls: ['./hourly-ticket-sales.component.scss']
})
export class HourlyTicketSalesComponent {

  @Input() set setDefaultGameInstanceDrawDate(drawTimeP: Date) {
    this.reportingService.hourlyTicketSalesBreakdown(this.gameService.activeGame().Id).subscribe({
      next: (reportResP) => {
        if (reportResP.length > 0) {
          this.ticketSalesPerHour = reportResP;
          this.drawDate = drawTimeP;
          this.filterResultsPerGameDrawDate();
        }
        this.uiState = UIStateEnum.ShowData;
      },
      error: () => {
        this.errorMessage = 'There was an issue retrieving your retention report. ' +
          'Please try refreshing the page or reaching out to a system administrator.';
        this.uiState = UIStateEnum.ShowData;
      }
    })
  }

  public ticketSalesPerHour: IHourlyTicketSalesPerHourPerGame[] = [];
  public filteredTicketSalesPerGameNumber: IHourlyTicketSalesPerHourPerGame[] = [];
  public uiState = UIStateEnum.ShowLoading;
  public drawDate: Date | null = null;
  public uiStateForTemplate = UIStateEnum;
  public errorMessage: string = '';

  public groupsPerHourDefs: ColDef[] = [
    {
      headerName: 'Draw Date',
      field: 'DrawDate',
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y');
        return convertedDate ? convertedDate : '';
      },
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Date of Sale',
      resizable: true,
      sortable: true,
      width: 250,
      // the hour start is the day that the time period is for
      field: 'HourRangeStart',
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y');
        return convertedDate ? convertedDate : '';
      }
    },
    {
      headerName: 'Tickets Sold',
      resizable: true,
      wrapHeaderText: true,
      filter: 'agNumberColumnFilter',
      sortable: true,
      field: 'TotalNumbersPurchased'
    },
    {
      headerName: 'Hour Range Start',
      resizable: true,
      wrapHeaderText: true,
      sortable: true,
      field: 'HourRangeStart',
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'h:mm a');
        return convertedDate ? convertedDate : '';
      }
    },
    {
      headerName: 'Hour Range End',
      resizable: true,
      wrapHeaderText: true,
      sortable: true,
      field: 'HourRangeEnd',
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'h:mm a');
        return convertedDate ? convertedDate : '';
      }
    }
  ];

  public groupsGridApi!: GridApi<IHourlyTicketSalesPerHourPerGame>;

  constructor(private reportingService: ReportingService,
              private gameService: GameService,
              private dateService: DateService,
              private datePipe: DatePipe) {
  }

  filterResultsPerGameDrawDate(overrideP: boolean = false) {
    if (this.drawDate && !overrideP) {
      this.filteredTicketSalesPerGameNumber = this.ticketSalesPerHour.filter((reportP) => {
        return this.dateService.areDatesSameDay(reportP.DrawDate, this.drawDate!)
      });

      return;
    }
    this.drawDate = null;
    this.filteredTicketSalesPerGameNumber = this.ticketSalesPerHour;
  }

  onGroupsGridReady(params: GridReadyEvent<IHourlyTicketSalesPerHourPerGame>) {
    this.groupsGridApi = params.api;
    this.groupsGridApi.sizeColumnsToFit();
  }
}
