import {Component} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {MatDialog} from "@angular/material/dialog";
import {ICellRendererParams} from "ag-grid-community";
import {UpdateUserRolesComponent} from "../../dialogs/update-user-roles/update-user-roles.component";
import {UsersService} from "../../../services/users.service";
import {IAdminQueryResult} from "../../../interfaces/IAdminQueryResult";
import {Router} from "@angular/router";
import {GameService} from "../../../services/game.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {MatDivider} from "@angular/material/divider";

@Component({
  selector: 'app-game-admin-settings',
  templateUrl: './game-admin-settings.component.html',
  standalone: true,
  imports: [
    HasPermissionDirective,
    MatMenuTrigger,
    MatIconButton,
    MatIcon,
    MatTooltip,
    MatMenu,
    MatMenuItem,
    MatDivider
  ],
  styleUrls: ['./game-admin-settings.component.scss']
})
export class GameAdminSettingsComponent implements ICellRendererAngularComp {

  public adminUser!: IAdminQueryResult;
  public permissionTypes = PermissionTypes;

  constructor(private matDialog: MatDialog,
              private gameService: GameService,
              private snackbarService: SnackbarService,
              private errorHandlingService: ErrorHandlingService,
              private router: Router,
              private userService: UsersService) {
  }

  agInit(params: ICellRendererParams): void {
    this.adminUser = params.data;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  onDeactivateUserClick() {
    this.userService.onDeactivateUserClick(this.adminUser);
  }

  onViewDetailsClick() {
    let activeGame = this.gameService.activeGame().Id;
    this.router.navigateByUrl(`${activeGame}/user-details/${this.adminUser.Id}`);
  }

  onUpdateUserRolesClick() {
    const dialogRef = this.matDialog.open(UpdateUserRolesComponent, {
      data: this.adminUser,
      width: '650px'
    });

    dialogRef.afterClosed().subscribe(
      {
        next: (updateSuccessful: boolean) => {
          if (updateSuccessful) {
            this.userService.getUpdatedAdminUsersBehaviorSubject.next(true);
          }
        }
      })
  }

  onReactiveUserClick() {
    this.userService.onReactivateUserClick(this.adminUser);
  }

  onResendInviteClick() {
    let activeGame = this.gameService.activeGame().Id;
    this.userService.resendAdminInvite({
      targetAdminId: this.adminUser.Id,
      gameId: activeGame,
      roles: this.adminUser.Roles,
      redirectUrl: `${window.location.origin}/login`
    }).subscribe({
      next: () => this.snackbarService.openSuccessfulSnackBar('Successfully resent email'),
      error: (err) => {
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });
  }
}
