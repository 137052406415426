<div class="one-column-page-container">
  @if (uiState === uiStateEnumForTemplate.ShowLoading) {
    <mat-spinner></mat-spinner>
  } @else if (uiState === uiStateEnumForTemplate.ShowData) {
    @if ((activeCharityScreen$ | async); as activeScreen) {

      @switch (activeScreen) {
        @case (charityScreens.AllCategories) {
          <div class="flex-row justify-space-between" data-testid="all-categories-screen">
            <h2>Charity Categories</h2>
          </div>
          <div>
            <div cdkDropListGroup class="margin-right-med full-width">
              <div class="margin-top-med margin-bottom-med" *hasPermission="PermissionTypes.ModifyCharities">
                <button mat-raised-button
                        color="primary"
                        data-testid="add-charities-button"
                        (click)="addNewCharities()">
                  <mat-icon class="enable-sort">add</mat-icon>
                  Add Charities
                </button>

                <div class="float-right" *hasPermission="PermissionTypes.ModifyCharities">
                  @if(charityCategoriesForSorting.length > 1) {
                    <button
                      mat-stroked-button
                      data-testid="sort-charity-category-button"
                      [color]="enableSortCharityCategory ? 'warn': 'primary'"
                      (click)="updateSortingCategories()">
                      {{ enableSortCharityCategory ? 'Cancel' : 'Sort List' }}
                      <mat-icon class="enable-sort">sort</mat-icon>
                    </button>
                  }
                  @if (enableSortCharityCategory) {
                    <button mat-stroked-button
                            matTooltip="Sort Categories Alphabetically"
                            class="margin-left-med"
                            data-testid="sort-categories-alphabetically-button"
                            color="primary"
                            (click)="sortCategoriesAlphabetically()">
                      <mat-icon class="padding-left-small">sort_by_alpha</mat-icon>
                    </button>
                  }
                   @if(enableSortCharityCategory) {
                     <button
                       mat-stroked-button
                       color="primary"
                       data-testid="sort-categories-save-changes-button"
                       class="margin-left-med"
                       (click)="submitCategoryChanges()"
                       matTooltip="Save changes to sort order">Save Changes
                       <mat-icon>save</mat-icon>
                     </button>
                   }

                </div>
              </div>

              <div
                cdkDropList
                data-testid="charity-category-list"
                [cdkDropListData]="charityCategoriesForSorting"
                class="charity-category-list"
                [class.drag-cursor]="enableSortCharityCategory"
                [class.pointer-cursor]="!enableSortCharityCategory"
                [cdkDropListDisabled]="!enableSortCharityCategory"
                (cdkDropListDropped)="dropCategory($event)">
                <!--    individual list(draggable) item  -->
                @for(item of charityCategoriesForSorting; track item) {
                  <div class="drag-box"
                       data-testid="charity-category-item"
                       cdkDrag
                       (click)="viewCharities(item)">
                    <div class="padding-right-med flex-row justify-space-between align-center full-width">
                      <div>
                        {{ item.Category.Name }}
                        @if(!item.Category.Active) {
                          <span class="inactive"> (inactive)</span>
                        }
                        @if(item.Charities.length === 0) {
                          <mat-icon
                            matTooltip="Looks like this category has 0 associated charities. This category will not show up in the game until there are associated charities"
                            class="red-text no-charities-icon">block
                          </mat-icon>
                        }
                      </div>
                      <div>
                        <mat-icon
                          class="sort-icon"
                          color="primary"
                        >{{ enableSortCharityCategory ? 'drag_indicator' : 'arrow_forward_ios' }}
                        </mat-icon>
                      </div>
                    </div>
                  </div>

                }
              </div>
            </div>
          </div>
        }
        @case (charityScreens.Category) {
          <app-charity-category-view></app-charity-category-view>
        }
        @case (charityScreens.Charity) {
          <app-charity-view></app-charity-view>
        }
      }
    }
  }
</div>
