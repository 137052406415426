<app-dialog-base [showLoadingSpinner]="uiState == uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Add Charity Category</span>

  <p>Choose a new category for {{ charity.Name }}.</p>
  <ng-container dialog-content>

    @if (uiState === uiStateEnumForTemplate.ShowData) {
      <form
        [formGroup]="moveCharityForm">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Choose New Charity Category</mat-label>
          <mat-select
            data-testid="change-charity-category-select"
            formControlName="categoryControl">
            <mat-option [value]="null"> ----</mat-option>
            @for (category of charityCategories; track category) {
              <mat-option data-testid="change-charity-category-option"
                          [value]="category.Id">{{ category.Name }}
              </mat-option>
            }
          </mat-select>
          @if (moveCharityForm.controls.categoryControl.hasError('required')) {
            <mat-error data-testid="charity-category-error">
              category is <span class="bold-text">required</span>
            </mat-error>
          }
        </mat-form-field>
      </form>
      <small>*Note: this transaction will not affect any players in the game. It will only update where the charity will
        be located in the "Mange your donations" page</small>
    }

  </ng-container>
  <ng-container actions>
    <button
      data-testid="confirm-change-category-button"
      mat-button
      (click)="submitNewCategoryChanges()"
      [disabled]="uiState == uiStateEnumForTemplate.ShowLoading">
      Update Charity Category
    </button>
  </ng-container>
</app-dialog-base>
