<app-dialog-base [showLoadingSpinner]="uiState == uiStateForTemplate.ShowLoading">
  <span dialog-title>Assign Roles</span>
  <ng-container dialog-content>
    <div autofocus [tabindex]="0">Current Roles</div>
    <mat-chip-listbox aria-label="current roles for user">
      @for (role of adminUser.Roles; track role) {
        <mat-chip
          data-testid="assigning-roles-current-role-chip"
          [matTooltip]="role.Description"
          matTooltipPosition="below">
          {{ role.Name }}
        </mat-chip>
      }
    </mat-chip-listbox>
    @if (possibleRoles.length > 0) {
      <p>Select roles to assign to this user. You may assign multiple roles to a user.</p>
      <form [formGroup]="newPermissionFormGroup">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Select Roles</mat-label>
          <mat-chip-grid #chipGrid aria-label="permission selection">
            @for (role of rolesToAdd; track role) {
              <mat-chip-row
                data-testid="assigning-roles-role-to-add-chip"
                (removed)="remove(role)">
                {{ role.Name }}
                <button matChipRemove [attr.aria-label]="'remove ' + role">
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
            }
          </mat-chip-grid>
          <input #roleInput [formControl]="newRoleFormControl"
                 data-testid="assigning-roles-select-roles-input"
                 placeholder="start typing or select from list...."
                 [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
                 (matChipInputTokenEnd)="add($event)"/>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            @for (role of filteredPossibleRoles | async; track role) {
              <mat-option
                data-testid="assigning-roles-select-options"
                [value]="role"
                [matTooltip]="role.Description"
                matTooltipPosition="right">
                {{ role.Name }}
              </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
        @if (rolesToAdd.length === 0 && (newRoleFormControl.touched || newRoleFormControl.dirty)) {
          <mat-error
            data-testid="assigning-roles-select-error">You
            must choose at least one role to add
          </mat-error>
        }
      </form>
    } @else {
      <p>Looks like this user has all available roles</p>
    }


    @if (newRoleFormControl.value) {
      <div>New Permission:
        <span class="bold-text">{{ newRoleFormControl.value }}</span>
      </div>
    }

  </ng-container>
  <ng-container actions>
    <button
      data-testid="confirm-update-user-roles-button"
      mat-button
      class="primary-button"
      [disabled]="uiState === uiStateForTemplate.ShowLoading"
      (click)="updateUserPermissions()">Update
    </button>
  </ng-container>
</app-dialog-base>
