import {Component, inject, OnInit} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {ReactiveFormsModule} from "@angular/forms";
import {ActiveUserService} from "../../services/active-user.service";
import {IAdminQueryResult} from "../../interfaces/IAdminQueryResult";
import {MatIconModule} from "@angular/material/icon";
import {GameService} from "../../services/game.service";
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {ListGroupDirective, ListGroupItemDirective} from "@coreui/angular";
import {Router} from "@angular/router";
import {ICharity} from "../../interfaces/charity/ICharity";

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [
    MatButton,
    MatError,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    ReactiveFormsModule,
    MatIconModule,
    MatProgressSpinner,
    ListGroupDirective,
    ListGroupItemDirective
  ],
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.css'
})
export class LandingComponent implements OnInit {
  protected uiState: UIStateEnum = UIStateEnum.ShowLoading;
  protected UIStateEnum = UIStateEnum;

  protected activeUserService: ActiveUserService = inject(ActiveUserService);
  protected router: Router = inject(Router);
  protected gameService: GameService = inject(GameService);
  protected user: IAdminQueryResult | null = null;
  protected userGames: IGameQueryResult[] = [];

  ngOnInit(): void {
    this.gameService.clearActiveGame();
    this.user = this.activeUserService.activeUser();

    this.gameService.getGamesForUser(this.user.Id).subscribe({
      next: (gamesP) => {
        this.userGames = gamesP;
        this.checkUsersForSinglePermission(gamesP);
      }, error: (err) => {
        this.uiState = UIStateEnum.ShowData;
      }
    })
  }

  private async checkUsersForSinglePermission(gamesP: IGameQueryResult[]) {
    let userIsSingleGameAdmin = this.userGames.length === 1 && !this.user?.CausableAdmin && this.user?.Charities.length == 0;
    let userIsSingleCharityAdmin = this.user?.Charities?.length == 1 && !this.user?.CausableAdmin && this.userGames.length === 0;

    if (userIsSingleGameAdmin) {
      const chosenGameId = gamesP[0].Id;
      await this.router.navigateByUrl(`${chosenGameId}/active-game-instances`);
    } else if (userIsSingleCharityAdmin) {
      const chosenCharityId = this.user?.Charities[0].Id;
      await this.router.navigateByUrl(`/charity/${chosenCharityId}`);
    }

    this.uiState = UIStateEnum.ShowData;
  }

  public async updateAndNavigateToActiveGame(chosenGameP: IGameQueryResult) {
    this.gameService.updateActiveGame(chosenGameP);
    await this.router.navigateByUrl(`${chosenGameP.Id}/active-game-instances`);
  }

  public async updateAndNavigateToActiveCharity(chosenCharityP: ICharity) {
    await this.router.navigateByUrl(`charity/${chosenCharityP.Id}`);
  }
}
