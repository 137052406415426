import {Component} from '@angular/core';
import {ErrorHandlingService} from "../../services/error-handling.service";
import {Observable} from "rxjs";
import {AsyncPipe} from "@angular/common";

@Component({
  selector: 'app-dialog-display-error',
  standalone: true,
  templateUrl: './base-display-error/base-display-error-message.component.html',
  imports: [
    AsyncPipe
  ],
  styleUrls: ['./base-display-error/base-display-error-message.component.scss']
})
export class DialogLevelErrorMessageComponent {
  public errorMessage$: Observable<string> = this.errorHandlingService.dialogLevelErrorMessage$;

  constructor(private errorHandlingService: ErrorHandlingService) {
  }

}
