<div class="one-column-page-container">

  <h2>Winner Payouts</h2>
  <h3 data-testid="active-game-name">{{ activeGame.Name }} - Winners</h3>
  <ag-grid-angular
    class="ag-theme-quartz"
    rowClass="payout-row"
    [tooltipShowDelay]=0
    domLayout='autoHeight'
    data-testid="payout-table"
    [loading]="uiState == UIStateEnum.ShowLoading"
    overlayNoRowsTemplate="<span>No charities for this game</span>"
    (gridReady)="onWinnerPayoutGridReady($event)"
    [rowData]="winnerPayouts$ | async"
    [columnDefs]="winnerPayoutDefs">
  </ag-grid-angular>

</div>
