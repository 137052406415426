<form [formGroup]="newGameDrawSettingsForm" (change)="onFormChange()">
  <mat-form-field appearance="outline" class="full-width">
    <mat-label>Draw Frequency</mat-label>
    <mat-select formControlName="instanceIntervalType" placeholder="choose how often the draw will happen">
      <mat-option [value]="1">Weekly</mat-option>
      <mat-option [value]="2">Biweekly</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>Draw Time</mat-label>
    <input matInput formControlName="drawTimeMinutesFromMidnightLocal" placeholder="enter time of the draw" data-testid="draw-time-input">
    <mat-hint>this is the time of your draw. Please enter as military time. i.e. 2pm would be 14:00, 7am would be 07:00
    </mat-hint>
    <mat-error data-testid="draw-time-minutes-from-midnight-local-error"
               *ngIf="newGameDrawSettingsForm.controls.drawTimeMinutesFromMidnightLocal.errors && (newGameDrawSettingsForm.controls.drawTimeMinutesFromMidnightLocal.touched || newGameDrawSettingsForm.controls.drawTimeMinutesFromMidnightLocal.dirty)">
      required
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>Draw Day of the Week</mat-label>
    <mat-select formControlName="drawDayOfWeek" placeholder="choose the day of the week for your draw">
      <mat-option [value]="0">Sunday</mat-option>
      <mat-option [value]="1">Monday</mat-option>
      <mat-option [value]="2">Tuesday</mat-option>
      <mat-option [value]="3">Wednesday</mat-option>
      <mat-option [value]="4">Thursday</mat-option>
      <mat-option [value]="5">Friday</mat-option>
      <mat-option [value]="6">Saturday</mat-option>
    </mat-select>
    <mat-hint>0 = sunday, 1 monday, etc...</mat-hint>
    <mat-error data-testid="draw-day-of-the-week-error"
               *ngIf="newGameDrawSettingsForm.controls.drawDayOfWeek.errors && (newGameDrawSettingsForm.controls.drawDayOfWeek.touched || newGameDrawSettingsForm.controls.drawDayOfWeek.dirty)">
      required
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>Max Tickets Per Draw</mat-label>
    <input formControlName="maxTicketsPerDraw" placeholder="enter max number of tickets that can be in the draw"
           matInput data-testid="max-tickets-per-draw-input">
    <mat-error data-testid="max-tickets-per-draw-error"
               *ngIf="newGameDrawSettingsForm.controls.maxTicketsPerDraw.errors && (newGameDrawSettingsForm.controls.maxTicketsPerDraw.touched || newGameDrawSettingsForm.controls.maxTicketsPerDraw.dirty)">
      required
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>Max Amount a player can spend per draw ($)</mat-label>
    <input type="number" formControlName="maxAmountPlayersCanSpendPerDraw"
           placeholder="enter max $ amount a player can play per game instance" matInput>
    <mat-error data-testid="max-amount-spent-per-draw-error"
               *ngIf="newGameDrawSettingsForm.controls.maxAmountPlayersCanSpendPerDraw.errors && (newGameDrawSettingsForm.controls.maxAmountPlayersCanSpendPerDraw.touched || newGameDrawSettingsForm.controls.maxAmountPlayersCanSpendPerDraw.dirty)">

      <ng-container *ngIf="newGameDrawSettingsForm.controls.maxAmountPlayersCanSpendPerDraw.hasError('required')">
        required
      </ng-container>
      <ng-container *ngIf="newGameDrawSettingsForm.controls.maxAmountPlayersCanSpendPerDraw.hasError('min')">
        must be at least 5$
      </ng-container>
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>Minimum Draw Length (hours)</mat-label>
    <input type="number" formControlName="minimumGameLengthInHours"
           placeholder="enter min game length" matInput>
    <mat-hint>what is the minimum acceptable draw length for your game.
    </mat-hint>
    <mat-error data-testid="minimum-game-length-error"
               *ngIf="newGameDrawSettingsForm.controls.minimumGameLengthInHours.errors && (newGameDrawSettingsForm.controls.minimumGameLengthInHours.touched || newGameDrawSettingsForm.controls.minimumGameLengthInHours.dirty)">
      required
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>Draw Notifications Start Time</mat-label>
    <input formControlName="notificationsStartMinutesFromMidnightLocal" data-testid="draw-notification-start-time-input"
           placeholder="enter time you would like to send notifications" matInput>
    <mat-hint>on the day of the draw, what time would you like to send notifications to your users. Please enter as
      military time. i.e. 2pm would be 14:00, 7am would be 07:00
    </mat-hint>
    <mat-error data-testid="notifications-start-error"
               *ngIf="newGameDrawSettingsForm.controls.notificationsStartMinutesFromMidnightLocal.errors && (newGameDrawSettingsForm.controls.notificationsStartMinutesFromMidnightLocal.touched || newGameDrawSettingsForm.controls.notificationsStartMinutesFromMidnightLocal.dirty)">
      required
    </mat-error>
  </mat-form-field>

  <mat-checkbox class="margin-top-large" formControlName="manualDraw">Draw Performed Manually</mat-checkbox>
</form>
