import {Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {PermissionsService} from "../../services/permissions.service";
import {Subscription} from "rxjs";

@Directive({
  standalone: true,
  selector: '[userHasCausableAdminAccess]'
})
export class UserHasCausableAdminAccessDirective implements OnInit, OnDestroy {

  private getIsCausableAdminSubscription: Subscription | null = null;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionsService
  ) {
  }

  ngOnInit() {
    this.permissionService.hasCausableAdminPermissions().subscribe((hasAccess: boolean) => {
      if (hasAccess) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.getIsCausableAdminSubscription != null) {
      this.getIsCausableAdminSubscription.unsubscribe();
    }
  }
}
