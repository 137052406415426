import {EnvironmentType} from "../enum/EnvironmentType";

export interface IPotentialEnvironment {
  type: EnvironmentType;
  name: string;
}

export const PotentialEnvironments: IPotentialEnvironment[] = [
  {
    name: 'DEV',
    type: EnvironmentType.Dev
  },
  {
    name: 'STAGING',
    type: EnvironmentType.Staging
  },
  {
    name: 'LOCAL',
    type: EnvironmentType.Local
  },
]
