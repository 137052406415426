import {Component} from '@angular/core';
import {MatTab, MatTabContent, MatTabGroup} from "@angular/material/tabs";
import {SupportRequestsTabComponent} from "./support-requests-tab/support-requests-tab.component";

@Component({
  selector: 'app-support-desk',
  standalone: true,
  imports: [
    MatTabGroup,
    MatTab,
    MatTabContent,
    SupportRequestsTabComponent
  ],
  templateUrl: './support-desk.component.html',
  styleUrl: './support-desk.component.scss'
})
export class SupportDeskComponent {

}
