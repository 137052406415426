<h2 mat-dialog-title>Pay {{ winnerPayout.PlayerFirstName }} {{ winnerPayout.PlayerLastName }}</h2>
<mat-dialog-content class="mat-typography">

  <div class="flex-row justify-space-between align-start">
    <div class="margin-bottom-med flex-50">
      <div class="bold-text">Player Name:</div>
      <div data-testid="payout-player-name">{{ winnerPayout.PlayerFirstName }} {{ winnerPayout.PlayerLastName }}</div>
    </div>

    <div class="margin-bottom-med flex-50">
      <div class="bold-text">Player Email:</div>
      <div data-testid="payout-player-email">{{ winnerPayout.PlayerEmail }}</div>
    </div>
  </div>

  <div class="flex-row justify-space-between align-start">
    <div class="margin-bottom-med flex-50">
      <div class="bold-text">Game Draw Date:</div>
      <div data-testid="payout-draw-date">{{ winnerPayout.DrawTime | date: 'yyyy-MM-dd' }}</div>
    </div>

    <div class="margin-bottom-med flex-50">
      <div class="bold-text">Created On:</div>
      <div data-testid="payout-created-on">{{ winnerPayout.CreatedOn |  date: 'yyyy-MM-dd' }}</div>
    </div>
  </div>

  <div class="flex-row justify-space-between align-start">
    <div class="margin-bottom-med flex-50">
      <div class="bold-text">Winning Ticket Number:</div>
      <div data-testid="payout-winning-ticket-number">{{ winnerPayout.TicketNumber }}</div>
    </div>
  </div>

  <form [formGroup]="makeWinnerPaymentForm">
    <div>Does your payout amount not line up with the player Winnings? If so please toggle the payment override.</div>
    <div class="float-right">
      <mat-slide-toggle [checked]="overrideEnabled"
                        data-testid="toggle-payout-override"
                        (change)="onToggleChange($event)">Override Payout Amount
      </mat-slide-toggle>
    </div>

    @if (overrideEnabled) {
      <mat-form-field appearance="outline" class="full-width margin-top-large">
        <mat-label>Reason for Payout Override</mat-label>
        <textarea
          data-testid="payout-override-reason"
          formControlName="paymentOverrideReason"
          placeholder="enter your rationale here" matInput></textarea>
        @if (paymentOverrideReasonControl.hasError('required')) {
          <mat-error data-testid="payout-reason-required-error">Payout override reason is required</mat-error>
        }
      </mat-form-field>
    }

    <mat-form-field appearance="outline" class="full-width margin-top-med">
      <mat-label>Date Payment was Made</mat-label>
      <input matInput formControlName="paymentDateControl" [matDatepicker]="picker" data-testid="payout-payment-date">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <h2 class="bold-text">Player Winnings: {{ winnerPayout.PlayerWinnings | formatCurrency }}</h2>

    <mat-form-field class="full-width margin-top-medium">
      <mat-label>Payout Amount</mat-label>
      <mat-icon matIconPrefix>attach_money</mat-icon>
      <input
        data-testid="payout-amount"
        matInput
        formControlName="paymentAmount"/>
      @if (paymentAmountControl.touched && paymentAmountControl.errors) {
        @if (paymentAmountControl.hasError('required')) {
          <mat-error data-testid="payout-amount-required-error">Payout amount is required</mat-error>
        } @else if (paymentAmountControl.hasError('amountExceedsWinnings')) {
          <mat-error data-testid="payout-amount-mismatch-error">Payout amount must match player winnings</mat-error>
        }
      }
    </mat-form-field>

  </form>

  @if (uiState === uiStateEnumForTemplate.ShowLoading) {
    <mat-spinner></mat-spinner>
  }

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false" data-testid="cancel-button">Cancel</button>
  <button mat-button class="primary-button"
          (click)="submitPayout()"
          [disabled]="uiState === uiStateEnumForTemplate.ShowRequestProcessing"
          data-testid="submit-payout-edit">
    @if (uiState === uiStateEnumForTemplate.ShowRequestProcessing) {
      <mat-spinner diameter="32" color="accent"></mat-spinner>
    } @else {
      Submit Payout
    }
  </button>
</mat-dialog-actions>
