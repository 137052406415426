import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NewGameService} from "../../../services/new-game.service";
import {IGamePaymentSettingsForm} from "../../../interfaces/new-game/forms/IGamePaymentSettingsForm";
import {MatError, MatFormField, MatHint, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {NgIf} from "@angular/common";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatCheckbox} from "@angular/material/checkbox";

@Component({
  selector: 'app-game-payment-settings-form',
  standalone: true,
  templateUrl: './game-payment-settings-form.component.html',
  imports: [
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    NgIf,
    MatLabel,
    MatHint,
    MatError,
    MatSelect,
    MatOption,
    MatCheckbox
  ],
  styleUrl: './game-payment-settings-form.component.css'
})
export class GamePaymentSettingsFormComponent implements OnInit {

  private newGameService: NewGameService = inject(NewGameService);

  @Input() newGamePaymentSettingsForm: FormGroup<IGamePaymentSettingsForm> = new FormGroup<IGamePaymentSettingsForm>({
    administrationCommissionMultiplier: new FormControl<number>(0),
    causableFeeMultiplier: new FormControl<number>(0, [Validators.required, Validators.min(1)]),
    deductTaxesFromCharityContribution: new FormControl<boolean>(true, Validators.required),
    stripePublicKey: new FormControl<string>('', Validators.required),
    taxMultiplier: new FormControl<number>(0, [Validators.required, Validators.min(1)])
  });

  @Output() newGamePaymentFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  ngOnInit(): void {
    this.populateGameGeneralFromState()
  }

  onFormChange() {
    this.newGamePaymentFormChange.emit(this.newGamePaymentSettingsForm)
  }

  private populateGameGeneralFromState() {
    const newGamePayment = this.newGameService.getNewGameRequest()?.newGamePayment;
    if (newGamePayment) {
      this.newGamePaymentSettingsForm.controls.taxMultiplier.setValue(newGamePayment.taxMultiplier ? (newGamePayment.taxMultiplier * 100) : 0);
      this.newGamePaymentSettingsForm.controls.stripePublicKey.setValue(newGamePayment.stripePublicKey);
      this.newGamePaymentSettingsForm.controls.causableFeeMultiplier.setValue(newGamePayment.causableFeeMultiplier ? newGamePayment.causableFeeMultiplier * 100 : 0);
      this.newGamePaymentSettingsForm.controls.administrationCommissionMultiplier.setValue(newGamePayment.administrationCommissionMultiplier ? newGamePayment.administrationCommissionMultiplier * 100 : 0);
      this.newGamePaymentSettingsForm.controls.deductTaxesFromCharityContribution.setValue(newGamePayment.deductTaxesFromCharityContribution);
    }

    this.newGamePaymentFormChange.emit(this.newGamePaymentSettingsForm)
  }

}
