<ag-grid-angular
  class="ag-theme-quartz margin-top-med margin-bottom-med"
  [tooltipShowDelay]=0
  [autoSizeStrategy]="autoSizeStrategy"
  [domLayout]="'autoHeight'"
  [rowHeight]="50"
  overlayNoRowsTemplate="<span>No lottery licences for this game</span>"
  (gridReady)="onLotteryGridReady($event)"
  [rowData]="lotteryLicencesForGame$ | async"
  [columnDefs]="lotteryLicencesDefs">
</ag-grid-angular>
