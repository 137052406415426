<mat-card class="margin-bottom-med">
  <mat-card-header>
    <mat-card-title>Pledges</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <ag-grid-angular
      class="ag-theme-quartz margin-top-med margin-bottom-med player-pledges-table"
      [tooltipShowDelay]=0
      domLayout='autoHeight'
      [rowHeight]="50"
      [pagination]="true"
      rowClass="table-row"
      [loading]="uiState == uiStateForTemplate.ShowLoading"
      (firstDataRendered)="onFirstDataRendered($event)"
      (gridSizeChanged)="gridSizeChanged($event)"
      overlayNoRowsTemplate="<span>this player has no pledges for this game</span>"
      [rowData]="pledges"
      [columnDefs]="pledgesTableColDefs">
    </ag-grid-angular>

  </mat-card-content>
</mat-card>
