@if (uiState == uiStateEnumForTemplate.ShowLoading) {
  <c-container>
    <h1 data-testid="charity-loading-message">Loading your charity. Please wait.</h1>
    <mat-spinner class="center-margin"></mat-spinner>
  </c-container>
} @else {
  @if (activeCharity$ | async; as activeCharity) {
    <!--    Insert warning message here if no stripe connect account is available-->
    <c-container class="mt-5">
      <h1 data-testid="charity-welcome">Welcome to {{ activeCharity.Name }} Admin</h1>
    </c-container>
    <c-container>
      <c-row>
        <c-col md="12" lg="6" class="gy-3">
          <mat-card>
            <mat-card-header>
              <mat-card-title>Charity General Details</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="margin-top-large">
                <div>
                  <div class="report-label">Name</div>
                  <div
                    class="report-value"
                    data-testid="charity-details-name">{{ activeCharity.Name }}
                  </div>
                </div>

                <div>
                  <div class="report-label">Belongs to Category</div>
                  <div
                    class="report-value"
                    data-testid="charity-category-name">{{ activeCharity.CharityCategoryName }}
                  </div>
                </div>

                <div>
                  <div class="report-label">Status</div>
                  <div
                    class="report-value"
                    data-testid="charity-active-status">{{ activeCharity.Active ? 'Active' : 'Deactivated' }}
                  </div>
                </div>

                <div>
                  <div class="report-label">Created On</div>
                  <div
                    class="report-value"
                    data-testid="charity-created-on">{{ activeCharity.CreatedOn | date: 'yyyy-MM-dd h:mm a' }}
                  </div>
                </div>

                <div>
                  <div class="report-label">Total Amount Raised</div>
                  <div
                    class="report-value"
                    data-testid="charity-total-amount-raised">{{ activeCharity.TotalCharityContributions | formatCurrency }}
                  </div>
                </div>

                <div>
                  <div class="report-label">Year to Date Amount Raised</div>
                  <div
                    class="report-value"
                    data-testid="charity-year-to-date-raised">{{ activeCharity.YearToDateContributions | formatCurrency }}
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

        </c-col>
        @if (charitySupportersReport) {

          <c-col md="12" lg="6" class="gy-3">
            <mat-card class="full-height">
              <mat-card-header>
                <mat-card-title>Charity Supporter Details</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="margin-top-large">
                  <div>
                    <div class="report-label">Actively Pledged Supporters</div>
                    <div
                      class="report-value"
                      data-testid="charity-details-active-supporters">{{ charitySupportersReport.ActiveSupporters }}
                    </div>
                  </div>

                  <div>
                    <div class="report-label">Supporters No Longer Pledged</div>
                    <div
                      class="report-value"
                      data-testid="charity-details-inactive-supporters">{{ charitySupportersReport.InactiveSupporters }}
                    </div>
                  </div>

                  <div>
                    <div class="report-label">Supporters on Autoplay</div>
                    <div
                      class="report-value"
                      data-testid="charity-details-autoplay-supporters">{{ charitySupportersReport.AutoplaySupporters }}
                    </div>
                  </div>

                  <div>
                    <div class="report-label">Exclusive Supporters</div>
                    <div
                      class="report-value"
                      data-testid="charity-details-exclusive-supporters">{{ charitySupportersReport.UniqueSupporters }}
                    </div>
                  </div>

                  <div>
                    <div class="report-label">Partial Supporters</div>
                    <div
                      class="report-value"
                      data-testid="charity-details-partial-supporters">{{ charitySupportersReport.PartialSupporters }}
                    </div>
                  </div>

                </div>
              </mat-card-content>
            </mat-card>
          </c-col>
        }
      </c-row>
    </c-container>
    <c-container>
      <c-row>
        <c-col md="12" lg="6" class="gy-3">
          <mat-card>
            <mat-card-header>
              <mat-card-title>Charity Date Range Reporting</mat-card-title>
              <mat-card-subtitle>Choose a date range for your report. Only games with a draw date within provided date
                range
                will be considered in the report
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>

              <form [formGroup]="charityDatesFromGroup" class="mt-3">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Enter a date range</mat-label>
                  <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate placeholder="Start date" formControlName="startDate"
                           data-testid="charity-date-range-report-start-date-input">
                    <input matEndDate placeholder="End date" formControlName="endDate"
                           data-testid="charity-date-range-report-end-date-input">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matIconSuffix [for]="picker"
                                         data-testid="charity-date-range-report-datepicker-toggle"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </form>
              <div class="text-end">
                <button mat-raised-button (click)="onGenerateDateRangeReport()" class="margin-top-med"
                        data-testid="charity-date-range-report-generate-button">Generate Reports
                </button>
              </div>

              @if (charityDateRangeReport && !showDateRangeLoader) {

                <div class="margin-top-large">
                  <div>
                    <div class="report-label">Number of Supporters</div>
                    <div
                      class="report-value"
                      data-testid="charity-date-range-total-supporters">{{ charityDateRangeReport.NumberOfSupporters }}
                    </div>
                  </div>

                  <div>
                    <div class="report-label">Total autoplay sales</div>
                    <div
                      class="report-value"
                      data-testid="charity-date-range-autoplay-sales">{{ charityDateRangeReport.AutoplaySales | formatCurrency }}
                    </div>
                  </div>

                  <div>
                    <div class="report-label">Total manual sales</div>
                    <div
                      class="report-value"
                      data-testid="charity-date-range-manual-sales">{{ charityDateRangeReport.ManualSales | formatCurrency }}
                    </div>
                  </div>
                  <div>
                    <div class="report-label">Total sales</div>
                    <div
                      class="report-value"
                      data-testid="charity-date-range-total-sales">{{ charityDateRangeReport.TotalAmountRaised | formatCurrency }}
                    </div>
                  </div>


                  <hr>
                  <div class="report-label">Purchase Size Breakdown</div>
                  <div>
                    <div class="report-label">Purchase amount</div>
                    <div
                      class="report-label float-right">Number of Purchases
                    </div>
                  </div>

                  @for (breakdown of charityDateRangeReport.PurchaseSizeBreakdown; track breakdown) {
                    <div>
                      <div class="report-label" class="purchase-amount-for-breakdown">{{ breakdown.PurchaseAmount | formatCurrency }}</div>
                      <div
                        class="report-value" class="number-of-purchases-for-breakdown">{{ breakdown.NumberOfPurchases }}
                      </div>
                    </div>
                  }
                </div>
              } @else if (showDateRangeLoader) {
                <mat-spinner class="center-margin"></mat-spinner>
              }
            </mat-card-content>
          </mat-card>

        </c-col>
        <c-col md="12" lg="6" class="gy-3">
          <app-charity-draw-reporting></app-charity-draw-reporting>
        </c-col>
      </c-row>
    </c-container>
  }
}
