<app-dialog-base [showLoadingSpinner]="uiState === uiStateForTemplate.ShowLoading">
  <span dialog-title>Support Request Options</span>

  <ng-container dialog-content>
    <mat-tab-group [selectedIndex]="supportRequest.Assignee === null ? 0 : 1">
      <mat-tab label="Assign Support">
        <form [formGroup]="assigneeForm">
          <mat-form-field appearance="outline" class="full-width margin-top-med">
            <mat-label>Assignee</mat-label>
            <input matInput formControlName="assignee" data-testid="support-request-dialog-assignee-input">
            <mat-hint>Please enter the Id of the admin you wish to assign this request to</mat-hint>
            <mat-error *ngIf="(assigneeControl.dirty || assigneeControl.touched) && assigneeControl.errors">
              Please enter a valid Id
            </mat-error>
          </mat-form-field>
          <button mat-flat-button data-testid="support-request-dialog-assign-to-me-button" (click)="assignToActiveUser()">Assign to me</button>
        </form>
        <div class="text-end">
          <button class="margin-right-small primary-button" data-testid="support-request-dialog-assign-button" mat-button (click)="assign()">Assign</button>
        </div>
      </mat-tab>

      <mat-tab label="Resolve">
        <form [formGroup]="resolveForm">
          <section class="margin-top-med">
            <mat-label>Resolved</mat-label>
            <mat-checkbox formControlName="resolved"
                          (change)="onCheckBoxToggle()"
                          data-testid="support-request-dialog-resolved-checkbox"
                          [checked]="supportRequest.Resolved"></mat-checkbox>
          </section>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Resolution Description</mat-label>
            <textarea formControlName="resolutionDescription"
                      matInput
                      data-testid="support-request-dialog-resolution-description-input"></textarea>
            <mat-hint>Please provide a brief description of the work you did to resolve this error</mat-hint>
            <mat-error *ngIf="(resolutionDescriptionControl.dirty || resolutionDescriptionControl.touched)
            && (resolutionDescriptionControl.errors && resolvedControl.value)">Description is required
            </mat-error>
          </mat-form-field>
        </form>
        <div class="text-end">
          <button class="margin-right-small primary-button"
                  data-testid="support-desk-dialog-resolve-button"
                  mat-button
                  (click)="resolve()">{{ resolvedControl.value ? "Resolve" : "Unresolve" }}</button>
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</app-dialog-base>
