<app-dialog-base [showLoadingSpinner]="uiState == uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Add New Admin</span>

  <ng-container dialog-content>
    @switch (uiState) {
      @case (uiStateEnumForTemplate.ValidateEmail) {
        <p>Please enter the new users email and click validate</p>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Email</mat-label>
          <input
            data-testid="add-admin-email-input"
            matInput
            name="newEmail"
            email
            required
            placeholder="Email"
            #name="ngModel"
            [(ngModel)]="newUserEmail"
          >
        </mat-form-field>
        @if (invalidEmailReason) {
          <div class="display-invalid-reason">{{ invalidEmailReason | displayInvalidEmailReason }}</div>
        }
        <button
          data-testid="add-admin-validate-email-button"
          mat-stroked-button
          [disabled]="!name.valid"
          (click)="validateEmail()">Validate Email
        </button>
      }
      @case (uiStateEnumForTemplate.InputUserData) {
        <form [formGroup]="addAdminForm">
          <p>Please provide the new users general information and they will be sent an email to complete their
            registration.</p>
          <div class="margin-bottom-med">
            New User Email : <span class="bold-text">{{ newUserEmail }}</span>
          </div>
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>First Name</mat-label>
            <input
              data-testid="add-admin-first-name-input"
              formControlName="firstName"
              type="text"
              matInput
              placeholder="First Name">
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Last Name</mat-label>
            <input
              data-testid="add-admin-last-name-input"
              formControlName="lastName"
              type="text"
              matInput
              placeholder="Last Name">
          </mat-form-field>

          @if (adminInviteType === adminInviteTypeForTemplate.GameAdminInvite) {
            <mat-form-field appearance="outline">
              <mat-label>New User Role(s)</mat-label>
              <mat-select
                data-testid="add-admin-user-role-select"
                formControlName="newRoles"
                multiple
                required>
                @for (role of possibleRoles; track role) {
                  <mat-option data-testid="add-admin-user-role-options"
                              [value]="role.Id"
                              [matTooltip]="role.Description"
                              matTooltipPosition="right">
                    {{ role.Name }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          }
          @if (adminInviteType == adminInviteTypeForTemplate.CharityAdminInvite) {
            <mat-form-field appearance="outline">
              <mat-label>Select Charity</mat-label>
              <mat-select
                data-testid="add-admin-charity-select"
                formControlName="charity"
                required>
                @for (charity of charities; track charity) {
                  <mat-option data-testid="add-admin-charities-options"
                              [value]="charity.Id"
                              [matTooltip]="charity.Name"
                              matTooltipPosition="right">
                    {{ charity.Name }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          }
        </form>
      }
      @case (uiStateEnumForTemplate.AddExistingUser) {
        <form [formGroup]="updateExistingAdminForm">
          <p>Looks like the user with email <span class="bold-text">{{ existingAdmin?.Email }}</span> already exists in
            the
            system</p>
          <div>Name: {{ existingAdmin?.FirstName }} {{ existingAdmin?.LastName }}</div>
          <div>Since : {{ existingAdmin?.CreatedOn | date: 'yyyy-MM-dd' }}</div>

          @switch (adminInviteType) {
            @case (adminInviteTypeForTemplate.GameAdminInvite) {
              <p>adding this user as a game admin will not affect any of their existing roles</p>
              <mat-form-field>
                <mat-label>New User Role(s)</mat-label>
                <mat-select formControlName="newRolesForExistingAdmin" multiple required
                            data-testid="update-admin-user-role-select">
                  @for (role of possibleRoles; track role) {
                    <mat-option [value]="role.Id" [matTooltip]="role.Description"
                                matTooltipPosition="right" data-testid="update-admin-user-role-options">
                      {{ role.Name }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            }
            @case (adminInviteTypeForTemplate.CausableInvite) {
              <p>adding this user as a causable admin will override all roles for this user</p>
            }
          }
        </form>
      }
      @case (uiStateEnumForTemplate.ViewVerificationCode) {
        <div>{{ addAdminForm.getRawValue().firstName }} {{ addAdminForm.getRawValue().lastName }} has been sent an
          email verification. Please provide them with the following code so they can complete their authentication
          process:
        </div>
        <div class="code" data-testid="add-admin-verification-code">{{ newUserVerificationCode }}</div>
      }
    }
  </ng-container>

  <ng-container actions>
    @if (uiState == uiStateEnumForTemplate.InputUserData) {
      <button mat-button
              data-testid="add-admin-send-invite-button"
              (click)="sendInvite()"
              [disabled]="!addAdminForm.valid">Send Invite
      </button>
    } @else if (uiState == uiStateEnumForTemplate.AddExistingUser) {
      <button mat-button
              (click)="addExistingUser()"
              [disabled]="!updateExistingAdminForm.valid"
              data-testid="add-new-role-button">Add New Role
      </button>
    }

  </ng-container>
</app-dialog-base>
