import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, Validators} from "@angular/forms";
import {UsersService} from "../../services/users.service";
import {IUpdateAdminPasswordRequest} from "../../interfaces/IUpdateAdminPasswordRequest";
import {OidcService} from "../../services/oidc.service";
import {SnackbarService} from "../../services/snackbar.service";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {IApiErrorResponse} from "../../interfaces/error/IApiErrorResponse";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatIcon} from "@angular/material/icon";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-update-password',
  standalone: true,
  templateUrl: './update-password.component.html',
  imports: [
    MatCardModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInput,
    MatIcon,
    MatButton
  ],
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent {

  public uiStateEnum: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public errorMessages: string[] = [];
  public showOldPassword: boolean = false;
  public showNewPassword: boolean = false;
  public showConfirmPassword: boolean = false;

  public newPasswordControl: FormControl = new FormControl<string>('', [
    Validators.required,
    Validators.maxLength(50),
    Validators.minLength(6),
    Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$')
  ]);

  public confirmNewPasswordControl: FormControl = new FormControl<string>('', [
    Validators.required,
    Validators.pattern(this.newPasswordControl.value)
  ]);

  public oldPasswordControl: FormControl = new FormControl<string>('', [
    Validators.required
  ]);

  public updatePasswordForm: FormGroup = this.formBuilder.group({
      oldPassword: this.oldPasswordControl,
      newPassword: this.newPasswordControl,
      confirmNewPassword: this.confirmNewPasswordControl
    },
    {
      validators: this.confirmedValidator('newPassword', 'confirmNewPassword')
    })

  constructor(private userService: UsersService,
              private formBuilder: FormBuilder,
              private snackBarService: SnackbarService,
              private errorHandlingService: ErrorHandlingService,
              private oidcService: OidcService) {
  }

  updatePassword() {
    this.errorHandlingService.clearErrorMessages();
    if (!this.updatePasswordForm.valid) {
      this.updatePasswordForm.markAllAsTouched()
      return;
    }

    const adminId = this.oidcService.getAdminIdFromClaims();

    if (adminId) {
      this.errorMessages = [];
      this.uiStateEnum = UIStateEnum.ShowLoading;
      const updatePassword: IUpdateAdminPasswordRequest = {
        oldPassword: this.oldPasswordControl.value,
        newPassword: this.newPasswordControl.value,
        adminId: adminId
      }
      this.userService.updateAdminPassword(updatePassword).subscribe({
        next: () => {
          this.uiStateEnum = UIStateEnum.ShowData;
          this.snackBarService.openSuccessfulSnackBar('Password updated successfully!')
        },
        error: (res: IApiErrorResponse) => {
          if (res.Error?.ClientErrorMessages?.length > 0) {
            this.uiStateEnum = UIStateEnum.ShowError;
            this.errorMessages = res.Error.ClientErrorMessages;
            this.errorHandlingService.displayPageLevelErrorMessage(res, res.Error.ClientErrorMessages.toString())
          }
        }
      })
    }
  }

  confirmedValidator(controlName: string, matchingControlName: string): ValidationErrors | null {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors['confirmedValidator']
      ) {
        return null;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({confirmedValidator: true});
        return {confirmedValidator: true};
      } else {
        matchingControl.setErrors(null);
        return null;
      }
    };
  }
}
