<app-dialog-base [showLoadingSpinner]="uiState === uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Add New Licence Number</span>

  <ng-container dialog-content *ngIf="uiState === uiStateEnumForTemplate.ShowData">
    <form [formGroup]="licenceForm">
      <mat-form-field appearance="outline" class="full-width margin-top-small">
        <mat-label>Licence Number</mat-label>
        <input matInput formControlName="licenceNumber" placeholder="enter licence number"
               data-testid="licence-number-input">
        <mat-error *ngIf="licenceNumberControl.errors">Licence Number is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width margin-top-small">
        <mat-label>Start Date</mat-label>
        <input matInput formControlName="startDate" placeholder="enter start date" [matDatepicker]="startDatepicker">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-error *ngIf="startDateControl.errors">Start Date is required</mat-error>
        <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatepicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width margin-top-small">
        <mat-label>End Date</mat-label>
        <input matInput formControlName="endDate" placeholder="enter end date" [matDatepicker]="endDatePicker">
        <mat-hint>Note*: This field is only if you know when the licence number is set to expire</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
    </form>
  </ng-container>

  <ng-container actions>
    <button mat-button
            (click)="addNewLicenceNumber()"
            data-testid="add-licence-number-button"
            [disabled]="uiState === uiStateEnumForTemplate.ShowLoading">
      Send
    </button>
  </ng-container>
</app-dialog-base>
