<div class="one-column-page-container">
  <h2>Auditing Events</h2>
  @if(uiState == uiStateForTemplate.ShowData) {
    <mat-tab-group data-testid="audit-table">
      <mat-tab label="Player Audits" *hasPermission="PermissionTypes.ViewPlayerAudits">
        <app-player-audit-table></app-player-audit-table>
      </mat-tab>
      <mat-tab label="Admin Audits" *hasPermission="PermissionTypes.ViewAdminAudits">
        <app-admin-audit-table></app-admin-audit-table>
      </mat-tab>
      <mat-tab label="System Audits" *hasPermission="PermissionTypes.ViewSystemAudits">
        <app-system-audit-table></app-system-audit-table>
      </mat-tab>
    </mat-tab-group>
  }
</div>
