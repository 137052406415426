@if (uiState === UIStateEnum.ShowLoading) {
  <mat-spinner class="center-margin"></mat-spinner>
} @else if (uiState == UIStateEnum.ShowData) {
  @if (gamePaymentTier) {
    <div>Here is the current payment tier:</div>
    <div class="margin-top-med">{{ gamePaymentTier.NumberOfTickets }} for {{ gamePaymentTier.Price | formatCurrency }}
    </div>
  } @else {
    <div class="margin-top-med">Choose a payment Tier for your game</div>
    <mat-radio-group
      aria-labelledby="available payment tiers to choose from"
      [(ngModel)]="newTierForGame"
      class="radio-group">
      @for (tier of availablePaymentTiers; track tier) {
        <mat-radio-button class="radio-button" [value]="tier" data-testid="payment-tiers-radio">{{ tier.NumberOfTickets }}
          for {{ tier.Price | formatCurrency }}
        </mat-radio-button>
      }
    </mat-radio-group>


    @if (showNoPaymentTierMessage) {
      <mat-error class="margin-bottom-med">You must choose a payment tier</mat-error>
    }
    <div>
      <button mat-stroked-button data-testid="payment-tiers-save-button" (click)="addPaymentTierToGame()">Save</button>
    </div>

    <div>
      <div class="margin-top-med">Don't see a tier you want?</div>
      <button class="margin-top-med" mat-stroked-button data-testid="add-new-tier-button" (click)="addNewTier()">Add New Tier</button>
    </div>
  }
}
