import {inject} from "@angular/core";
import {AppConfigService} from "../services/app-config.service";
import {Router} from "@angular/router";

export const EnvironmentGuard = () => {
  const appConfigService = inject(AppConfigService);
  const router = inject(Router);
  let isDevelopment: boolean = false;

  appConfigService.appConfig$.subscribe(config => {
    if (config.currentEnvironment.toLowerCase() === "development" || config.currentEnvironment.toLowerCase() === "local") {
      isDevelopment = true;
    }
  });

  if (!isDevelopment) {
    return router.navigateByUrl("active-game-instances")
  }

  return true;
}
