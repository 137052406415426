<app-dialog-base [showLoadingSpinner]="uiState == uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Create New Role</span>
  <ng-container dialog-content>
    <form [formGroup]="createRoleForm">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Enter New Role Name</mat-label>
        <input
          formControlName="roleName"
          type="text"
          matInput
          data-testid="new-role-name-input"
          placeholder="enter role name">

        @if(roleNameControl.hasError('required')) {
          <mat-error data-testid="role-name-error">
            name is <span class="bold-text">required</span>
          </mat-error>
        }
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Enter New Role Description</mat-label>
        <input
          formControlName="roleDescription"
          type="text"
          matInput
          data-testid="new-role-description-input"
          placeholder="enter role description">
        @if(roleDescriptionControl.hasError('required')) {
          <mat-error data-testid="role-description-error">
            role description is <span class="bold-text">required</span>
          </mat-error>
        }
      </mat-form-field>
    </form>
  </ng-container>
  <ng-container actions>
    <button
      data-testid="submit-new-role-button"
      mat-button
      (click)="submitNewRole()"
      [disabled]="uiState == uiStateEnumForTemplate.ShowLoading">Create Role
    </button>
  </ng-container>
</app-dialog-base>
