import { Component } from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {MatIcon} from "@angular/material/icon";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-show-played',
  standalone: true,
  templateUrl: './format-boolean.component.html',
  imports: [
    MatIcon,
    NgClass
  ],
  styleUrls: ['./format-boolean.component.css']
})
export class FormatBoolean implements ICellRendererAngularComp {

  public boolean: boolean = false;

  constructor() {
  }

  agInit(params: ICellRendererParams): void {
    this.boolean = params.value;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }
}
