import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'minutesFromMidnightToHours'
})
export class MinutesFromMidnightPipe implements PipeTransform {
  public transform(drawFromMidnight: number): string {
    if (drawFromMidnight) {
      const hour = Math.floor(drawFromMidnight/60);
      const minutes = (drawFromMidnight - (hour * 60));
      return `${this.padToTwoDigits(hour)}:${this.padToTwoDigits(minutes)}`;
    }
    return 'Unknown time of day';
  }

  private padToTwoDigits(numP: number) {
    return numP.toString().padStart(2, '0');
  }
}
