<h2 mat-dialog-title>Add New Payment Tier</h2>
<mat-dialog-content class="mat-typography">

  @if (uiState === uiStateEnumForTemplate.ShowLoading) {
    <mat-spinner></mat-spinner>
  } @else {
    <form [formGroup]="newPaymentTierForm">

      <mat-form-field appearance="outline" class="full-width margin-top-large">
        <mat-label>Price per Group of Tickets ($)</mat-label>
        <input formControlName="price" placeholder="enter the price for the group of tickets" matInput>
        <mat-hint>enter in dollar amount as in 7 for 7$, or 7.50 for 7$ and 50 cents
        </mat-hint>
        <mat-error data-testid="new-payment-tier-price"
                   *ngIf="newPaymentTierForm.controls.price.hasError('required') && (newPaymentTierForm.controls.price.touched || newPaymentTierForm.controls.price.dirty)">
          <strong>price is required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width margin-top-large">
        <mat-label>Number of Tickets in Group</mat-label>
        <input formControlName="numberOfTickets" placeholder="enter the number of tickets in the group" matInput>
        <mat-error data-testid="new-payment-tier-number-of-tickets"
                   *ngIf="newPaymentTierForm.controls.numberOfTickets.hasError('required') && (newPaymentTierForm.controls.numberOfTickets.touched || newPaymentTierForm.controls.numberOfTickets.dirty)">
          <strong>number of must be greater then 0</strong>
        </mat-error>
      </mat-form-field>
    </form>

  }

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false" data-testid="cancel-button">Cancel</button>
  <button mat-button class="primary-button"
          (click)="saveNewPaymentTier()"
          [disabled]="uiState === uiStateEnumForTemplate.ShowRequestProcessing"
          data-testid="make-payment-button">
    @if (uiState === uiStateEnumForTemplate.ShowRequestProcessing) {
      <mat-spinner diameter="32" color="accent"></mat-spinner>
    } @else {
      Create Tier
    }
  </button>
</mat-dialog-actions>
