<div class="one-column-page-container">
  @if (loadInProgressGame) {
    <app-new-game-container [newGameIdSetter]="newGameId"></app-new-game-container>
  } @else if (startNewGame) {
    <app-init-game (newGameWasInitiated)="newGameInitiated()"></app-init-game>
  } @else if (showDraftGames) {
    <h3>Existing Games</h3>
    <div>Looks like there are some games already in Draft. You can choose an existing draft game, or choose to start a
      new one
    </div>
    <div class="margin-top-med margin-bottom-med">
      <button mat-stroked-button (click)="shouldStartNewGame()" data-testid="start-new-game">
        Start a New Game
      </button>
      <div>
        <h3>Existing Games</h3>
        <mat-selection-list #draftGames [multiple]="false">
          @for (draft of availableDraftGames; track draft) {
            <mat-list-option [value]="draft">{{ draft.Name }} : {{ draft.Subdomain }}</mat-list-option>
          }
        </mat-selection-list>
      </div>
      <button mat-stroked-button
              [disabled]="!draftGames.selectedOptions.hasValue()"
              (click)="continueWithDraftGame(draftGames.selectedOptions.selected[0].value)"
              data-testid="continue-existing-draft">
        {{ draftGames.selectedOptions.hasValue() ? 'Continue with ' + draftGames.selectedOptions.selected[0].value.Name : 'Choose Game' }}
      </button>
    </div>
  }
</div>
