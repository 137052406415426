import {Component, inject, OnInit} from '@angular/core';
import {PermissionTypes} from "../../enum/PermissionTypes";
import {HasPermissionDirective} from "../../directives/permissions/has-permission";
import {MatIcon} from "@angular/material/icon";
import {MatButton, MatMiniFabButton} from "@angular/material/button";
import {MatTooltip} from "@angular/material/tooltip";
import {AgGridAngular} from "ag-grid-angular";
import {AsyncPipe, DatePipe} from "@angular/common";
import {ColDef, GridApi, GridReadyEvent, SizeColumnsToFitGridStrategy} from "ag-grid-community";
import {FormatBoolean} from "../../components/cell-renderers/show-played/format-boolean.component";
import {
  TestimonialsSettingsComponent
} from "../../components/testimonials/testimonial-settings/testimonials-settings.component";
import {Observable} from "rxjs";
import {IFrequentlyAskedQuestions} from "../../interfaces/IFrequentlyAskedQuestions";
import {FrequentlyAskedQuestionsService} from "../../services/frequently-asked-questions.service";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {
  FaqTableSettingComponents
} from "../../components/frequently-asked-questions/faq-table-settings/faq-table-setting-components.component";
import {FaqsEditComponent} from "../../components/frequently-asked-questions/faqs-edit/faqs-edit.component";
import {MatDialog} from "@angular/material/dialog";
import {FaqAddComponent} from "../../components/frequently-asked-questions/faq-add/faq-add.component";

@Component({
  selector: 'app-frequently-asked-questions',
  standalone: true,
  imports: [
    HasPermissionDirective,
    MatIcon,
    MatMiniFabButton,
    MatTooltip,
    AgGridAngular,
    AsyncPipe,
    MatButton
  ],
  templateUrl: './frequently-asked-questions.component.html',
  styleUrl: './frequently-asked-questions.component.css'
})
export class FrequentlyAskedQuestionsComponent implements OnInit {

  private datePipe: DatePipe = inject(DatePipe);
  private frequentlyAskedQuestionsService: FrequentlyAskedQuestionsService = inject(FrequentlyAskedQuestionsService);
  private errorHandlingService: ErrorHandlingService = inject(ErrorHandlingService);
  private matDialog: MatDialog = inject(MatDialog);

  protected readonly permissionTypes = PermissionTypes;
  public autoSizeStrategy: SizeColumnsToFitGridStrategy = {
    type: 'fitGridWidth'
  };
  public defaultColDef: ColDef = {
    flex: 1,
    cellDataType: false,
  };

  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  public UIStateEnum = UIStateEnum;

  public faqsGridApi!: GridApi<IFrequentlyAskedQuestions>;
  public selectFaqs$: Observable<IFrequentlyAskedQuestions[]> = this.frequentlyAskedQuestionsService.selectFaqs$();
  public faqDefs: ColDef[] = [
    {
      headerName: 'Id',
      flex: 1,
      field: 'Id',
    },
    {
      headerName: 'Question',
      field: 'Question',
    },
    {
      headerName: 'Answer',
      field: 'Answer'
    },
    {
      headerName: 'Created On Date',
      maxWidth: 140,
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y');
        return convertedDate ? convertedDate : '';
      },
      field: 'CreatedOn'
    },
    {
      headerName: 'Active',
      maxWidth: 80,
      cellRenderer: FormatBoolean,
      field: 'Active',
    },
    {
      maxWidth: 80,
      cellRenderer: FaqTableSettingComponents
    }
  ];

  ngOnInit(): void {
    this.frequentlyAskedQuestionsService.fetchFaqs().subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
      }, error: (err) => {
        this.errorHandlingService.displayPageLevelErrorMessage(err);
        this.uiState = UIStateEnum.ShowData;
      }
    })
  }

  onFAQGridReady(params: GridReadyEvent<IFrequentlyAskedQuestions>) {
    this.faqsGridApi = params.api;
    this.faqsGridApi.sizeColumnsToFit({
      defaultMinWidth: 90,
    });
  }

  onAddFaqClick() {
    this.matDialog.open(FaqAddComponent, {
      width: '650px'
    });
  }
}
