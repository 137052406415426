import {Component, OnDestroy, OnInit} from '@angular/core';
import {UIStateEnum} from "../../enum/UIStateEnum";
import {GameService} from "../../services/game.service";
import {CharityService} from "../../services/charity.service";
import {concatMap, Observable, Subscription, tap} from "rxjs";
import {ICharitiesByCategoryResult} from "../../interfaces/charity/ICharitiesByCategoryResult";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {SnackbarService} from "../../services/snackbar.service";
import {IUpdateCharityCategorySortOrder} from "../../interfaces/charity/IUpdateCharityCategorySortOrder";
import {ICharityCategory} from "../../interfaces/charity/ICharityCategory";
import {CdkDrag, CdkDropList, CdkDropListGroup, moveItemInArray} from "@angular/cdk/drag-drop";
import {CharityScreens} from "../../enum/CharityScreens";
import {PermissionTypes} from "../../enum/PermissionTypes";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {AsyncPipe} from "@angular/common";
import {CharityCategoryViewComponent} from "../../components/charity-category-view/charity-category-view.component";
import {CharityViewComponent} from "../../components/charity-view/charity-view.component";
import {MatButton} from "@angular/material/button";
import {MatTooltip} from "@angular/material/tooltip";
import {MatIcon} from "@angular/material/icon";
import {HasPermissionDirective} from "../../directives/permissions/has-permission";

@Component({
  selector: 'app-manage-charities',
  standalone: true,
  templateUrl: './charity-management.component.html',
  imports: [
    MatProgressSpinner,
    AsyncPipe,
    CharityCategoryViewComponent,
    CharityViewComponent,
    CdkDropListGroup,
    MatButton,
    MatTooltip,
    CdkDropList,
    MatIcon,
    CdkDrag,
    HasPermissionDirective
  ],
  styleUrls: ['./charity-management.component.scss']
})
export class CharityManagementComponent implements OnInit, OnDestroy {
  public PermissionTypes = PermissionTypes;
  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  public uiStateEnumForTemplate = UIStateEnum;
  public charityCategoriesForSorting: ICharitiesByCategoryResult[] = [];
  public enableSortCharityCategory: boolean = false;
  public activeGame = this.gameService.activeGame();
  public charityScreens = CharityScreens;
  public activeCharityScreen$: Observable<CharityScreens> = this.charityService.activeCharityScreen$;
  public charityCategories$: Observable<ICharitiesByCategoryResult[]> = this.charityService.charityCategories$.pipe(tap((categoriesP) => {
    this.charityCategoriesForSorting = categoriesP.sort((a, b) => a.Category.SortOrder - b.Category.SortOrder);
  }));
  public charitySubscription: Subscription | null = null;

  constructor(private charityService: CharityService,
              private gameService: GameService,
              private errorHandlingService: ErrorHandlingService,
              private snackBarService: SnackbarService) {
  }

  ngOnDestroy(): void {
    if (this.charitySubscription) {
      this.charitySubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.fetchCharities();
  }

  public updateSortingCategories() {
    this.enableSortCharityCategory = !this.enableSortCharityCategory;
    this.charityCategoriesForSorting = this.charityCategoriesForSorting
      .sort((a, b) => a.Category.SortOrder - b.Category.SortOrder);
  }

  public sortCategoriesAlphabetically() {
    this.charityCategoriesForSorting = this.charityCategoriesForSorting
      .sort((a, b) => a.Category.Name.trim().localeCompare(b.Category.Name));
    for (let i = 0; i < this.charityCategoriesForSorting.length; i++) {
      this.charityCategoriesForSorting[i].Category.SortOrder = i;
    }
  }

  submitCategoryChanges() {
    let updatedCharities = this.updateSortOrderForCategoriesList();
    let updateCharityCategorySort: IUpdateCharityCategorySortOrder = {
      charityCategories: updatedCharities,
      gameId: this.activeGame?.Id!
    };

    this.uiState = UIStateEnum.ShowLoading;

    this.charityService.updateCharityCategorySortOrder(updateCharityCategorySort).subscribe({
        next: () => {
          this.enableSortCharityCategory = false;
          this.uiState = UIStateEnum.ShowData;
          this.snackBarService.openSuccessfulSnackBar('Successfully Updated Category Sorting');
        },
        error: err => {
          this.uiState = UIStateEnum.ShowData;
          this.errorHandlingService.displayPageLevelErrorMessage(err);
        }
      }
    )
  }

  addNewCharities() {
    this.charityService.openAddCharityDialog(this.activeGame.Id);
  }

  updateSortOrderForCategoriesList(): ICharityCategory[] {
    return this.charityCategoriesForSorting.map((val, index) => {
      val.Category.SortOrder = index + 1;
      return val.Category;
    });
  }

  dropCategory(event: any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  public viewCharities(categoryP: ICharitiesByCategoryResult) {
    this.charityService.setActiveCharityCategory(categoryP.CategoryId);
  }

  private fetchCharities() {
    this.charitySubscription = this.charityService.fetchCharitiesPerGameByCategory(this.activeGame?.Id!)
      .pipe(concatMap(() => {
        return this.charityCategories$;
      })).subscribe({
        next: () => {
          this.uiState = UIStateEnum.ShowData;
        },
        error: err => {
          this.uiState = UIStateEnum.ShowData;
          this.errorHandlingService.displayPageLevelErrorMessage(err);
        }
      });
  }
}
