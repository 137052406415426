import { Component } from '@angular/core';
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";
import {MatDivider} from "@angular/material/divider";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatTooltip} from "@angular/material/tooltip";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {IAdminQueryResult} from "../../../interfaces/IAdminQueryResult";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {GameService} from "../../../services/game.service";
import {Router} from "@angular/router";
import {UsersService} from "../../../services/users.service";
import {ICellRendererParams} from "ag-grid-community";

@Component({
  selector: 'app-charity-admin-settings',
  standalone: true,
  imports: [
    HasPermissionDirective,
    MatDivider,
    MatIcon,
    MatIconButton,
    MatMenu,
    MatMenuItem,
    MatTooltip,
    MatMenuTrigger
  ],
  templateUrl: './charity-admin-settings.component.html',
  styleUrl: './charity-admin-settings.component.scss'
})
export class CharityAdminSettingsComponent  implements ICellRendererAngularComp {

  public adminUser!: IAdminQueryResult;
  public permissionTypes = PermissionTypes;

  constructor(private gameService: GameService,
              private router: Router,
              private userService: UsersService) {
  }

  agInit(params: ICellRendererParams): void {
    this.adminUser = params.data;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  onDeactivateUserClick() {
    this.userService.onDeactivateUserClick(this.adminUser);
  }

  onViewDetailsClick() {
    let activeGame = this.gameService.activeGame().Id;
    this.router.navigateByUrl(`${activeGame}/user-details/${this.adminUser.Id}`);
  }

  onReactiveUserClick() {
    this.userService.onReactivateUserClick(this.adminUser);
  }
}
