import {inject, Injectable} from "@angular/core";
import {createStore, select, setProp, setProps, withProps} from "@ngneat/elf";
import {ICompleteNewGameRequest} from "../interfaces/new-game/ICompleteNewGameRequest";
import {localStorageStrategy, persistState} from "@ngneat/elf-persist-state";
import {IGameGeneralSettings} from "../interfaces/new-game/breakdown/IGameGeneralSettings";
import {IGameAssetSettings} from "../interfaces/new-game/breakdown/IGameAssetSettings";
import {APIService} from "./api.service";
import {IGamePaymentSettings} from "../interfaces/new-game/breakdown/IGamePaymentSettings";
import {IGameMetadataSettings} from "../interfaces/new-game/breakdown/IGameMetadataSettings";
import {IGameDrawSettings} from "../interfaces/new-game/breakdown/IGameDrawSettings";
import {IInitNewGame} from "../interfaces/new-game/breakdown/IInitNewGame";
import {concatMap, map, Observable, tap} from "rxjs";
import {IInitNewGameResponse} from "../interfaces/new-game/IInitNewGameResponse";
import {IGameBase} from "../interfaces/new-game/breakdown/IGameBase";
import {GameStateEnum} from "../enum/GameStateEnum";
import {IFinalizeNewGame} from "../interfaces/IFinalizeNewGame";
import {IGameQueryResult} from "../interfaces/IGameQueryResult";
import {GameService} from "./game.service";
import {IUploadImageToAzure} from "../interfaces/IUploadImageToAzure";
import {ISaveDraftGameSettings} from "../interfaces/ISaveDraftGameSettings";

@Injectable({
  providedIn: "root"
})
export class NewGameService {
  private apiService: APIService = inject(APIService);
  private gameService: GameService = inject(GameService);

  private defaultNewGame: ICompleteNewGameRequest = {
    newBaseGameSettings: null,
    newGameAssets: null,
    newGameGeneral: null,
    newGameInProgress: false,
    newGameDraw: null,
    newGameMetadata: null,
    newGamePayment: null
  }

  private newGameStore = createStore(
    {name: 'new-game-store'},
    withProps<ICompleteNewGameRequest>(this.defaultNewGame));

  private persist = persistState(this.newGameStore, {
    key: 'auth',
    storage: localStorageStrategy
  });

  public getNewGameRequest(): ICompleteNewGameRequest | null {
    return this.newGameStore.query(state => state);
  }

  public selectNewGameRequest(): Observable<ICompleteNewGameRequest> {
    return this.newGameStore.pipe(select((state) => state));
  }

  public saveNewGameGeneral(newGameSettingsP: IGameGeneralSettings) {
    this.newGameStore.update(setProp('newGameGeneral', newGameSettingsP));
  }

  public saveNewGameDraw(newGameSettingsP: IGameDrawSettings) {
    this.newGameStore.update(setProp('newGameDraw', newGameSettingsP));
  }

  public saveNewGamePayment(newGameSettingsP: IGamePaymentSettings) {
    this.newGameStore.update(setProp('newGamePayment', newGameSettingsP));
  }

  public saveNewGameMeta(newGameSettingsP: IGameMetadataSettings) {
    this.newGameStore.update(setProp('newGameMetadata', newGameSettingsP));
  }

  public saveNewGameAssets(newGameAssetsP: IGameAssetSettings) {
    this.newGameStore.update(setProp('newGameAssets', newGameAssetsP));
  }

  public clearGameInProgress() {
    this.newGameStore.update(() => ({
      newBaseGameSettings: null,
      newGameAssets: null,
      newGameGeneral: null,
      newGameInProgress: false,
      newGameDraw: null,
      newGameMetadata: null,
      newGamePayment: null
    }));
  }

  public newGameInProgress(): boolean {
    return this.newGameStore.query(state => state.newGameInProgress);
  }

  public newGameId(): string | undefined {
    return this.newGameStore.query(state => state.newBaseGameSettings?.id);
  }

  public newGameSubdomain(): string | undefined {
    return this.newGameStore.query(state => state.newBaseGameSettings?.subdomain);
  }

  public initNewGame(initNewGameP: IInitNewGame, activeUserIdP: string): Observable<IInitNewGameResponse> {
    return this.apiService.MakePostRequest<IInitNewGameResponse>('game/init-new-game', initNewGameP)
      .pipe(
        tap((initGameResP) => {
          let baseGameSettings: IGameBase = {
            name: initNewGameP.name,
            id: initGameResP.NewGameId,
            subdomain: initNewGameP.subdomain
          }
          this.newGameStore.update(setProp('newGameInProgress', true));
          this.newGameStore.update(setProp("newBaseGameSettings", baseGameSettings));
        }),
        concatMap((initGameResP) => {
          return this.gameService.getGamesForUser(activeUserIdP).pipe(map(() => initGameResP));
        }));
  }

  public mapToCompleteNewGameRequest(queryResult: IGameQueryResult) {
    const gameForState: ICompleteNewGameRequest = {
      newBaseGameSettings: {
        id: queryResult.Id,
        name: queryResult.Name,
        subdomain: queryResult.Subdomain
      },
      newGameGeneral: {
        type: queryResult.Type,
        autoplayEnabled: queryResult.AutoplayEnabled,
        contactEmail: queryResult.ContactEmail,
        allowFreeTickets: queryResult.AllowFreeTickets,
        collectPlayerAddress: queryResult.CollectPlayerAddress,
        dateOfFinalInstance: queryResult.DateOfFinalInstance,
        dateOfFirstInstance: queryResult.DateOfFirstInstance,
        displayNonWinnerName: queryResult.DisplayNonWinnerName,
        licenceNumber: queryResult.LicenceNumber,
        gameDescription: queryResult.GameDescription,
        maxReceiptAttempts: queryResult.MaxReceiptAttempts,
        pushNotificationMessageFeeCost: queryResult.PushNotificationMessageFeeCost,
        regionLockPlayerGeolocation: queryResult.RegionLockPlayerGeolocation,
        regionLockPlayerPostalCode: queryResult.RegionLockPlayerGeolocation,
        senderEmail: queryResult.SenderEmail,
        timezone: queryResult.Timezone,
        loginMoreInfoUrl: queryResult.LoginMoreInfoUrl,
        twilioSid: queryResult.TwilioSid,
        randomApiOverrideId: queryResult.RandomApiOverrideId,
        twilioPhoneNumber: queryResult.TwilioPhoneNumber,
        twilioAuthToken: queryResult.TwilioAuthToken,
        maxPledgesPerPlayer: queryResult.MaxPledgesPerPlayer,
        shortName: queryResult.ShortName,
        gameMoreInformation: queryResult.GameMoreInformation,
        minimumAgeOfParticipation: queryResult.MinimumAgeOfParticipation
      },
      newGameAssets: {
        faqUrl: queryResult.FaqUrl,
        primaryGameColor: queryResult.PrimaryGameColor,
        gameThemeTypeId: queryResult.GameThemeTypeId
      },
      newGamePayment: {
        causableFeeMultiplier: queryResult.CausableFeeMultiplier,
        taxMultiplier: queryResult.TaxMultiplier,
        administrationCommissionMultiplier: queryResult.AdministrationCommissionMultiplier,
        stripePublicKey: queryResult.StripePublicKey,
        deductTaxesFromCharityContribution: queryResult.DeductTaxesFromCharityContribution,
      },
      newGameMetadata: {
        metadataDescription: queryResult.MetadataDescription,
        metadataImageUrl: queryResult.MetadataImageUrl,
        metadataSiteName: queryResult.MetadataSiteName,
        metadataTitle: queryResult.MetadataTitle,
        metadataUrl: queryResult.MetadataUrl,
        metadataFaviconUrl: queryResult.MetadataFaviconUrl,
        metadataType: queryResult.MetadataType
      },
      newGameDraw: {
        instanceIntervalType: queryResult.InstanceIntervalType,
        drawTimeMinutesFromMidnightLocal: queryResult.DrawTimeMinutesFromMidnightLocal,
        maxTicketsPerDraw: queryResult.MaxTicketsPerDraw,
        maxAmountPlayersCanSpendPerDraw: queryResult.MaxAmountPlayersCanSpendPerDraw,
        minimumGameLengthInHours: queryResult.MinimumGameLengthInHours,
        notificationsStartMinutesFromMidnightLocal: queryResult.NotificationsStartMinutesFromMidnightLocal,
        manualDraw: queryResult.ManualDraw,
        drawDayOfWeek: queryResult.DrawDayOfWeek
      },
      newGameInProgress: true
    };
    this.newGameStore.update(setProps({
      newBaseGameSettings: gameForState.newBaseGameSettings,
      newGameInProgress: gameForState.newGameInProgress,
      newGamePayment: gameForState.newGamePayment,
      newGameGeneral: gameForState.newGameGeneral,
      newGameMetadata: gameForState.newGameMetadata,
      newGameAssets: gameForState.newGameAssets,
      newGameDraw: gameForState.newGameDraw
    }));
  }

  public mapGameToRequest(): ISaveDraftGameSettings | null {
    const completeRequestP = this.getNewGameRequest();
    if (!completeRequestP) {
      return null;
    }

    return {
      Id: completeRequestP.newBaseGameSettings?.id || '',
      Name: completeRequestP.newBaseGameSettings?.name || '',
      Subdomain: completeRequestP.newBaseGameSettings?.subdomain || '',
      Type: completeRequestP.newGameGeneral?.type || 0,
      AutoplayEnabled: completeRequestP.newGameGeneral?.autoplayEnabled || false,
      State: GameStateEnum.Draft,
      CollectPlayerAddress: completeRequestP.newGameGeneral?.collectPlayerAddress || false,
      RegionLockPlayerGeolocation: completeRequestP.newGameGeneral?.regionLockPlayerGeolocation || false,
      RegionLockPlayerPostalCode: completeRequestP.newGameGeneral?.regionLockPlayerPostalCode || false,
      Timezone: completeRequestP.newGameGeneral?.timezone || '',
      LicenceNumber: completeRequestP.newGameGeneral?.licenceNumber || '',
      DateOfFirstInstance: completeRequestP.newGameGeneral?.dateOfFirstInstance || null,
      DateOfFinalInstance: completeRequestP.newGameGeneral?.dateOfFinalInstance || null,
      ManualDraw: completeRequestP.newGameDraw?.manualDraw || false,
      DrawDayOfWeek: completeRequestP.newGameDraw?.drawDayOfWeek || 0,
      DrawTimeMinutesFromMidnightLocal: completeRequestP.newGameDraw?.drawTimeMinutesFromMidnightLocal || 0,
      NotificationsStartMinutesFromMidnightLocal: completeRequestP.newGameDraw?.notificationsStartMinutesFromMidnightLocal || 0,
      InstanceIntervalType: completeRequestP.newGameDraw?.instanceIntervalType || 0,
      MaxTicketsPerDraw: completeRequestP.newGameDraw?.maxTicketsPerDraw || 0,
      ContactEmail: completeRequestP.newGameGeneral?.contactEmail || '',
      SenderEmail: completeRequestP.newGameGeneral?.senderEmail || '',
      StripePublicKey: completeRequestP.newGamePayment?.stripePublicKey || '',
      FaqUrl: completeRequestP.newGameAssets?.faqUrl || '',
      GameDescription: completeRequestP.newGameGeneral?.gameDescription || '',
      DisplayNonWinnerName: completeRequestP.newGameGeneral?.displayNonWinnerName || false,
      AllowFreeTickets: completeRequestP.newGameGeneral?.allowFreeTickets || false,
      MaxAmountPlayersCanSpendPerDraw: completeRequestP.newGameDraw?.maxAmountPlayersCanSpendPerDraw || 0,
      MetadataDescription: completeRequestP.newGameMetadata?.metadataDescription || '',
      MetadataImageUrl: completeRequestP.newGameMetadata?.metadataImageUrl || '',
      MetadataSiteName: completeRequestP.newGameMetadata?.metadataSiteName || '',
      MetadataTitle: completeRequestP.newGameMetadata?.metadataTitle || '',
      MetadataUrl: completeRequestP.newGameMetadata?.metadataUrl || '',
      TaxMultiplier: completeRequestP?.newGamePayment?.taxMultiplier || 0,
      CausableFeeMultiplier: completeRequestP?.newGamePayment?.causableFeeMultiplier || 0,
      AdministrationCommissionMultiplier: completeRequestP?.newGamePayment?.administrationCommissionMultiplier || 0,
      MaxReceiptAttempts: completeRequestP?.newGameGeneral?.maxReceiptAttempts || 0,
      MinimumGameLengthInHours: completeRequestP?.newGameDraw?.minimumGameLengthInHours || 0,
      PushNotificationMessageFeeCost: completeRequestP?.newGameGeneral?.pushNotificationMessageFeeCost || 0,
      DeductTaxesFromCharityContribution: completeRequestP?.newGamePayment!.deductTaxesFromCharityContribution ? completeRequestP?.newGamePayment.deductTaxesFromCharityContribution : false,
      RandomApiOverrideId: completeRequestP?.newGameGeneral?.randomApiOverrideId || '',
      TwilioAuthToken: completeRequestP?.newGameGeneral?.twilioAuthToken || '',
      TwilioPhoneNumber: completeRequestP?.newGameGeneral?.twilioPhoneNumber || '',
      TwilioSid: completeRequestP?.newGameGeneral?.twilioSid || '',
      LoginMoreInfoUrl: completeRequestP?.newGameGeneral?.loginMoreInfoUrl || '',
      PaymentTiers: [],
      PrimaryGameColor: completeRequestP?.newGameAssets?.primaryGameColor || '',
      GameThemeTypeId: completeRequestP?.newGameAssets?.gameThemeTypeId || 0,
      MaxPledgesPerPlayer: completeRequestP?.newGameGeneral?.maxPledgesPerPlayer || 0,
      MetadataFaviconUrl: completeRequestP?.newGameMetadata?.metadataFaviconUrl || '',
      MetadataType: completeRequestP?.newGameMetadata?.metadataFaviconUrl || '',
      ShortName: completeRequestP?.newGameGeneral?.shortName || '',
      GameId: completeRequestP.newBaseGameSettings?.id || '',
      MinimumAgeOfParticipation: completeRequestP?.newGameGeneral?.minimumAgeOfParticipation || 19,
      GameMoreInformation: completeRequestP?.newGameGeneral?.gameMoreInformation || ''
    };
  }

  public saveGameToApi(draftForAPI: ISaveDraftGameSettings) {
    return this.apiService.MakePutRequest<any>('game/save-draft-game', draftForAPI);
  }

  public uploadGameImage(uploadLogoRequestP: IUploadImageToAzure) {
    return this.apiService.uploadFile(`game/upload-cdn-image/${uploadLogoRequestP.gameId}/${uploadLogoRequestP.adminId}`, uploadLogoRequestP.image);
  }

  public completeGameAndValidate() {

    const finalizeNewGame: IFinalizeNewGame = {
      GameId: this.newGameId() ?? ''
    }
    return this.apiService.MakePutRequest<any>('game/finalize-draft-game', finalizeNewGame);
  }
}
