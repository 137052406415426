<div class="app-container">
  <mat-sidenav-container class="sidenav-container">

    <mat-sidenav mode="side" [opened]="(sideNavState$ | async)" style="background-color: #222222;">
      <app-side-nav></app-side-nav>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="main-content">
        <app-game-banner></app-game-banner>
        <div>
          <app-page-display-error></app-page-display-error>
          <router-outlet></router-outlet>
          <app-component-level-error-message [errorMessage]="errorMessage"></app-component-level-error-message>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

