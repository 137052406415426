<app-dialog-base [showLoadingSpinner]="uiState === uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>{{suspension.Resolved ? "Are you sure you want to unresolve this suspension?" : "Are you sure you want to resolve this suspension?"}}</span>

  <ng-container actions *ngIf="uiState === uiStateEnumForTemplate.ShowData">
    <button mat-button
            data-testid="confirm-resolve-suspension-button"
            (click)="resolveSuspension()">
      {{suspension.Resolved ? "Unresolve" : "Resolve"}}
    </button>
  </ng-container>
</app-dialog-base>
