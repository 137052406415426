import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IGameInstancePlayerNumberWithPayment} from "../../../interfaces/IGameInstancePlayerNumberWithPayment";
import {Router} from "@angular/router";
import {GameService} from "../../../services/game.service";
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {MatIcon} from "@angular/material/icon";
import {CdkCopyToClipboard} from "@angular/cdk/clipboard";
import {DatePipe} from "@angular/common";
import {PipesModule} from "../../../pipes/pipes.module";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-view-player-payment',
  standalone: true,
  templateUrl: './view-player-payment.component.html',
  imports: [
    DialogBaseComponent,
    MatIcon,
    CdkCopyToClipboard,
    DatePipe,
    PipesModule,
    MatTooltip
  ],
  styleUrls: ['./view-player-payment.component.scss']
})
export class ViewPlayerPaymentComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public gameData: IGameInstancePlayerNumberWithPayment,
              private router: Router,
              private matDialogRef: MatDialogRef<ViewPlayerPaymentComponent>,
              private gameService: GameService) {
  }

  openPlayer(playerIdP: string) {
    let activeGame = this.gameService.activeGame().Id;
    this.router.navigateByUrl(`${activeGame}/manage-player/${playerIdP}`).then(() => {
      this.matDialogRef.close();
    });
  }
}
