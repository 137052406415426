<app-dialog-base [showLoadingSpinner]="uiState == uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Edit - {{ activeCategory?.Name }}</span>

  <ng-container dialog-content>
    @if (uiState === uiStateEnumForTemplate.ShowData) {
      <form [formGroup]="updateCharityCategoryForm">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Category Name</mat-label>
          <input data-testid="edit-charity-category-name-input" formControlName="categoryName" type="text" matInput
                 placeholder="enter charity name" required>
          @if (categoryNameControl.hasError('required')) {
            <mat-error data-testid="edit-charity-category-name-error">
              charity name is required
            </mat-error>
          }
          <mat-hint>this will be the display name for the charity category</mat-hint>
        </mat-form-field>
      </form>
    }
  </ng-container>

  <ng-container actions>
    <button
      mat-button
      data-testid="save-update-charity-category"
      (click)="updateCharityCategory()"
      [disabled]="uiState === uiStateEnumForTemplate.ShowLoading">Edit
    </button>
  </ng-container>
</app-dialog-base>
