import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {PermissionTypes} from "../../enum/PermissionTypes";
import {NewGameService} from "../../services/new-game.service";
import {MatStep, MatStepLabel, MatStepper} from "@angular/material/stepper";
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {GameService} from "../../services/game.service";
import {GameStateEnum} from "../../enum/GameStateEnum";
import {MatListOption, MatSelectionList} from "@angular/material/list";
import {MatButton, MatFabButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {AsyncPipe} from "@angular/common";
import {InitGameComponent} from "../../components/new-game/init-game/init-game.component";
import {
  GeneralGameSettingsForm
} from "../../components/new-game/general-game-settings-form/general-game-settings-form.component";
import {
  GameDrawSettingsComponentForm
} from "../../components/new-game/game-draw-settings-form/game-draw-settings-component-form.component";
import {
  GamePaymentSettingsFormComponent
} from "../../components/new-game/game-payment-settings-form/game-payment-settings-form.component";
import {
  GameMetadataSettingsFormComponent
} from "../../components/new-game/game-metadata-settings-form/game-metadata-settings-form.component";
import {
  GameAssetSettingsFormComponent
} from "../../components/new-game/game-asset-settings-form/game-asset-settings-form.component";
import {NewGameContainerComponent} from "../../components/new-game/new-game-container/new-game-container.component";
import {CharityService} from "../../services/charity.service";

@Component({
  selector: 'app-new-game',
  templateUrl: './new-game.component.html',
  standalone: true,
  imports: [
    MatSelectionList,
    MatListOption,
    MatButton,
    MatStep,
    MatStepper,
    MatIcon,
    MatFabButton,
    AsyncPipe,
    InitGameComponent,
    GeneralGameSettingsForm,
    GameDrawSettingsComponentForm,
    GamePaymentSettingsFormComponent,
    GameMetadataSettingsFormComponent,
    GameAssetSettingsFormComponent,
    MatStepLabel,
    NewGameContainerComponent
  ],
  styleUrl: './new-game.component.scss'
})
export class NewGameComponent implements OnInit {
  @ViewChild('stepper')
  public stepper!: MatStepper;

  private newGameService: NewGameService = inject(NewGameService);
  private gameService: GameService = inject(GameService);
  private charityService: CharityService = inject(CharityService);

  public loadInProgressGame: boolean = false;
  public startNewGame: boolean = false;
  public availableDraftGames: IGameQueryResult[] = this.gameService.getAllAvailableGames().filter((game) => game.State == GameStateEnum.Draft);
  public showDraftGames = false;
  public newGameId: string = '';

  protected readonly permissionTypes = PermissionTypes;

  ngOnInit(): void {
    this.gameService.clearActiveGame();
    this.charityService.clearCharityStore();
    const areDraftGames = this.availableDraftGames.length > 0;

    if (!areDraftGames) {
      this.startNewGame = true;
    } else {
      this.showDraftGames = true;
    }
  }

  public newGameInitiated() {
    const recentlyCreatedStoredGameId = this.newGameService.newGameId();
    if (recentlyCreatedStoredGameId) {
      this.newGameId = recentlyCreatedStoredGameId;
      this.startNewGame = false;
      this.showDraftGames = false;
      this.loadInProgressGame = true;
    }
  }

  public continueWithDraftGame(gameP: IGameQueryResult) {
    this.newGameId = gameP.Id;
    this.newGameService.clearGameInProgress();
    this.newGameService.mapToCompleteNewGameRequest(gameP);
    this.loadInProgressGame = true;
  }

  public shouldStartNewGame() {
    this.newGameService.clearGameInProgress();
    this.startNewGame = true;
  }
}
