import {inject} from "@angular/core";
import {OidcService} from "../services/oidc.service";
import {Router} from "@angular/router";

export const LoginGuard = () => {
  const oidcService = inject(OidcService);
  const router = inject(Router);
  const is_authenticated = oidcService.isAuthenticated();

  if (is_authenticated) {
    return router.navigateByUrl('');
  }

  return true;
};
