<app-dialog-base [showLoadingSpinner]="uiState == uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Add Permission to {{ role.Role.Name }}</span>
  <ng-container dialog-content>
    <div autofocus [tabindex]="0">Current Permissions</div>
    <mat-chip-listbox aria-label="current permission for role">
      @for (permission of role.Permissions; track permission) {
        <mat-chip
          data-testid="assigning-roles-current-role-chip">
          {{ permission.Name }}
        </mat-chip>
      }
    </mat-chip-listbox>
    @if (availablePermissions.length > 0) {
      <p>Select permissions to add to this role. You may add multiple permissions at a time.</p>
      <form [formGroup]="newPermissionsFormGroup">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Select Permissions</mat-label>
          <mat-chip-grid #chipGrid aria-label="Fruit selection">
            @for (role of permissionsToAdd; track role) {
              <mat-chip-row
                data-testid="assigning-roles-role-to-add-chip"
                (removed)="remove(role)">
                {{ role.Name }}
                <button matChipRemove [attr.aria-label]="'remove ' + role" data-testid="remove-chip">
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
            }
          </mat-chip-grid>
          <input #permissionInput [formControl]="newPermissionsFormControl"
                 data-testid="assigning-roles-select-roles-input"
                 placeholder="start typing or select from list...."
                 [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
                 (matChipInputTokenEnd)="add($event)"/>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            @for (permission of filteredAvailablePermissions | async; track permission) {
              <mat-option
                data-testid="assigning-roles-select-options"
                [value]="permission">
                {{ permission.Name }}
              </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
        @if (permissionsToAdd.length === 0 && (newPermissionsFormControl.touched || newPermissionsFormControl.dirty)) {
          <mat-error data-testid="permission-error">
            You must choose at least one permission to add
          </mat-error>
        }

      </form>
    } @else {
      <p>Looks like this role has all available permissions</p>
    }

    @if (newPermissionsFormControl.value) {
      <div>New Permissions:
        <span class="bold-text">{{ newPermissionsFormControl.value }}</span>
      </div>
    }



  </ng-container>
  <ng-container actions>
    <button
      mat-button
      data-testid="save-new-charities-button"
      color="primary"
      [disabled]="uiState == uiStateEnumForTemplate.ShowLoading"
      (click)="addPermissions()">Save
    </button>
  </ng-container>
</app-dialog-base>
