<div class="text-center" *hasPermission="permissionTypes.ModifyAdminUsers">
  <button mat-icon-button [matMenuTriggerFor]="menu"
          data-testid="open-admin-user-menu-button">
    <mat-icon matTooltipPosition="right"
              matTooltip="open admin user menu"
    >more_horiz
    </mat-icon>
  </button>
</div>

<mat-menu #menu="matMenu">
  @if (rowData.Active) {
    <button mat-menu-item
            (click)="onDeactivateUserClick()"
            data-testid="deactivate-user-button">
      <mat-icon>do_disturb</mat-icon>
      <span>Deactivate</span>
    </button>
  } @else {
    <button mat-menu-item
            (click)="onReactiveUserClick()"
            data-testid="reactivate-user-button">
      <mat-icon>refresh</mat-icon>
      <span>Reactivate</span>
    </button>
  }
</mat-menu>
