<div class="one-column-page-container">
  <div class="flex-row justify-space-between align-center mb-3">
    <h2>Frequently Asked Questions</h2>
    <button mat-raised-button
            data-testid="add-new-frequently-asked-question"
            *hasPermission="permissionTypes.ModifyFrequentlyAskedQuestions"
            color="primary"
            (click)="onAddFaqClick()"
            matTooltip="add new FAQ"
            matTooltipShowDelay="500"> Add
      <mat-icon>add_circle</mat-icon>
    </button>
  </div>
  <ag-grid-angular
    class="ag-theme-quartz"
    data-testid="faqs-table"
    rowClass="faq-row"
    [tooltipShowDelay]=0
    [autoSizeStrategy]="autoSizeStrategy"
    [domLayout]="'autoHeight'"
    [rowHeight]="50"
    [loading]="uiState == UIStateEnum.ShowLoading"
    [defaultColDef]="defaultColDef"
    enableCellTextSelection
    overlayNoRowsTemplate="<span>No FAQs</span>"
    (gridReady)="onFAQGridReady($event)"
    [rowData]="selectFaqs$ | async"
    [columnDefs]="faqDefs">
  </ag-grid-angular>

</div>
