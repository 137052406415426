/*
* This pipe exists to replace the currency pipe built into Angular 2.
* The reason we use this is because the current Angular 2 currency pipe
* does not function in Safari. A better solution may be implemented down
* the line.
*/

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'formatCurrency'
})
export class FormatCurrencyPipe implements PipeTransform
{
  public transform(currencyP: number): string {
    if (currencyP !== null && !isNaN(currencyP)) {
      const formattedCurrency = (currencyP / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      return '$' + formattedCurrency;
    } else {
      return '$0.00';
    }
  }
}
