import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class SideNavService {

  public sideNavState$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {
  }

  public toggleDrawer() {
    this.sideNavState$.next(!this.sideNavState$.value);
  }
}
