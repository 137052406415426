@if(entityId) {
  <mat-icon [matTooltip]="'click to copy'" [cdkCopyToClipboard]="entityId" class="copy-icon">content_copy</mat-icon>
  <span
    [matTooltip]="'view ' + entityId + ' details'"
    matTooltipPosition="above"
    class="href-text"
    (click)="viewEntity()"
  >{{entityId}}</span>
}


