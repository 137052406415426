import {Component, OnInit} from '@angular/core';
import {UIStateEnum} from "../../enum/UIStateEnum";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ColDef, GridApi, GridReadyEvent} from "ag-grid-community";
import {GameService} from "../../services/game.service";
import {IPlayer} from "../../interfaces/player/IPlayer";
import {PlayersService} from "../../services/players.service";
import {IPlayerSearchParams} from "../../interfaces/player/IPlayerSearchParams";
import {ActivatedRoute, Router} from "@angular/router";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {PhoneFormatPipe} from "../../pipes/phone-format.pipe";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatTooltip} from "@angular/material/tooltip";
import {MatButton} from "@angular/material/button";
import {MatCard} from "@angular/material/card";
import {AgGridAngular} from "ag-grid-angular";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-player-search',
  standalone: true,
  templateUrl: './player-search.component.html',
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInput,
    MatTooltip,
    MatIcon,
    MatButton,
    MatCard,
    AgGridAngular
  ],
  styleUrls: ['./player-search.component.scss']
})
export class PlayerSearchComponent implements OnInit {

  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  public uiStateEnumForTemplate = UIStateEnum;
  public phoneNumberControl: FormControl<string | null> = new FormControl<string | null>('');
  private guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  public playerIdControl: FormControl<string | null> = new FormControl('', Validators.pattern(this.guidRegex));

  public playerSearchForm = new FormGroup(
    {
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      email: new FormControl(''),
      phoneNumber: this.phoneNumberControl,
      playerId: this.playerIdControl
    }
  );

  public players: IPlayer[] = [];
  public columnDefs: ColDef[] = [
    {
      headerName: 'Id',
      field: 'Id',
      resizable: true,
      sortable: true
    },
    {
      headerName: 'First Name',
      field: 'FirstName',
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Last Name',
      field: 'LastName',
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Email',
      field: 'Email',
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Phone',
      field: 'Phone',
      valueFormatter: params => {
        return this.phoneFormatPipe.transform(params.value)
      },
      resizable: true,
      sortable: true
    },
  ];

  private gridApi!: GridApi<IPlayer>;
  protected isGlobalSearch = false;

  constructor(private gameService: GameService,
              private playerService: PlayersService,
              private phoneFormatPipe: PhoneFormatPipe,
              private activatedRoute: ActivatedRoute,
              private errorHandlingService: ErrorHandlingService,
              private router: Router) {
    this.phoneNumberControl.valueChanges.subscribe((value: string | null) => {
      if (value) {
        const formattedValue = this.phoneFormatPipe.transform(value);
        this.phoneNumberControl.setValue(formattedValue, {emitEvent: false});
        return;
      }
    });
  }

  ngOnInit(): void {
    this.isGlobalSearch = this.activatedRoute.snapshot.data['globalSearch'] ?? false;

    const activeGameId = this.gameService.activeGame()?.Id;
    let searchParams: IPlayerSearchParams | undefined = undefined;
    if (activeGameId) {
      searchParams = {
        gameId: this.gameService.activeGame()?.Id!,
      };
    }

    this.retrieveUsersWithSearchParams(searchParams);
  }

  private retrieveUsersWithSearchParams(paramsP?: IPlayerSearchParams) {
    this.playerService.getPlayersBySearchParams(paramsP).subscribe({
      next: (res) => this.players = res,
      error: (error) => {
        this.errorHandlingService.displayPageLevelErrorMessage(error);
      }
    });
  }

  onResetClick() {
    this.playerSearchForm.reset();
    this.retrieveUsersWithSearchParams({
      gameId: this.gameService.activeGame()?.Id!,
    });
  }

  onFormSubmit() {
    if (!this.playerSearchForm.valid) {
      this.playerSearchForm.markAllAsTouched();
      return;
    }

    let searchParams: IPlayerSearchParams = {
      email: this.playerSearchForm.controls.email.value ? this.playerSearchForm.controls.email.value : '',
      gameId: this.gameService.activeGame()?.Id!,
      lastName: this.playerSearchForm.controls.lastName.value ? this.playerSearchForm.controls.lastName.value : '',
      firstName: this.playerSearchForm.controls.firstName.value ? this.playerSearchForm.controls.firstName.value : '',
      phoneNumber: this.phoneNumberControl.value ? this.phoneNumberControl.value?.replace(/-/g, '') : '',
      playerId: this.playerSearchForm.controls.playerId.value ? this.playerSearchForm.controls.playerId.value : '',
    }
    this.retrieveUsersWithSearchParams(searchParams);
  }

  onGridReady(params: GridReadyEvent<IPlayer>) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit({
      defaultMinWidth: 100,
    });
  }

  onRowSelected(chosenPlayerP: any) {
    let player: IPlayer = chosenPlayerP.data;
    const gameId = this.gameService.activeGame()?.Id;

    if (player && gameId) {
      this.router.navigateByUrl(`${gameId}/manage-player/${player.Id}`);
    }

    if (this.isGlobalSearch) {
      this.router.navigateByUrl(`/manage-player/${player.Id}`);
    }
  }
}
