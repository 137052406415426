import {Component} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {MatDialog} from "@angular/material/dialog";
import {ICellRendererParams} from "ag-grid-community";
import {EditWinnerPayoutDialogComponent} from "../edit-winner-payout-dialog/edit-winner-payout-dialog.component";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatTooltip} from "@angular/material/tooltip";
import {MatIcon} from "@angular/material/icon";
import {IWinnerPayoutWithWithWinnerInfo} from "../../../interfaces/IWinnerPayoutWithWithWinnerInfo";
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";
import {MatDivider} from "@angular/material/divider";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {PermissionTypes} from "../../../enum/PermissionTypes";

@Component({
  selector: 'app-winner-payout-options',
  standalone: true,
  templateUrl: './winner-payout-options.component.html',
  imports: [
    MatButton,
    MatTooltip,
    MatIcon,
    HasPermissionDirective,
    MatDivider,
    MatIconButton,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger
  ],
  styleUrls: ['./winner-payout-options.component.css']
})
export class WinnerPayoutOptionsComponent implements ICellRendererAngularComp {

  public rowData!: IWinnerPayoutWithWithWinnerInfo;
  protected readonly PermissionTypes = PermissionTypes;

  constructor(private matDialog: MatDialog) {
  }

  agInit(params: ICellRendererParams): void {
    this.rowData = params.data;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  onPayWinnerClick() {
    this.matDialog.open(EditWinnerPayoutDialogComponent, {
      data: this.rowData,
      width: '650px'
    });
  }

  onViewDetailsClick() {
    this.matDialog.open(EditWinnerPayoutDialogComponent, {
      data: this.rowData,
      width: '650px'
    });
  }

}
