import { Injectable } from '@angular/core';

@Injectable({
  providedIn: "root"
})
export class DialogFunctionService {
  private callback: Function | undefined;

  setCallback(callback: Function) {
    this.callback = callback;
  }

  getCallback() {
    return this.callback;
  }
}
