import {Injectable} from '@angular/core';
import {createStore, select, setProp, withProps} from "@ngneat/elf";
import {ISupportRequest} from "../interfaces/player/ISupportRequest";
import {ISupportRequestPagedResponse} from "../interfaces/player/ISupportRequestPagedResponse";
import {APIService} from "./api.service";
import {map, Observable} from "rxjs";
import {ISupportRequestFilter} from "../interfaces/ISupportRequestFilter";
import {HttpParams} from "@angular/common/http";
import {IAssignAdminToSupportRequest} from "../interfaces/player/IAssignAdminToSupportRequest";
import {IResolveSupportRequest} from "../interfaces/player/IResolveSupportRequest";
import {
  getAllEntitiesApply,
  selectEntitiesCountByPredicate,
  setEntities,
  withEntities
} from "@ngneat/elf-entities";
import {DateService} from "./date.service";
import {ActiveUserService} from "./active-user.service";
import {INumberDeactivationRequest} from "../interfaces/player/INumberDeactivationRequest";

@Injectable({
  providedIn: 'root'
})
export class SupportRequestService {

  private defaultSupportRequestResponse: ISupportRequestPagedResponse = {
    Data: [],
    Errors: [],
    FirstPage: 1,
    LastPage: 1,
    Message: '',
    NextPage: '',
    PageNumber: 1,
    PageSize: 1,
    PreviousPage: '',
    Succeeded: false,
    TotalPages: 1,
    TotalRecords: 0
  }

  public defaultFilter: ISupportRequestFilter = {
    PlayerName: "",
    PageSize: 15,
    PageNumber: 1,
    FromDate: this.dateService.twoWeeksAgo(),
    ToDate: this.dateService.tomorrow(),
    GameId: '',
    PlayerId: '',
    ErrorType: undefined,
    Assignee: '',
    PlayerEmail: ''
  }

  private supportRequestStore = createStore(
    {name: 'support-request-response'},
    withProps<{ supportRequestResponse: ISupportRequestPagedResponse }>({supportRequestResponse: this.defaultSupportRequestResponse}),
    withEntities<ISupportRequest, "Id">({idKey: "Id"})
  );

  public supportRequests$ = this.supportRequestStore.pipe(select(state => state.supportRequestResponse));
  public assignedRequestsCount$: Observable<number> = this.supportRequestStore.pipe(
    selectEntitiesCountByPredicate((request) => request.Assignee == this.activeUserService.activeUser().Id && !request.Resolved));

  constructor(private apiService: APIService,
              private dateService: DateService,
              private activeUserService: ActiveUserService)
  { }

  public getSupportRequests(paramsP: ISupportRequestFilter): Observable<ISupportRequest[]> {
    let query_params = new HttpParams();

    if(paramsP.PlayerName) {
      query_params = query_params.append('playerName', paramsP.PlayerName);
    }

    if (paramsP.PlayerId) {
      query_params = query_params.append("playerId", paramsP.PlayerId);
    }

    if (paramsP.GameId) {
      query_params = query_params.append("gameId", paramsP.GameId);
    }

    if (paramsP.PlayerEmail) {
      query_params = query_params.append("playerEmail", paramsP.PlayerEmail);
    }

    if (paramsP.ErrorType) {
      query_params = query_params.append("errorType", paramsP.ErrorType);
    }

    if(paramsP.Assignee) {
      query_params = query_params.append("assignee", paramsP.Assignee);
    }

    if (paramsP.FromDate) {
      query_params = query_params.append("fromDate", paramsP.FromDate.toDateString());
    }

    if (paramsP.ToDate) {
      query_params = query_params.append("toDate", paramsP.ToDate.toDateString());
    }

    if (paramsP.PageNumber) {
      query_params = query_params.append("pageNumber", paramsP.PageNumber);
    }

    if (paramsP.PageSize) {
      query_params = query_params.append("pageSize", paramsP.PageSize);
    }

    return this.apiService.MakeGetRequest<ISupportRequestPagedResponse>("support-desk/support-requests", query_params).pipe(map((response) => {
      this.supportRequestStore.update(setProp('supportRequestResponse', response));
      this.supportRequestStore.update(setEntities(response.Data));
      return response.Data;
    }));
  }

  public assignAdminToSupportRequest(commandP: IAssignAdminToSupportRequest) {
    return this.apiService.MakePostRequest("support-desk/assign-admin-to-support-request", commandP);
  }

  public resolveSupportRequest(commandP: IResolveSupportRequest) {
    return this.apiService.MakePostRequest("support-desk/resolve-support-request", commandP);
  }
}
