import {Component, Inject} from '@angular/core';
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ISupportRequest} from "../../../interfaces/player/ISupportRequest";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatTab, MatTabGroup} from "@angular/material/tabs";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NgIf} from "@angular/common";
import {MatButton} from "@angular/material/button";
import {ActiveUserService} from "../../../services/active-user.service";
import {MatCheckbox} from "@angular/material/checkbox";
import {IAssignAdminToSupportRequest} from "../../../interfaces/player/IAssignAdminToSupportRequest";
import {SupportRequestService} from "../../../services/support-request.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {IResolveSupportRequest} from "../../../interfaces/player/IResolveSupportRequest";

@Component({
  selector: 'app-support-request-dialog',
  standalone: true,
  imports: [
    DialogBaseComponent,
    MatTabGroup,
    MatTab,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    NgIf,
    MatButton,
    MatCheckbox
  ],
  templateUrl: './support-request-dialog.component.html',
  styleUrl: './support-request-dialog.component.scss'
})
export class SupportRequestDialogComponent {
  public uiState = UIStateEnum.ShowData;
  public uiStateForTemplate = UIStateEnum;

  guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  public assigneeControl = new FormControl<string | null>("", [Validators.required, Validators.pattern(this.guidRegex)]);

  public assigneeForm = new FormGroup({
    assignee: this.assigneeControl
  });

  public resolvedControl = new FormControl<boolean>(false);
  public resolutionDescriptionControl = new FormControl<string | null>({value: "", disabled: true}, Validators.required);

  public resolveForm = new FormGroup({
    resolved: this.resolvedControl,
    resolutionDescription: this.resolutionDescriptionControl
  });

  private isChecked = false;
  constructor(@Inject(MAT_DIALOG_DATA) public supportRequest: ISupportRequest,
              private dialogRef: MatDialogRef<SupportRequestDialogComponent>,
              private activeUserService: ActiveUserService,
              private supportRequestService: SupportRequestService,
              private snackBarService: SnackbarService) {
  }

  assignToActiveUser() {
    this.assigneeControl.setValue(this.activeUserService.activeUser().Id);
    this.assign();
  }

  assign() {
    if (!this.assigneeForm.valid) {
      this.assigneeControl.markAsTouched();
      return;
    }

    let command: IAssignAdminToSupportRequest = {
      AdminId: this.activeUserService.activeUser().Id,
      RequestId: this.supportRequest.Id,
      AssigneeId: this.assigneeControl.value!
    };

    this.supportRequestService.assignAdminToSupportRequest(command).subscribe({
      next: () => {
        this.snackBarService.openSuccessfulSnackBar("Successfully assigned admin to support request");
        this.dialogRef.close();
      },
      error: () => {
        this.snackBarService.openErrorSnackBar("Failed to assign admin to support request");
      }
    });
  }

  resolve() {
    if (this.resolvedControl.value == true && this.resolutionDescriptionControl.value == "") {
      this.resolveForm.markAllAsTouched();
      return;
    }

    let command: IResolveSupportRequest = {
      RequestId: this.supportRequest.Id,
      AdminId: this.activeUserService.activeUser().Id,
      ResolutionDescription: this.resolvedControl.value! ? this.resolutionDescriptionControl.value! : "",
      Resolved: this.resolvedControl.value!
    };

    this.supportRequestService.resolveSupportRequest(command).subscribe({
      next: () => {
        if (this.resolvedControl.value) {
          this.snackBarService.openSuccessfulSnackBar("Successfully resolved support request");
        } else {
          this.snackBarService.openSuccessfulSnackBar("Successfully unresolved support request");
        }

        this.dialogRef.close();
      },
      error: () => {
        this.snackBarService.openErrorSnackBar("Failed to resolve support request");
      }
    });
  }

  onCheckBoxToggle()
  {
    this.isChecked = !this.isChecked;

    if (this.isChecked) {
      this.resolutionDescriptionControl.enable();
    } else {
      this.resolutionDescriptionControl.disable();
    }
  }
}
