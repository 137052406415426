import {Component, Inject, inject} from '@angular/core';
import {DialogBaseComponent} from "../../dialogs/dialog-base/dialog-base.component";
import {
  ButtonCloseDirective,
  ButtonDirective,
  FormCheckComponent,
  FormCheckInputDirective,
  FormCheckLabelDirective,
  FormControlDirective,
  FormDirective,
  FormFeedbackComponent,
  FormLabelDirective,
  ModalModule
} from "@coreui/angular";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButton} from "@angular/material/button";
import {SnackbarService} from "../../../services/snackbar.service";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IApiErrorResponse} from "../../../interfaces/error/IApiErrorResponse";
import {FrequentlyAskedQuestionsService} from "../../../services/frequently-asked-questions.service";
import {IFrequentlyAskedQuestions} from "../../../interfaces/IFrequentlyAskedQuestions";
import {MarkdownComponent, MarkdownPipe} from "ngx-markdown";
import {AsyncPipe} from "@angular/common";

@Component({
  selector: 'app-faqs-edit',
  standalone: true,
  imports: [
    DialogBaseComponent,
    FormCheckComponent,
    FormCheckInputDirective,
    FormCheckLabelDirective,
    FormControlDirective,
    FormDirective,
    FormFeedbackComponent,
    FormLabelDirective,
    FormsModule,
    MatButton,
    ReactiveFormsModule,
    ButtonDirective,
    ModalModule,
    ButtonCloseDirective,
    MarkdownComponent,
    MarkdownPipe,
    AsyncPipe
  ],
  templateUrl: './faqs-edit.component.html',
  styleUrl: './faqs-edit.component.css'
})
export class FaqsEditComponent {
  private frequentlyAskedQuestionsService: FrequentlyAskedQuestionsService = inject(FrequentlyAskedQuestionsService);
  private snackbarService: SnackbarService = inject(SnackbarService);

  uiState: UIStateEnum = UIStateEnum.ShowData;
  uiStateEnumForTemplate = UIStateEnum;
  answerContentControl = new FormControl<string | null>("", Validators.required);
  questionControl = new FormControl<string | null>("", Validators.required);
  activeControl = new FormControl<boolean>(false);

  editFaqForm = new FormGroup({
    question: this.questionControl,
    answer: this.answerContentControl,
    active: this.activeControl
  });

  constructor(private matDialogRef: MatDialogRef<FaqsEditComponent>,
              @Inject(MAT_DIALOG_DATA) public faq: IFrequentlyAskedQuestions) {
    this.populateForm(faq);
  }

  populateForm(faqP: IFrequentlyAskedQuestions) {
    this.editFaqForm.setValue({
      question: faqP.Question,
      answer: faqP.Answer,
      active: faqP.Active
    })
  }

  editFaq() {
    this.editFaqForm.markAllAsTouched();

    if (!this.editFaqForm.valid) {
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;

    this.frequentlyAskedQuestionsService.editFaq({
      active: this.activeControl.value!,
      id: this.faq.Id,
      answer: this.answerContentControl.value!,
      question: this.questionControl.value!
    }).subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
        this.snackbarService.openSuccessfulSnackBar('Successfully edited FAQ');
        this.matDialogRef.close();
      }, error: (res: IApiErrorResponse) => {
        this.uiState = UIStateEnum.ShowData;
        if (res.Error?.ClientErrorMessages?.length > 0) {
          this.snackbarService.openErrorSnackBar(res.Error.ClientErrorMessages.toString());
        } else {
          this.snackbarService.openErrorSnackBar('There was an issue editing your FAQ');
        }
      }
    })
  }
}
