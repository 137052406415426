import {Component, OnInit} from '@angular/core';
import {AuditingService} from "../../../services/auditing.service";
import {IPlayerAuditEvent} from "../../../interfaces/audit/IPlayerAuditEvent";
import {map, Observable} from "rxjs";
import {ColDef, RowClickedEvent} from "ag-grid-community";
import {AuditEventTables} from "../../../table-definitions/audit-event-tables";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {AuditTablesBase} from "../audit-tables-base";
import {DateService} from "../../../services/date.service";
import {AuditingDataDisplayComponent} from "../../dialogs/auditing-data-display/auditing-data-display.component";
import {EventSourceType} from "../../../enum/EventSourceType";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {MatDialog} from "@angular/material/dialog";
import {GameService} from "../../../services/game.service";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {MatFormField, MatFormFieldModule, MatHint, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatDatepickerModule, MatDateRangeInput, MatDateRangePicker} from "@angular/material/datepicker";
import {MatButton} from "@angular/material/button";
import {AgGridAngular} from "ag-grid-angular";
import {AsyncPipe, NgClass} from "@angular/common";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-player-audit-table',
  standalone: true,
  templateUrl: './player-audit-table.component.html',
  imports: [
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatSelect,
    MatHint,
    MatLabel,
    MatOption,
    MatFormFieldModule,
    MatDatepickerModule,
    MatButton,
    AgGridAngular,
    AsyncPipe,
    MatIcon,
    NgClass,
    MatDateRangeInput,
    MatDateRangePicker
  ],
  styleUrls: ['./player-audit-table.component.scss']
})
export class PlayerAuditTableComponent extends AuditTablesBase implements OnInit {

  public playerAudits$: Observable<IPlayerAuditEvent[]> = this.auditingService.playerAudits$.pipe(map((responseP) => {
    this.pagedAuditResponse = responseP;
    return responseP.Data;
  }));

  public playerAuditColDefs: ColDef[] = this.auditEventTables.playerAuditEventColDefs;
  public playerIdFormControl: FormControl = new FormControl('');
  public playerAuditFilterFormGroup = new FormGroup(
    {
      playerId: this.playerIdFormControl,
      eventTypeValue: this.eventTypeFormControl,
      fromDate: this.fromDateFormControl,
      toDate: this.toDateFormControl,
      genericContentSearch: this.genericContentSearchFormControl,
      isSuccess: this.isSuccessControl
    }
  );

  constructor(auditingService: AuditingService,
              dateService: DateService,
              matDialog: MatDialog,
              errorHandlingService: ErrorHandlingService,
              gameService: GameService,
              auditEventTables: AuditEventTables) {
    super(auditingService, dateService, matDialog, gameService, errorHandlingService, auditEventTables);
  }

  ngOnInit() {
    this.auditingService.retrievePlayerAudits(this.defaultFilter).subscribe({
        next: () => {
          this.uiState = UIStateEnum.ShowData
        },
        error: (err) => {
          this.errorHandlingService.displayPageLevelErrorMessage(err);
        }
      }
    )
  }

  onRowSelected(playerAuditP: RowClickedEvent<IPlayerAuditEvent>) {
    this.matDialog.open(AuditingDataDisplayComponent, {
      data: {
        audit: playerAuditP.data,
        eventSource: EventSourceType.WebApp
      }
    })
  }

  override onResetClick() {
    super.onResetClick();
    this.playerIdFormControl.setValue('');
    this.getPaginatedResponse(1);
  }

  getPaginatedResponse(pageNumberP: number): void {
    this.auditingService.retrievePlayerAudits({
      fromDate: new Date(this.fromDateFormControl.value),
      toDate: new Date(this.toDateFormControl.value),
      pageNumber: pageNumberP,
      pageSize: 15,
      eventTypeValue: this.eventTypeFormControl.value,
      playerId: this.playerIdFormControl.value,
      gameId: this.gameService.activeGame().Id,
      genericContentSearch: this.genericContentSearchFormControl.value,
      isSuccess: this.isSuccessControl.value
    }).subscribe({
      next: () => {
        this.updatePaginator();
      },
      error: (err) => {
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });
  }
}
