import {Component, OnInit} from '@angular/core';
import {OidcService} from "../../../services/oidc.service";

@Component({
  selector: 'app-login',
  standalone: true,
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private oidcService: OidcService) {
  }

  ngOnInit(): void {
    this.oidcService.login();
  }

}
