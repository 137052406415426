<form [formGroup]="newGameGeneralSettingsForm" (change)="onFormChange()">
  <mat-form-field appearance="outline" class="full-width">
    <mat-label>Short Name</mat-label>
    <input formControlName="shortName"
           data-testid="short-name-input"
           placeholder="this is an abbreviated or short version or your organization. This will be used in email templates."
           matInput>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>Game Type</mat-label>
    <mat-select formControlName="type" placeholder="choose the game type" data-testid="game-type-select">
      <mat-option [value]="GameTypeEnum.GoldRush">Gold Rush</mat-option>
      <mat-option [value]="GameTypeEnum.ProgressiveFiftyFifty">Fifty Fifty</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>License #</mat-label>
    <input formControlName="licenceNumber"
           data-testid="license-number-input"
           placeholder="enter your license number" matInput>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>Date of First Game Instance</mat-label>
    <input matInput formControlName="dateOfFirstInstance" [matDatepicker]="picker">
    <mat-hint>MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <div class="margin-top-large">
    <p>There are two places on the games landing page to describe your game.</p>
  </div>
  <mat-form-field appearance="outline" class="full-width">
    <mat-label>Short Game Description</mat-label>
    <textarea formControlName="gameDescription"
              data-testid="short-description-text-input"
              [placeholder]="'this text will show on the landing page of your game. This is commonly a simple description of your game. ex: Game Name Here is a way for the community to raise funds for Your main reason for fundraising.'"
              matInput></textarea>
    <mat-hint>Short version description of your game.</mat-hint>
    <mat-error data-testid="landing-page-text-error"
               *ngIf="newGameGeneralSettingsForm.controls.gameDescription.errors && (newGameGeneralSettingsForm.controls.gameDescription.touched || newGameGeneralSettingsForm.controls.gameDescription.dirty)">
      short game description is required
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>More Information Description</mat-label>
    <textarea formControlName="gameMoreInformation"
              data-testid="long-description-text-input"
              placeholder="this text will also show on the landing page of your game. This is roughly a paragraph of text explaining your game. Some ex: What exactly is your game? Why was the game created? Where the funds will be used? Any information you want to include to encourage people to give/play"
              matInput></textarea>
    <mat-hint>Longer version description of your game.
    </mat-hint>
    <mat-error data-testid="landing-page-text-error"
               *ngIf="newGameGeneralSettingsForm.controls.gameMoreInformation.errors && (newGameGeneralSettingsForm.controls.gameMoreInformation.touched || newGameGeneralSettingsForm.controls.gameMoreInformation.dirty)">
      more information description text is required
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>Contact Email</mat-label>
    <input formControlName="contactEmail" placeholder="enter the the contact email" matInput
           data-testid="contact-email-input">
    <mat-hint>this will be the email used for any "Contact Us" links in the web application
    </mat-hint>
    <mat-error data-testid="contact-email-error"
               *ngIf="newGameGeneralSettingsForm.controls.contactEmail.errors && (newGameGeneralSettingsForm.controls.contactEmail.touched || newGameGeneralSettingsForm.controls.contactEmail.dirty)">
      <ng-container *ngIf="newGameGeneralSettingsForm.controls.contactEmail.hasError('required')">
        <strong>contact email is required</strong>
      </ng-container>
      <ng-container *ngIf="newGameGeneralSettingsForm.controls.contactEmail.hasError('email')">
        <strong>email is incorrect format</strong>
      </ng-container>
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>Sender Email</mat-label>
    <input formControlName="senderEmail" placeholder="enter the email for your sent emails" matInput
           data-testid="sender-email-input">
    <mat-hint>this will be the email address that all of the game emails will be sent from
    </mat-hint>
    <mat-error data-testid="sender-email-error"
               *ngIf="newGameGeneralSettingsForm.controls.senderEmail.errors && (newGameGeneralSettingsForm.controls.senderEmail.touched || newGameGeneralSettingsForm.controls.senderEmail.dirty)">
      <ng-container *ngIf="newGameGeneralSettingsForm.controls.senderEmail.hasError('required')">
        <strong>sender email is required</strong>
      </ng-container>
      <ng-container *ngIf="newGameGeneralSettingsForm.controls.senderEmail.hasError('email')">
        <strong>email is incorrect format</strong>
      </ng-container>
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>Max Charity Pledges Per Player</mat-label>
    <input type="number" formControlName="maxPledgesPerPlayer"
           placeholder="enter max number pledges" matInput>
    <mat-hint>maximum number of charities a player can support at one time.</mat-hint>
    @if (newGameGeneralSettingsForm.controls.maxPledgesPerPlayer.errors &&
    newGameGeneralSettingsForm.controls.maxPledgesPerPlayer.touched ||
    newGameGeneralSettingsForm.controls.maxPledgesPerPlayer.dirty) {
      <mat-error data-testid="max-pledges-error">
        @if (newGameGeneralSettingsForm.controls.maxPledgesPerPlayer.hasError('max')) {
          maximum 5 pledges per player
        } @else if (newGameGeneralSettingsForm.controls.maxPledgesPerPlayer.hasError('required')) {
          required
        } @else if (newGameGeneralSettingsForm.controls.maxPledgesPerPlayer.hasError('min')) {
          minimum 1
        }
      </mat-error>
    }

  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>Minimum Age of Participation</mat-label>
    <input type="number" formControlName="minimumAgeOfParticipation"
           placeholder="min age of your users" matInput>
    <mat-hint>this will be displayed on the landing page as Supporters must be ___ years or older to play</mat-hint>
    <mat-error data-testid="max-receipt-attempts-error"
               *ngIf="newGameGeneralSettingsForm.controls.minimumAgeOfParticipation.errors && (newGameGeneralSettingsForm.controls.minimumAgeOfParticipation.touched || newGameGeneralSettingsForm.controls.minimumAgeOfParticipation.dirty)">
      required
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>Max Receipt Attempts</mat-label>
    <input type="number" formControlName="maxReceiptAttempts"
           placeholder="enter max number of attempts" matInput>
    <mat-hint>how many times will we attempt to resend your receipt emails</mat-hint>
    <mat-error data-testid="max-receipt-attempts-error"
               *ngIf="newGameGeneralSettingsForm.controls.maxReceiptAttempts.errors && (newGameGeneralSettingsForm.controls.maxReceiptAttempts.touched || newGameGeneralSettingsForm.controls.maxReceiptAttempts.dirty)">
      required
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>Timezone</mat-label>
    <input formControlName="timezone" placeholder="enter the timezone of your game" matInput
           data-testid="timezone-input">
    <mat-hint>this must be an acceptable Dotnet Timezone Id. Ex: Atlantic Standard Time
    </mat-hint>
    <mat-error data-testid="timezone-error"
               *ngIf="newGameGeneralSettingsForm.controls.timezone.errors && (newGameGeneralSettingsForm.controls.timezone.touched || newGameGeneralSettingsForm.controls.timezone.dirty)">
      timezone is required
    </mat-error>
  </mat-form-field>

  <div class="margin-top-large">
    <mat-checkbox class="display-block" formControlName="regionLockPlayerGeolocation">Region Lock by Player
      Geolocation
    </mat-checkbox>
    <mat-checkbox class="display-block" formControlName="regionLockPlayerPostalCode">Region Lock Player by Postal Code
    </mat-checkbox>
    <mat-checkbox class="display-block" formControlName="allowFreeTickets">Allow Free Tickets</mat-checkbox>
    <mat-checkbox class="display-block" formControlName="autoplayEnabled">Autoplay Enabled</mat-checkbox>
    <mat-checkbox class="display-block" formControlName="collectPlayerAddress">Collect Player Address</mat-checkbox>
    <mat-checkbox class="display-block" formControlName="displayNonWinnerName">Display Non Winner Name</mat-checkbox>
  </div>

</form>
