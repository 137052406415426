import {Pipe, PipeTransform} from '@angular/core';
import {GameStateEnum} from "../enum/GameStateEnum";

@Pipe({
  name: 'gameState'
})
export class GameStatePipe implements PipeTransform {
  public transform(stateP: GameStateEnum): string {
    if (stateP) {
      switch (stateP) {
        case GameStateEnum.Active:
          return 'Active';
        case GameStateEnum.Draft:
          return 'Draft';
        case GameStateEnum.Inactive:
          return 'Inactive';
      }
    }
    return 'Unknown Status';
  }
}
