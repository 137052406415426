import {ResolveFn, Router} from "@angular/router";
import {inject} from "@angular/core";
import {GameService} from "../services/game.service";
import {ActiveUserService} from "../services/active-user.service";
import {map} from "rxjs";
import {catchError} from "rxjs/operators";

export const CheckForSingleGameAccessResolver: ResolveFn<null> = () => {
  const gameService = inject(GameService);
  const activeUserService = inject(ActiveUserService);
  const router = inject(Router);

  return gameService.getGamesForUser(activeUserService.activeUser().Id).pipe(map((gamesForUserP) => {
    // if you only have access to one org, check if that org only have one game
    if (gamesForUserP.length == 1) {
      const chosenGameId = gamesForUserP[0].Id;
      router.navigateByUrl(`${chosenGameId}/active-game-instances`);
    }

    return null;
  }), catchError((err) => {
    router.navigateByUrl(`not-authorized`);
    throw err;
  }))
}
