<app-component-level-error-message [errorMessage]="errorMessage"></app-component-level-error-message>
@if (selectedGameInstance) {
  <div class="flex-row-wrap justify-start gap-normal margin-bottom-med margin-top-med custom-card-border">

    <div class="detail-section">
      <div class="detail-label">Started On</div>
      <div class="detail-value" data-testid="game-started-on">{{ selectedGameInstance.StartedOn | date: 'MMM d, y' }}
      </div>
    </div>

    <div class="detail-section">
      <div class="detail-label">Draw Date & Time</div>
      <div class="detail-value"
           data-testid="game-ended-on">{{ selectedGameInstance.EndedOn | date: 'MMM d, y hh:mm a' }}
      </div>
    </div>

    <div class="detail-section">
      <div class="detail-label">Status</div>
      <div class="detail-value"
           data-testid="game-instance-status">{{ selectedGameInstance.State | gameInstanceStatus }}
      </div>
    </div>

  </div>
}

<div class="flex-row justify-center align-center">
  @if (!noTicketChartData) {
    <div class="flex-50">
      <canvas data-testid="tickets-chart" id="ticketsChart" class="table-size"></canvas>
    </div>
  }

  @if (!noManualAutoPlayChartData) {
    <div class="flex-50">
      <canvas data-testid="manual-auto-sales-chart" id="manualAutoPlayChart" class="table-size"></canvas>
    </div>
  }

</div>

<div class="flex-row justify-space-between align-end">
  <div class="flex-50">
    <canvas data-testid="player-ticket-chart" id="playersTicketsLineChart"></canvas>
  </div>
  @if (!noSalesPerDayLineChartData) {
    <div class="flex-50">
      <canvas data-testid="sales-per-day-chart" id="salesPerDayLineChart"></canvas>
    </div>
  }

</div>

@if (!noCharitySalesBarGraphData) {
  <div class="flex-row justify-start align-center">
    <canvas data-testid="charity-sales-chart" id="charitySalesBarGraph" class="table-size"></canvas>
  </div>

}

<div *hasPermission="PermissionTypes.DrawGameWinner" class="margin-top-large">
  <ng-container *ngIf="winnersPendingDecisions$ | async as winnersPendingDecisions">
    @if (winnersPendingDecisions.UnclaimedWinners.length > 0) {
      @if (uiState == uiStateForTemplate.ShowLoading) {
        <div class="bold-text ready-for-draw margin-top-med" data-testid="draw-in-progress-message">The draw is now
          taking place. Please wait while we determine
          the winner.
        </div>
        <mat-spinner class="center-margin"></mat-spinner>
      } @else {
        <h2>Winners Pending Decisions</h2>
        <p>Claim status must be changed to 'Forfeit' before the game instance can be redrawn</p>
        <table mat-table [dataSource]="winnersPendingDecisions.UnclaimedWinners">

          <ng-container matColumnDef="gameInstanceId">
            <th mat-header-cell *matHeaderCellDef>Game Instance Id</th>
            <td mat-cell *matCellDef="let winner;">{{ winner.GameInstanceId }}</td>
          </ng-container>

          <ng-container matColumnDef="playerNumberId">
            <th mat-header-cell *matHeaderCellDef>Player Number Id</th>
            <td mat-cell *matCellDef="let winner;">{{ winner.PlayerNumberId }}</td>
          </ng-container>

          <ng-container matColumnDef="playerWinnings">
            <th mat-header-cell *matHeaderCellDef>Player Winnings</th>
            <td mat-cell *matCellDef="let winner;">{{ winner.PlayerWinnings | formatCurrency }}</td>
          </ng-container>

          <ng-container matColumnDef="playerName">
            <th mat-header-cell *matHeaderCellDef>Player Name</th>
            <td mat-cell *matCellDef="let winner;">{{ winner.PlayerFirstName + " " + winner.PlayerLastName }}</td>
          </ng-container>

          <ng-container matColumnDef="playerEmail">
            <th mat-header-cell *matHeaderCellDef>Player Email</th>
            <td mat-cell *matCellDef="let winner;">{{ winner.PlayerEmail }}</td>
          </ng-container>

          <ng-container matColumnDef="createdOn">
            <th mat-header-cell *matHeaderCellDef>Created On</th>
            <td mat-cell *matCellDef="let winner;">{{ winner.CreatedOn | date }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
        </table>
      }
    }
  </ng-container>
</div>
