import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  private readonly DEFAULT_RETRIEVAL_MESSAGE: string = 'Looks like there was a problem retrieving data for this page. Please refresh and try again';
  private readonly DEFAULT_COMMAND_MESSAGE: string = 'Looks like there was a problem processing your request. Please try again or contact your system admin if problem persists';
  private readonly FORBIDDEN_ERROR_MESSAGE: string = 'Sorry, but looks like you do not have access to view this info or perform the requested action. Please try a different page, or try logging in as a different user';
  private readonly UNAUTHENTICATED_ERROR_MESSAGE: string = 'Sorry, but it appears you do not currently have access to the admin application. Please try logging out and back in, or logging in with a user account who has admin access.';
  private pageLevelErrorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public pageLevelErrorMessage$: Observable<string> = this.pageLevelErrorMessageBehaviorSubject.asObservable();

  private dialogLevelErrorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public dialogLevelErrorMessage$: Observable<string> = this.dialogLevelErrorMessageBehaviorSubject.asObservable();

  public unauthorizedErrorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public unauthorizedErrorMessage$: Observable<string> = this.unauthorizedErrorMessageBehaviorSubject.asObservable();

  constructor() {
  }

  public display403ErrorMessage() {
    this.unauthorizedErrorMessageBehaviorSubject.next(this.FORBIDDEN_ERROR_MESSAGE);
  }

  public display401ErrorMessage() {
    this.unauthorizedErrorMessageBehaviorSubject.next(this.UNAUTHENTICATED_ERROR_MESSAGE);
  }

  public clearErrorMessages() {
    this.pageLevelErrorMessageBehaviorSubject.next('');
    this.dialogLevelErrorMessageBehaviorSubject.next('');
    this.unauthorizedErrorMessageBehaviorSubject.next('');
  }

  public clearDialogErrorMessages() {
    this.dialogLevelErrorMessageBehaviorSubject.next('');
  }

  public displayPageLevelErrorMessage(errorP: any, errorMessageP: string = '') {
    if (!errorMessageP) {
      errorMessageP = this.DEFAULT_RETRIEVAL_MESSAGE;
    }
    this.pageLevelErrorMessageBehaviorSubject.next(errorMessageP);
    throw errorP;
  }

  public displayDialogLevelErrorMessage(errorP: any, isCommand: boolean = false, errorMessageP: string = '') {
    if (errorP?.Error?.ClientErrorMessages?.length > 0) {
      errorMessageP = errorP?.Error?.ClientErrorMessages[0];
    }

    if (!errorMessageP) {
      if (isCommand) {
        errorMessageP = this.DEFAULT_COMMAND_MESSAGE;
      } else {
        errorMessageP = this.DEFAULT_RETRIEVAL_MESSAGE;

      }
    }

    this.dialogLevelErrorMessageBehaviorSubject.next(errorMessageP);
    throw errorP;
  }
}
