import {Component, inject, OnInit} from '@angular/core';
import {AsyncPipe, DatePipe} from "@angular/common";
import {GameService} from "../../../services/game.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {Observable} from "rxjs";
import {ColDef, GridApi, GridReadyEvent, SizeColumnsToFitGridStrategy} from "ag-grid-community";
import {FormatBoolean} from "../../cell-renderers/show-played/format-boolean.component";
import {AgGridAngular} from "ag-grid-angular";
import {TestimonialsService} from "../../../services/testimonials.service";
import {IGameTestimonials} from "../../../interfaces/IGameTestimonials";
import {TestimonialsSettingsComponent} from "../testimonial-settings/testimonials-settings.component";

@Component({
  selector: 'app-testimonials-table',
  standalone: true,
  imports: [
    AgGridAngular,
    AsyncPipe
  ],
  templateUrl: './testimonials-table.component.html',
  styleUrl: './testimonials-table.component.scss'
})
export class TestimonialsTableComponent implements OnInit {

  private datePipe: DatePipe = inject(DatePipe);
  private gameService: GameService = inject(GameService);
  private testimonialsService: TestimonialsService = inject(TestimonialsService);
  private errorHandlingService: ErrorHandlingService = inject(ErrorHandlingService);
  public defaultColDef: ColDef = {
    flex: 1,
    cellDataType: false,
  };
  public themeClass: string =
    "ag-theme-quartz";
  public uiState = UIStateEnum.ShowLoading;
  public uiStateForTemplate = UIStateEnum;
  public testimonialsForGame$: Observable<IGameTestimonials[]> = this.testimonialsService.selectGameTestimonials$();
  public testimonialsGridApi!: GridApi<IGameTestimonials>;
  public autoSizeStrategy: SizeColumnsToFitGridStrategy = {
    type: 'fitGridWidth'
  };

  public testimonialsDefs: ColDef[] = [
    {
      headerName: 'Id',
      flex: 1,
      field: 'Id',
    },
    {
      headerName: 'Name of User',
      field: 'GivenByUserName',
    },
    {
      headerName: 'Position of User',
      field: 'GivenByUserPosition'
    },
    {
      headerName: 'Testimonial',
      field: 'Content',
      cellRenderer: (params: any) => {
        return `<div title="${params.value}">${params.value.substring(0, 50)}${params.value.length > 50 ? '...' : ''}</div>`;
      }
    },
    {
      headerName: 'Created On Date',
      maxWidth: 140,
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y');
        return convertedDate ? convertedDate : '';
      },
      field: 'CreatedOn'
    },
    {
      headerName: 'Active',
      maxWidth: 80,
      cellRenderer: FormatBoolean,
      field: 'Active',
    },
    {
      maxWidth: 80,
      cellRenderer: TestimonialsSettingsComponent
    }
  ];

  ngOnInit(): void {
    this.testimonialsService.fetchTestimonials(this.gameService.activeGame()?.Id!).subscribe({
      next: (res) => {
        this.uiState = UIStateEnum.ShowData;
      }, error: (error) => {
        this.errorHandlingService.displayPageLevelErrorMessage(error);
        this.uiState = UIStateEnum.ShowData;
      }
    })
  }

  onTestimonialGridReady(params: GridReadyEvent<IGameTestimonials>) {
    this.testimonialsGridApi = params.api;
    this.testimonialsGridApi.sizeColumnsToFit({
      defaultMinWidth: 90,
    });
  }
}
