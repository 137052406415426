import {inject} from "@angular/core";
import {PermissionsService} from "../services/permissions.service";
import {PermissionTypes} from "../enum/PermissionTypes";
import {Router} from "@angular/router";
import {ActiveUserService} from "../services/active-user.service";

export const PermissionGuard = (permissionTypeP: PermissionTypes) => {
  const permissionsService = inject(PermissionsService);
  const activeUserService = inject(ActiveUserService);
  const router = inject(Router);

  let userHasPermission = false;
  if (activeUserService.activeUser()?.Id) {
    userHasPermission = permissionsService.userHasPermission(permissionTypeP);

    if (!userHasPermission) {
      router.navigateByUrl('not-authorized');
    }
  }

  return userHasPermission;
};
