<mat-card>
  <mat-card-content>
    <div class="flex-col mb-3">
      <button class="red-text margin-top-med"
              (click)="onDeactivatePlayerNumbersClick()"
              data-testid="deactivate-numbers-button"
              mat-stroked-button>
        <mat-icon>do_disturb</mat-icon>
        Deactivate Player Numbers
      </button>
      <button class="green-text margin-top-med"
              (click)="onActivatePlayerNumbersClick()"
              data-testid="activate-numbers-button"
              mat-stroked-button>
        <mat-icon>refresh</mat-icon>
        Activate Player Numbers
      </button>
    </div>

    <div class="mb-3">
      <h3>Numbers in Current Draw</h3>
      <ag-grid-angular
        id="playerNumbersGrid"
        class="ag-theme-quartz margin-top-med"
        data-testid="played-player-number-groups-table"
        [tooltipShowDelay]=0
        rowClass="table-row"
        domLayout='autoHeight'
        overlayNoRowsTemplate="<span>This user has no numbers played in the current draw</span>"
        (gridReady)="onPlayedGridReady($event)"
        [rowData]="playedPlayerNumberGroupRows"
        [columnDefs]="playedNumberGroupsColumnDefs">
      </ag-grid-angular>
    </div>


    @if (failedAutoplayGroups.length > 0) {
      <div class="mb-3">
        <h3>Failed Autoplay Groups</h3>
        <ag-grid-angular
          id="failedAutoplaysGrid"
          class="ag-theme-quartz margin-top-med"
          data-testid="failed-autoplays-table"
          [tooltipShowDelay]=0
          rowClass="table-row"
          domLayout='autoHeight'
          overlayNoRowsTemplate="<span>No numbers for this game</span>"
          (gridReady)="onFailedAutoplayGridReady($event)"
          [rowData]="failedAutoplayGroups"
          [columnDefs]="failedColumnDefs">
        </ag-grid-angular>
      </div>
    }

    <div class="mb-3">
      <h3>All Player Numbers</h3>
      <ag-grid-angular
        id="allNumbersGrid"
        class="ag-theme-quartz margin-top-med large-table"
        data-testid="all-player-numbers-table"
        [tooltipShowDelay]=0
        rowClass="table-row"
        domLayout='autoHeight'
        overlayNoRowsTemplate="<span>this player has no numbers for this game</span>"
        (gridReady)="onUnplayedGridReady($event)"
        [rowData]="allPlayerNumbers"
        [columnDefs]="allNumbersColumnDefs">
      </ag-grid-angular>

    </div>
  </mat-card-content>
</mat-card>
