import {Component, OnInit} from '@angular/core';
import {GameService} from "../../services/game.service";
import {ActiveUserService} from "../../services/active-user.service";
import {PermissionTypes} from "../../enum/PermissionTypes";
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {concatMap, map, Observable, of, tap} from "rxjs";
import {AppConfigService} from "../../services/app-config.service";
import {MatListModule} from "@angular/material/list";
import {AsyncPipe} from "@angular/common";
import {RouterLink} from "@angular/router";
import {MatTooltip} from "@angular/material/tooltip";
import {MatIcon} from "@angular/material/icon";
import {HasPermissionDirective} from "../../directives/permissions/has-permission";
import {
  UserHasCausableAdminAccessDirective
} from "../../directives/permissions/user-has-causable-admin-access.directive";
import {AdminAppMode} from "../../enum/AdminAppMode";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {MatBadge} from "@angular/material/badge";
import {SupportRequestService} from "../../services/support-request.service";

@Component({
  selector: 'app-side-nav',
  standalone: true,
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  imports: [
    MatListModule,
    AsyncPipe,
    RouterLink,
    MatTooltip,
    MatIcon,
    HasPermissionDirective,
    UserHasCausableAdminAccessDirective,
    MatSlideToggle,
    MatBadge
  ]
})
export class SideNavComponent implements OnInit {
  public activeGameId$ = this.gameService.selectActiveGameId();
  public gameDrawnManually$ = this.gameService.selectActiveGameDrawnManually();
  public userIsLoggedIn$ = this.activeUserService.userIsLoggedIn().pipe(concatMap((val) => {
    // if the user is logged in, go get all of their games and store them
    if (val) {

      return this.gameService.getGamesForUser(this.activeUserService.activeUser().Id).pipe(map(() => val));
    }
    return of(val);
  }));
  public activeUser$ = this.activeUserService.selectActiveUser().pipe(tap((activeUser) => {
    if (activeUser.CausableAdmin) {
      this.showCausableModeToggle = true;
      this.supportRequestService.getSupportRequests(this.supportRequestService.defaultFilter).subscribe()
    }
  }));
  public availableGamesToUser$: Observable<IGameQueryResult[]> = this.gameService.selectAllAvailableGames();
  sideNavShouldOpen: boolean = true;
  public showCausableModeToggle = false;
  public permissionTypes = PermissionTypes;
  public currentEnvironment: string = "";
  public assignedSupportRequestsCount$ = this.supportRequestService.assignedRequestsCount$;

  constructor(private gameService: GameService,
              private activeUserService: ActiveUserService,
              private appConfigService: AppConfigService,
              private supportRequestService: SupportRequestService) {
  }

  ngOnInit(): void {
    this.appConfigService.appConfig$.subscribe(config => {
      this.currentEnvironment = config.currentEnvironment;
    })
  }


}
