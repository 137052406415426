import {Component, inject, Input} from '@angular/core';
import {MatCardModule} from "@angular/material/card";
import {AgGridAngular} from "ag-grid-angular";
import {ColDef, FirstDataRenderedEvent, GridSizeChangedEvent} from "ag-grid-community";
import {IGamePlayerInputData} from "../../../interfaces/player/IGamePlayerInputData";
import {PlayerInfoTableDefs} from "../../player-game-info/player-info-table-defs";
import {PlayersService} from "../../../services/players.service";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {IPlayerCharityPledge} from "../../../interfaces/player/IPlayerCharityPledge";
import {ErrorHandlingService} from "../../../services/error-handling.service";

@Component({
  selector: 'app-manage-player-pledges',
  standalone: true,
  imports: [
    MatCardModule,
    AgGridAngular
  ],
  templateUrl: './manage-player-pledges.component.html',
  styleUrl: './manage-player-pledges.component.css'
})
export class ManagePlayerPledgesComponent {
  private playerService: PlayersService = inject(PlayersService);
  private playerInfoTables: PlayerInfoTableDefs = inject(PlayerInfoTableDefs);
  private errorHandlingService: ErrorHandlingService = inject(ErrorHandlingService);
  public uiState = UIStateEnum.ShowData;
  public uiStateForTemplate = UIStateEnum;

  public pledgesTableColDefs: ColDef[] = this.playerInfoTables.playerPledgesColDefs;
  public pledges: IPlayerCharityPledge[] = [];

  @Input()
  public set gamePlayerData(gamePlayerDataP: IGamePlayerInputData) {
    if (gamePlayerDataP.playerId && gamePlayerDataP.game) {
      this.fetchCharityPledgeInfo(gamePlayerDataP.playerId, gamePlayerDataP.game.Id);
    }
  }

  private fetchCharityPledgeInfo(playerIdP: string, gameIdP: string) {
    this.uiState = UIStateEnum.ShowLoading;
    this.playerService.getPlayerCharityPledgesByGameId(playerIdP, gameIdP).subscribe({
      next: (res) => {
        this.uiState = UIStateEnum.ShowData;
        this.pledges = res;
      },
      error:(err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    })
  }

  gridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }
}
