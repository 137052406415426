import {Component, Inject, inject} from '@angular/core';
import {DialogBaseComponent} from "../../dialogs/dialog-base/dialog-base.component";
import {MatButton} from "@angular/material/button";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {MatError, MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {DatePipe, NgIf} from "@angular/common";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {GameService} from "../../../services/game.service";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {SnackbarService} from "../../../services/snackbar.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IGameLotteryLicence} from "../../../interfaces/IGameLotteryLicence";
import {MatOption, MatSelect} from "@angular/material/select";
import {IApiErrorResponse} from "../../../interfaces/error/IApiErrorResponse";
import {LotteryLicenceService} from "../../../services/lottery-licence.service";

@Component({
  selector: 'app-lottery-licence-edit',
  standalone: true,
  imports: [
    DialogBaseComponent,
    MatButton,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatError,
    MatFormField,
    MatHint,
    MatInput,
    MatLabel,
    MatSuffix,
    NgIf,
    ReactiveFormsModule,
    MatSelect,
    FormsModule,
    MatOption
  ],
  templateUrl: './lottery-licence-edit.component.html',
  styleUrl: './lottery-licence-edit.component.css'
})
export class LotteryLicenceEditComponent {
  private lotteryLicenceService: LotteryLicenceService = inject(LotteryLicenceService);
  private gameService: GameService = inject(GameService);
  private datePipe: DatePipe = inject(DatePipe);
  private snackbarService: SnackbarService = inject(SnackbarService);

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;

  licenceNumberControl = new FormControl<string | null>("", Validators.required);
  startDateControl = new FormControl<string | null>('', Validators.required);
  endDateControl = new FormControl<string | null>('');
  activeControl = new FormControl<boolean>(false);

  licenceForm = new FormGroup({
    licenceNumber: this.licenceNumberControl,
    startDate: this.startDateControl,
    endDate: this.endDateControl,
    active: this.activeControl
  });

  constructor(private matDialogRef: MatDialogRef<LotteryLicenceEditComponent>,
              @Inject(MAT_DIALOG_DATA) public lotteryLicence: IGameLotteryLicence) {
    this.populateForm(lotteryLicence);

  }

  populateForm(lotteryLicenceP: IGameLotteryLicence) {
    this.licenceForm.setValue({
      licenceNumber: lotteryLicenceP.LicenceNumber,
      startDate: this.datePipe.transform(lotteryLicenceP.StartDate, 'yyyy-MM-ddTHH:mm:ss.SSS', 'UTC'),
      endDate: lotteryLicenceP.EndDate ? this.datePipe.transform(lotteryLicenceP.EndDate, 'yyyy-MM-ddTHH:mm:ss.SSS', 'UTC') : null,
      active: lotteryLicenceP.Active
    })
  }

  editLicenceNumber() {
    if (!this.licenceForm.valid) {
      this.licenceForm.markAllAsTouched();
      return;
    }

    this.lotteryLicenceService.editLotteryLicence({
      gameId: this.gameService.activeGame().Id!,
      licenceNumber: this.licenceNumberControl.value!,
      endDate: this.endDateControl.value!,
      startDate: this.startDateControl.value!,
      active: this.activeControl.value!,
      id: this.lotteryLicence.Id
    }).subscribe({
      next: () => {
        this.matDialogRef.close();
        this.snackbarService.openSuccessfulSnackBar('Successfully edited licence number');
      }, error: (res: IApiErrorResponse) => {
        this.uiState = UIStateEnum.ShowData;
        if (res.Error?.ClientErrorMessages?.length > 0) {
          this.snackbarService.openErrorSnackBar(res.Error.ClientErrorMessages.toString());
        } else {
          this.snackbarService.openErrorSnackBar('There was an issue editing your licence number');
        }
      }
    })
  }
}
