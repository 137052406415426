import {Pipe, PipeTransform} from '@angular/core';
import {AutoplayStatusTypes} from "../enum/AutoplayStatusTypes";

@Pipe({
  name: 'subscriptionStatus'
})
export class SubscriptionStatusPipe implements PipeTransform {
  public transform(statusP: AutoplayStatusTypes): string {
    if (statusP !== null && typeof statusP !== 'undefined') {
      switch (statusP){
        case AutoplayStatusTypes.notAutoplay: return 'Not Using Autoplay';
        case AutoplayStatusTypes.PaymentFailure: return 'Payment Failure';
        case AutoplayStatusTypes.PaymentProcessing: return 'Payment Processing';
        case AutoplayStatusTypes.PaymentSuccessful: return 'Payment Successful';
      }
    }
    return 'Not Using Autoplay';
  }
}
