<app-dialog-base [showLoadingSpinner]="uiState === uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>{{ noteToUpdate === undefined ? "Add Player Game Note" : "Update Player Game Note" }}</span>

  <ng-container dialog-content>
    @if (uiState === uiStateEnumForTemplate.ShowData) {
      <form [formGroup]="playerGameNoteForm">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Message</mat-label>
          <input matInput formControlName="message" data-testid="player-game-note-message-input" type="text"
                 placeholder="Enter the message for the note" required>
          @if (messageControl.errors) {
            <mat-error data-testid="player-game-note-message-error">
              Message is required
            </mat-error>
          }

        </mat-form-field>

        @if (currentUser.CausableAdmin && noteToUpdate.note === undefined) {
          <mat-label>Causable Admin Note</mat-label>
          <mat-checkbox data-testid="player-game-note-causable-admin-checkbox"
                        formControlName="isCausableAdminNote"></mat-checkbox>
        }

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Reason Type</mat-label>
          <mat-select formControlName="reasonType" data-testid="player-game-note-select">
            @for (reason of noteReasonTypes; track reason) {
              <mat-option [value]="reason.value" data-testid="player-game-note-option">{{ reason.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </form>
    }
  </ng-container>

  <ng-container actions>
    <button data-testid="player-game-note-form-submit-button"
            mat-button (click)="noteToUpdate.note === undefined ? submit() : updateNote();"
            [disabled]="uiState === uiStateEnumForTemplate.ShowLoading">Submit
    </button>
  </ng-container>
</app-dialog-base>
