<form [formGroup]="newGameMetadataSettingsForm" (change)="onFormChange()">
  <div>Meta data fields are used for Browser SEO</div>
  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>Metadata Description</mat-label>
    <textarea formControlName="metadataDescription" placeholder="enter desciption text" matInput></textarea>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>Metadata Image Url</mat-label>
    <input formControlName="metadataImageUrl" placeholder="enter url to your image" matInput>
    <mat-error data-testid="metadata-image-url-error"
      *ngIf="newGameMetadataSettingsForm.controls.metadataImageUrl.errors && (newGameMetadataSettingsForm.controls.metadataImageUrl.touched || newGameMetadataSettingsForm.controls.metadataImageUrl.dirty)">
      url must be in a valid format
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>Metadata Site Name</mat-label>
    <input formControlName="metadataSiteName" placeholder="enter the name of your website" matInput>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>Metadata Title</mat-label>
    <input formControlName="metadataTitle" placeholder="enter title for your game" matInput>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width margin-top-large">
    <mat-label>Metadata Url</mat-label>
    <input formControlName="metadataUrl" placeholder="enter the url to your game" matInput>
    <mat-error data-testid="metadata-url-error"
      *ngIf="newGameMetadataSettingsForm.controls.metadataUrl.errors && (newGameMetadataSettingsForm.controls.metadataUrl.touched || newGameMetadataSettingsForm.controls.metadataUrl.dirty)">
      url must be in a valid format
    </mat-error>
  </mat-form-field>

</form>
