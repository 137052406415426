<app-component-level-error-message [errorMessage]="errorMessage"></app-component-level-error-message>

@if (uiState === uiStateEnumForTemplate.ShowLoading) {
  <app-loading-card-content></app-loading-card-content>
} @else if (uiState === uiStateEnumForTemplate.ShowData) {
  <div data-testid="auditing-report-container">
    @if (raffleReportSummaryData) {
      <div class="flex-row justify-space-between align-start">

        <div>
          <div class="section-header">Auditing Report</div>
          <div class="ticket-type-header">Draw Date: {{ chosenGameInstance?.EndedOn | date: 'MMM d, y h:mm a' }}
          </div>
        </div>

        <div>
          <button mat-raised-button
                  color="primary" (click)="generatePDF()">Generate PDF
          </button>
        </div>
      </div>
      <div class="margin-top-large">
        <div class="raffle-section">
          <div class="section-header">Ticket Summary:</div>
          <div class="ion-margin-bottom">
            <div>Number of Registered Players: {{ raffleReportSummaryData.NumberOfRegisteredPlayers }}</div>
            <div>Number of Players in this Game: {{ raffleReportSummaryData.NumberOfPlayersInGame }}</div>
            <div>Number of Played Tickets: {{ raffleReportSummaryData.TotalPlayed }}</div>
            @if (activeGame?.Type === gameTypes.GoldRush) {
              <div>Number of Unplayed
                Tickets: {{ raffleReportSummaryData.TotalNotPlayed }}
              </div>
            }
            <div>Number of Voided Tickets: {{ raffleReportSummaryData.TotalVoided }}</div>
          </div>
        </div>
        @for (data of allGroupedTickets; track data) {
          <div>
            <div class="section-header margin-top-med">Player Info:</div>
            <div class="margin-bottom-med">
              <div>{{ data.Player.FirstName + ' ' + data.Player.LastName }}</div>
              <div>{{ data.Player.Email }}</div>
              <div>{{ data.Player.Phone }}</div>
            </div>
            <div class="ticket-type-header">Played Tickets:</div>
            @if (data.GroupedTickets.Played) {
              <table class="margin-bottom-med">
                <tr>
                  <th>Ticket Number</th>
                  <th>Assigned On</th>
                  <th>Purchased On</th>
                </tr>
                @for (group of data.GroupedTickets.Played; track group) {
                  <tr>
                    <th colspan="4">Payment Identifier: {{ group.PaymentId }}</th>
                  </tr>
                  @for (row of group.Tickets; track row) {
                    <tr>
                      <td>{{ row.TicketNumber }}</td>
                      <td>{{ row.CreatedOn }}</td>
                      <td>{{ row.PurchasedOn }}</td>
                    </tr>
                  }

                }
              </table>

            } @else {
              <div class="margin-bottom-med" data-testid="no-played-tickets-message">This player had no played tickets
                in
                this draw
              </div>
            }

            @if (activeGame?.Type === gameTypes.GoldRush) {
              <div class="ticket-type-header">Not Played Tickets:</div>
              @if (data.GroupedTickets.NotPlayed.length) {
                <table class="margin-bottom-med">
                  <tr>
                    <th>Ticket Number</th>
                    <th>Assigned On</th>
                  </tr>
                  @for (ticket of data.GroupedTickets.NotPlayed; track ticket) {
                    <tr>
                      <td>{{ ticket.TicketNumber }}</td>
                      <td>{{ ticket.CreatedOn }}</td>
                    </tr>
                  }

                </table>
              } @else {
                <div class="margin-bottom-med" data-testid="no-unplayed-tickets-message">This player had no unplayed
                  tickets in this draw
                </div>
              }
            }

            <div class="ticket-type-header">Voided Tickets:</div>

            @if (data.GroupedTickets.Voided.length) {
              <table class="margin-bottom-med">
                <tr>
                  <th>Ticket Number</th>
                </tr>

                @for (ticket of data.GroupedTickets.Voided; track ticket) {
                  <tr>
                    <td>{{ ticket.TicketNumber }}</td>
                  </tr>
                }

              </table>
            } @else {
              <div class="margin-bottom-med" data-testid="no-voided-tickets-message">This player had no voided tickets
                in
                this draw
              </div>
            }
            <hr>
          </div>

        }
      </div>

      @if (raffleReport.length === 0) {
        <div class="no-results">No Auditing Report data for this draw</div>
      }

    } @else {
      <span data-testid="no-report-data-message">No Available Report Data</span>
    }
  </div>

}
