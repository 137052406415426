<app-dialog-base [showLoadingSpinner]="uiState === uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Send Email Blast</span>

  <ng-container dialog-content *ngIf="uiState === uiStateEnumForTemplate.ShowData">
    <form [formGroup]="emailForm">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Subject</mat-label>
        <input matInput formControlName="subject" placeholder="Subject" data-testid="email-subject-input">
        <mat-hint>Note that the game name will be prepended to the subject</mat-hint>
        <mat-error *ngIf="subjectControl.errors">Subject is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Message</mat-label>
        <textarea matInput
                  formControlName="message"
                  placeholder="Message"
                  data-testid="email-message-input"></textarea>
        <mat-hint>The message you wish to send to all users in the game</mat-hint>
        <mat-error *ngIf="messageControl.errors">Message is required.</mat-error>
      </mat-form-field>

      <mat-checkbox formControlName="sendToAllUsers">Send to all users</mat-checkbox>
      <mat-checkbox formControlName="shouldByPassUnsubscribe">Should bypass unsubscribes</mat-checkbox>

      @if (!sendToAllUsersControl.value) {
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Users to Email</mat-label>
          <mat-chip-grid #emails>
            @for (email of selectedEmails; track $index; ) {
              <mat-chip-row
                removable="true"
                (removed)="removeEmail($index)">
                {{ email }}
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
            }
          </mat-chip-grid>
          <input matInput #emailInput #emailAutoTrigger="matAutocompleteTrigger"
                 (input)="emailInputChanged(emailInput.value)"
                 [matChipInputFor]="emails"
                 [matChipInputAddOnBlur]="true"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 (matChipInputTokenEnd)="addEmail($event)"
                 [matAutocomplete]="emailAuto"
                 [formControl]="emailControl">
          <mat-hint>Begin typing the email address(es) of the users you wish to notify with this email</mat-hint>
          <mat-error *ngIf="!sendToAllUsersControl.value && selectedEmails.length == 0
          && (emailControl.dirty || emailControl.touched)">
            Please enter at least one email address or select to send to all users in the game
          </mat-error>
          <mat-autocomplete #emailAuto="matAutocomplete"
                            (optionSelected)="selectedChip($event)">
            @for (email of (filteredEmails$ | async); track email) {
              <mat-option [value]="email" (mousedown)="$event.preventDefault()">
                {{email}}
              </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      }
    </form>
  </ng-container>

  <ng-container actions>
    <button mat-button
            (click)="sendEmailBlast()"
            data-testid="send-email-blast-button"
            [disabled]="uiState === uiStateEnumForTemplate.ShowLoading">
      Send
    </button>
  </ng-container>
</app-dialog-base>
