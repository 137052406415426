import {Pipe, PipeTransform} from '@angular/core';
import {InvalidEmailEntryReasonType} from "../enum/InvalidEmailEntryReasonType";

@Pipe({
  name: 'displayInvalidEmailReason'
})
export class DisplayInvalidEmailReasonPipe implements PipeTransform {
  public transform(invalidReasonP: InvalidEmailEntryReasonType): string {
    if (invalidReasonP) {
      switch (invalidReasonP) {
        case InvalidEmailEntryReasonType.OrgInviteAlreadyCausableAdmin:
          return 'This user already has access to this org through a higher level of access.';
        case InvalidEmailEntryReasonType.CausableInviteAlreadyCausableAdmin:
          return 'This user is already a Causable Admin.';
        case InvalidEmailEntryReasonType.GameInviteAlreadyHigherAdmin:
          return 'This user already has access to this game through a higher level of access.';
        case InvalidEmailEntryReasonType.GameInviteAlreadyGameAdminForCurrentGame:
          return 'This user is already a game admin for this game. If you wish to update their permissions, please use the update permissions option.';

        case InvalidEmailEntryReasonType.UserIsCurrentlyDeactivated:
          return 'This user is currently deactivated';
      }
    }
    return 'Unknown Day';
  }
}
