<app-dialog-base>
  <span dialog-title data-testid="title">{{ genericData.title }}</span>

  <ng-container dialog-content>
    @if (uiState === uiStateForTemplate.ShowData) {
      <p data-testid="generic-dialog-message" [innerHTML]="genericData.message"></p>

      @if (genericData.playerData) {
        <div>Name: <span
          class="bold-text"
          data-testid="player-name">{{ genericData.playerData.firstName }} {{ genericData.playerData.lastName }}</span>
        </div>
        @if (genericData.playerData.email) {
          <div>Email: <span class="bold-text"
                            data-testid="player-email">{{ genericData.playerData.email }}</span>
          </div>
        }
      }

      @if(genericData.extraDetails) {
        <p data-testid="player-extra-details">{{ genericData.extraDetails }}</p>
      }

    }
  </ng-container>
  <ng-container actions>
    <button [attr.data-testid]="genericData.dataCyTag"
            [disabled]="uiState === uiStateForTemplate.ShowLoading"
            mat-button
            class="primary-button"
            (click)="onConfirmationClick()">Confirm
    </button>
  </ng-container>
</app-dialog-base>
