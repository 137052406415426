import {Component, OnInit} from '@angular/core';

import {Router} from "@angular/router";
import {OidcService} from "../../../services/oidc.service";

@Component({
  selector: 'admin-auth-callback-page',
  standalone: true,
  styleUrls: ['admin-auth-callback.page.scss'],
  templateUrl: 'admin-auth-callback.page.html'
})
export class AdminAuthCallbackPage implements OnInit {
  constructor(private oidcService: OidcService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.ConfigAndHandleAuthResult();
  }

  private async ConfigAndHandleAuthResult(): Promise<void> {

    try {

      const auth_result = await this.oidcService.attemptLogin();
      if (!auth_result) {
        await this.router.navigateByUrl('/dashboard');
        return;
      }

      await this.router.navigateByUrl('/auth/login');
    } catch (authException) {
      await this.router.navigateByUrl('/auth/login');
    }
  }
}
