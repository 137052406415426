import {Component, Input, OnDestroy} from '@angular/core';
import {MatCardModule} from "@angular/material/card";
import {AgGridAngular} from "ag-grid-angular";
import {ColDef, GridApi, GridReadyEvent} from "ag-grid-community";
import {IPlayerNumberWithAutoplayFlag} from "../../../interfaces/player/IPlayerNumberWithAutoplayFlag";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {IGamePlayerInputData} from "../../../interfaces/player/IGamePlayerInputData";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {IFailedAutoplayGroupRow} from "../../../interfaces/player/IFailedAutoplayGroupRow";
import {IPlayedPlayerNumbersByGroupRow} from "../../../interfaces/player/IPlayedPlayerNumbersByGroupRow";
import {GameTypeEnum} from "../../../enum/GameTypeEnum";
import {PlayersService} from "../../../services/players.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {PlayerInfoTableDefs} from "../../player-game-info/player-info-table-defs";
import {MatDialog} from "@angular/material/dialog";
import {concatMap, Subscription} from "rxjs";
import {IPlayerNumbersForGameQueryResult} from "../../../interfaces/player/IPlayerNumbersForGameQueryResult";
import {IFailedAutoplayGroup} from "../../../interfaces/player/IFailedAutoplayGroup";
import {IGameInstanceScopedPlayerNumberGroup} from "../../../interfaces/player/IGameInstanceScopedPlayerNumberGroup";
import {
  DeactivatePlayerNumbersComponent
} from "../../dialogs/deactivate-player-numbers/deactivate-player-numbers.component";
import {ActivatePlayerNumbersComponent} from "../../dialogs/activate-player-numbers/activate-player-numbers.component";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";
import {SnackbarService} from "../../../services/snackbar.service";

@Component({
  selector: 'app-manage-player-numbers',
  standalone: true,
  imports: [
    MatCardModule,
    AgGridAngular,
    MatButton,
    MatIcon,
  ],
  templateUrl: './manage-player-numbers.component.html',
  styleUrl: './manage-player-numbers.component.css'
})
export class ManagePlayerNumbersComponent implements OnDestroy {

  @Input()
  public set gamePlayerData(gamePlayerDataP: IGamePlayerInputData) {
    if (gamePlayerDataP.playerId && gamePlayerDataP.game.Id) {
      this.unsubscribeFromFetchingTicketNumbers();
      this.game = gamePlayerDataP.game;
      this.playerId = gamePlayerDataP.playerId;
      this.allNumbersColumnDefs = this.playerInfoTables.returnAllNumberColumnDefs(this.game!.Type === GameTypeEnum.GoldRush);
      this.setupFetchNumbers();
    }
  }

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateForTemplate = UIStateEnum;
  public failedAutoplayGroups: IFailedAutoplayGroupRow[] = [];
  public allPlayerNumbers: IPlayerNumberWithAutoplayFlag[] = [];
  public playedPlayerNumberGroupRows: IPlayedPlayerNumbersByGroupRow[] = [];

  public game: IGameQueryResult | null = null;
  public playerId: string = '';

  public unplayedNumbersGridApi!: GridApi<IPlayerNumberWithAutoplayFlag>;
  public playedNumbersGridApi!: GridApi<IPlayedPlayerNumbersByGroupRow>;
  public failedAutoplayGroupGridApi!: GridApi<IFailedAutoplayGroupRow>;

  public playedNumberGroupsColumnDefs: ColDef[] = this.playerInfoTables.playedNumberGroupsColumnDefs;
  public allNumbersColumnDefs: ColDef[] = [];
  public failedColumnDefs: ColDef[] = this.playerInfoTables.failedAutoplayColumnDefs;

  private fetchNumbersSubscription: Subscription | null = null;

  constructor(private playerService: PlayersService,
              private errorHandlingService: ErrorHandlingService,
              private playerInfoTables: PlayerInfoTableDefs,
              private snackBarService: SnackbarService,
              private matDialog: MatDialog) {
  }

  ngOnDestroy(): void {
    this.unsubscribeFromFetchingTicketNumbers();
  }

  // have to do this so every time the game changes, we aren't subscribing again
  private unsubscribeFromFetchingTicketNumbers() {
    if (this.fetchNumbersSubscription) {
      this.fetchNumbersSubscription.unsubscribe();
    }
  }

  // this will set up what will happen
  setupFetchNumbers() {
    // when subject gets pushed a value (will happen on de-active ticket numbers) get updated ticket data from api.
    this.fetchNumbersSubscription = this.playerService.fetchUpdatedTicketNumbersSubject$
      .pipe(
        concatMap(() => {
          this.uiState = UIStateEnum.ShowLoading;
          return this.playerService.getPlayerNumbersByGameId(this.playerId, this.game?.Id!);
        })
      ).subscribe({
        next: (allNumberDataP) => {
          this.setPlayerNumbers(allNumberDataP);
        },
        error: (err) => {
          this.uiState = UIStateEnum.ShowData;
          this.errorHandlingService.displayPageLevelErrorMessage(err, 'Looks there was an issue retrieving player tickets.' +
            ' Please try reloading and if the problem persists contact a system administrator');
        }
      });
  }

  private setPlayerNumbers(allNumberDataP: IPlayerNumbersForGameQueryResult) {
    // map payment tiers
    this.playedPlayerNumberGroupRows = this.formatPurchasedPlayerNumberGroups(allNumberDataP.PlayedPlayerNumberGroups);
    this.failedAutoplayGroups = this.formatFailedAutoplayNumberGroups(allNumberDataP.FailedAutoplayGroups);
    this.allPlayerNumbers = allNumberDataP.AllPlayerNumbers;
    this.uiState = UIStateEnum.ShowData;
  }

  formatFailedAutoplayNumberGroups(groupedNumbersP: IFailedAutoplayGroup[]) {
    let numberGroupRows: IFailedAutoplayGroupRow[] = [];
    if (groupedNumbersP && groupedNumbersP.length > 0) {
      groupedNumbersP.forEach(group => {
        numberGroupRows.push({
          TicketNumbers: this.getTicketNumbersFromListOfTickets(group.PlayerNumbers),
          AutoplayGroupId: group.AutoplayGroupId
        })
      });
    }
    return numberGroupRows;
  }

  formatPurchasedPlayerNumberGroups(groupedNumbersP: IGameInstanceScopedPlayerNumberGroup[]) {
    let numberGroupRows: IPlayedPlayerNumbersByGroupRow[] = [];

    if (groupedNumbersP && groupedNumbersP.length > 0) {
      groupedNumbersP.forEach(group => {
        numberGroupRows.push({
          TicketNumbers: this.getTicketNumbersFromListOfTickets(group.PlayerNumbers),
          Played: group.Played,
          Autoplay: group.Autoplay,
          Id: group.Id
        })
      });
    }

    return numberGroupRows;
  }

  getTicketNumbersFromListOfTickets(ticketsP: IPlayerNumberWithAutoplayFlag[]) {
    let groupTicketNumbers: number[] = ticketsP.map((ticket) => ticket.TicketNumber);
    return groupTicketNumbers.toString();
  }

  onPlayedGridReady(params: GridReadyEvent<IPlayedPlayerNumbersByGroupRow>) {
    this.playedNumbersGridApi = params.api;
    this.playedNumbersGridApi.sizeColumnsToFit({
      defaultMinWidth: 100,
    });
  }

  onFailedAutoplayGridReady(params: GridReadyEvent<IFailedAutoplayGroupRow>) {
    this.failedAutoplayGroupGridApi = params.api;
    this.failedAutoplayGroupGridApi.sizeColumnsToFit({
      defaultMinWidth: 100,
    });
  }

  onUnplayedGridReady(params: GridReadyEvent<IPlayerNumberWithAutoplayFlag>) {
    this.unplayedNumbersGridApi = params.api;
    this.unplayedNumbersGridApi.sizeColumnsToFit({
      defaultMinWidth: 100,
    });
  }

  public onDeactivatePlayerNumbersClick() {
    const dialogRef = this.matDialog.open(DeactivatePlayerNumbersComponent, {
      width: '650px',
      data: {
        PlayerId: this.playerId,
        Game: this.game,
        AllPlayerTickets: this.allPlayerNumbers
      }
    });
    dialogRef.afterClosed().subscribe(
      {
        next: (updateSuccessful: boolean) => {
          if (updateSuccessful) {
            this.snackBarService.openSuccessfulSnackBar('You have successfully deactivated the players numbers');
            this.playerService.fetchUpdatedTicketNumbersSubject.next(true);
          }
        }
      })
  }

  public onActivatePlayerNumbersClick() {
    const dialogRef = this.matDialog.open(ActivatePlayerNumbersComponent, {
      width: '650px',
      data: {
        PlayerId: this.playerId,
        GameId: this.game!.Id,
        AllPlayerTickets: this.allPlayerNumbers
      }
    });
    dialogRef.afterClosed().subscribe(
      {
        next: (updateSuccessful: boolean) => {
          if (updateSuccessful) {
            this.playerService.fetchUpdatedTicketNumbersSubject.next(true);
          }
        }
      })
  }
}
