import {Component} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {SnackbarService} from "../../../services/snackbar.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {GameService} from "../../../services/game.service";
import {MatDialogRef} from "@angular/material/dialog";
import {IUpdateGameSettings} from "../../../interfaces/IUpdateGameSettings";
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-update-generic-game-settings',
  standalone: true,
  templateUrl: './update-generic-game-settings.component.html',
  imports: [
    DialogBaseComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInput,
    MatButton
  ],
  styleUrls: ['./update-generic-game-settings.component.css']
})
export class UpdateGenericGameSettingsComponent {
  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public game: IGameQueryResult = this.gameService.activeGame();
  public faqUrlControl: FormControl<string | null> = new FormControl<string | null>('');
  public genericGameSettingsForm = new FormGroup({
    faqUrl: this.faqUrlControl
  });

  constructor(private snackBarService: SnackbarService,
              private errorHandlingService: ErrorHandlingService,
              private gameService: GameService,
              private matDialogRef: MatDialogRef<UpdateGenericGameSettingsComponent>) {
    this.populateForm();
  }

  private populateForm() {
    this.faqUrlControl.setValue(this.game.FaqUrl);
  }

  updateGenericSettings() {

    if (!this.genericGameSettingsForm.valid) {
      this.genericGameSettingsForm.markAsDirty();
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;
    let updateSettingsRequest: IUpdateGameSettings = {
      gameId: this.gameService.activeGame()?.Id!,
      faqUrl: this.faqUrlControl.value!
    };

    this.gameService.updateGenericGameSettings(updateSettingsRequest).subscribe({
      next: () => {
        this.matDialogRef.close();
        this.snackBarService.openSuccessfulSnackBar('Successfully updated game settings');
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage(err, true);
      }
    })

  }
}
