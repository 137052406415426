<div>**Note: you can hover over a testimonial to read it in a tooltip. Or click on the pencil icon to edit your testimonial</div>
<ag-grid-angular
  class="ag-theme-quartz margin-top-med margin-bottom-med"
  data-testid="testimonials-table"
  [tooltipShowDelay]=0
  [autoSizeStrategy]="autoSizeStrategy"
  [domLayout]="'autoHeight'"
  [rowHeight]="50"
  [defaultColDef]="defaultColDef"
  [class]="themeClass"
  rowClass="testimonial-row"
  enableCellTextSelection
  overlayNoRowsTemplate="<span>No testimonials for this game</span>"
  (gridReady)="onTestimonialGridReady($event)"
  [rowData]="testimonialsForGame$ | async"
  [columnDefs]="testimonialsDefs">
</ag-grid-angular>
