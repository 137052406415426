import {Component} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {ICharity} from "../../../interfaces/charity/ICharity";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {CharityService} from "../../../services/charity.service";
import {IUpdateCharityCommand} from "../../../interfaces/charity/IUpdateCharityCommand";
import {SnackbarService} from "../../../services/snackbar.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {MatDialogRef} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";

@Component({
  selector: 'app-change-charity-name',
  standalone: true,
  templateUrl: './change-charity-name.component.html',
  imports: [
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatError,
    MatButton,
    MatInput,
    DialogBaseComponent
  ],
  styleUrls: ['./change-charity-name.component.scss']
})
export class ChangeCharityNameComponent {

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public charityNameControl: FormControl<string | null> = new FormControl<string | null>('', Validators.required);
  public updateCharityForm = new FormGroup({
    charityName: this.charityNameControl
  });
  private activeCharity$: Observable<ICharity | undefined> = this.charityService.activeCharity$;
  public activeCharity: ICharity | null = null;

  constructor(private charityService: CharityService,
              private errorHandlingService: ErrorHandlingService,
              private matDialogRef: MatDialogRef<ChangeCharityNameComponent>,
              private snackBarService: SnackbarService) {
    this.setFormData();
  }

  setFormData(): void {
    this.activeCharity$.subscribe({
      next: (charityP) => {
        if (charityP) {
          this.activeCharity = charityP;
          this.charityNameControl.setValue(charityP.Name);
        }
      }
    })
  }
  updateCharity() {
    if (this.updateCharityForm.valid) {
      this.uiState = UIStateEnum.ShowLoading;

      let charityUpdate: IUpdateCharityCommand = {
        charityId: this.activeCharity?.Id!,
        gameId: this.activeCharity?.GameId!,
        name: this.charityNameControl.value!
      };

      this.charityService.updateCharity(charityUpdate).subscribe({
        next: () => {
          this.snackBarService.openSuccessfulSnackBar('Successfully updated Charity');
          this.matDialogRef.close(true);
        },
        error: (err) => {
          this.uiState = UIStateEnum.ShowData;
          this.errorHandlingService.displayDialogLevelErrorMessage(err, true);
        }
      })
    }
  }

}
